<div *ngIf="(authService.isLoggedIn()); else notLoggedIn" class="container">
  <div *ngIf="!success; else joinedSuccessfully">
    <div *ngIf="!(wronguser); else notAuthorized">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card shadow-light border-0 rounded-5 mt-5 bg-white">
            <div class="card-header bg-white rounded-top-5">
              <h3 class="text-center font-weight-light my-4">Error joining department!</h3>
            </div>
            <div class="card-body px-4 my-3">
              <p>Your invitation might have been withdrawn or you are already member of the department.</p>
            </div>
            <div class="card-footer rounded-bottom-5 text-end py-3">
              <div class="button-container px-2 my-2">
                <button class="btn btn-outline-secondary rounded-3" type="button" routerLink="/">Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notLoggedIn>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="card shadow-light border-0 rounded-5 mt-5 bg-white">
          <div class="card-header bg-white rounded-top-5">
            <h3 class="text-center font-weight-light my-4">Access denied!</h3>
          </div>
          <div class="card-body px-4 my-3">
            <p>Please login to accept the invitation!</p>
          </div>
          <div class="card-footer rounded-bottom-5 text-end py-3">
            <div class="button-container px-2 my-2">
              <button class="btn btn-outline-primary rounded-3" type="button" routerLink="/">Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #joinedSuccessfully>
  <div class="row justify-content-center">
    <div class="col-lg-5">
      <div class="card shadow-light border-0 rounded-5 mt-5 bg-white">
        <div class="card-header bg-white rounded-top-5">
          <h3 class="text-center font-weight-light my-4">Success</h3>
        </div>
        <div class="card-body px-4 my-3">
          <p>You have successfully joined the department.</p>
        </div>
        <div class="card-footer rounded-bottom-5 text-center py-3">
          <div class="button-container my-2">
            <button class="btn btn-outline-secondary rounded-3" type="button" routerLink="/">Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #notAuthorized>
  <app-not-authorized></app-not-authorized>
</ng-template>
