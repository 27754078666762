import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {InviteService} from "../../services/invite.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  public success: boolean;
  email = this.authService.getLoggedInEmail();
  loggedIn: boolean = false;
  wronguser: boolean = false;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private inviteService: InviteService,
  ) {
  }

  ngOnInit() {
    if (this.email !== "") {
      this.loggedIn = true;
    }
    this.route.params.subscribe(params => {
      const email = params['email'];
      const token = params['token'];
      const user = params['user'];

      if (email && token) {
        this.inviteService.processInviteLink(email, token, user).subscribe(
          (response: any) => {
            this.success = true;
          },
          (error: any) => {
            if (error.status === 403) {
               this.wronguser=true;
            }

            this.success = false;
          }
        );
      } else {
        console.error('Invalid parameters in the link');
      }
    });
  }
}
