import {Injectable} from "@angular/core";
import {Globals} from '../global/globals';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Answer} from "../dtos/answer";


@Injectable({
  providedIn: 'root'
})
export class AnswerService {
  private questionBaseUri: string = this.globals.backendUri + '/questions/answers';

  private isAnswered: boolean = false;

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }


  checkAnswered(): boolean {
    return this.isAnswered;
  }

  /**
   * Add the answers to the backend
   * @param answers all answers
   */
  putAnswers(answers: Answer[]): Observable<Answer[]> {
    this.isAnswered = answers.every(answer => answer.answer !== null && answer.answer !== 0);
    return this.httpClient.post<Answer[]>(this.questionBaseUri, answers);
  }

  /**
   * Get the anwsers from the logged in user
   */
  getAnswers(user: string): Observable<Answer[]> {
    return this.httpClient.get<Answer[]>(this.questionBaseUri + '/' + user);
  }

  /**
   * Delete answers for the logged-in user
   */
  deleteAnswers(user: string): Observable<void> {
    return this.httpClient.delete<void>(this.questionBaseUri + '/' + user);
  }
}
