import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {SimpleUser, User} from "../../dtos/user";
import {UserService} from "../../services/user.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {SafeHtml} from "@angular/platform-browser";
import {Trait} from "../../dtos/trait";
import {QuestionService} from "../../services/question.service";
import {from, Observable, switchMap} from "rxjs";
import {Chart, registerables} from 'chart.js/auto';


import {ChatOpenAI} from "langchain/chat_models/openai";
import {DepartmentService} from "../../services/department.service";
import {Department} from "../../dtos/department";
import {ToastrService} from "ngx-toastr";
//import {Ollama} from "langchain/llms/ollama";


Chart.register(...registerables);

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {

  user: SimpleUser = {
    firstName: "",
    lastName: "",
    birthday: null,
    jobrole: "",
    email: "",
    role: "",
    departmentName: "",
  }

  department: Department | undefined;

  errorMessage: SafeHtml = '';
  successMessage: SafeHtml = '';
  showDeleteConfirmation: boolean = true;
  assessment: Trait[];
  startedQuestionnaire = false;
  processTraits = [];
  psychoTraits = [];
  neuroTraits = [];
  gptForm: UntypedFormGroup;
  submitted = false;
  selectGptCall = ["psycho", "neuro", "process"]

  colors = ['darkgrey', 'lightblue', 'lightpink', '#B4DEC2', '#D7AFFF', '#ECE7A2', '#FFBA9F'];
  hoverBackgroundColor = ['grey', '#86C6DA', '#FF9BA9', '#94D0A8', '#C58BFF', "#E2DA70", 'lightsalmon'];
  streamedData: Array<string> = [' '];
  streamedData1: Array<string> = [' '];
  streamedData2: Array<string> = [' '];
  streamedData3: Array<string> = [' '];

  manager: User = {
    id: null,
    firstName: "",
    lastName: "",
    birthday: null,
    jobrole: "",
    email: "",
    password: "",
    role: "MANAGER",
    department: null,
  }

  constructor(private userService: UserService,
              private departmentService: DepartmentService,
              private formBuilder: UntypedFormBuilder,
              public authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private questionService: QuestionService,
              private notification: ToastrService) {
    this.gptForm = this.formBuilder.group({
      prompt: ['', [Validators.required]],
    });
  }


  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/401']).then(r => console.log('Error status 401'));
    }
    this.route.data.pipe(
      switchMap(data => {
        this.getUser(data);
        return from(this.getAssessment());
      })
    ).subscribe(() => {
      if (this.startedQuestionnaire) {
        this.RenderChart();
      }
      if (this.user.role == "MANAGER") {
        this.getDepartmentName(this.user);
      }
    });
  }


  getUser(data: any) {
    let email = this.getEmail();
    if (email) {
      if (this.authService.isAdmin()) {
        this.getCorrectUser(email);
      } else if (this.authService.isManager()) {
        if (email == 'admin@email.com') {
          this.router.navigate(['/401']).then(r => console.log('Error status 401'));
        } else {
          this.departmentService.getDepartmentByManagerEmail(this.authService.getLoggedInEmail()).subscribe({
            next: data => {
              this.manager.department = data;
            },
            error: err => {
              console.error(err);
            },
            complete: () => {
              let userExists;
              for (let i = 0; i < this.manager.department.members.length; i++) {
                if (email == this.manager.department.members[i].email) {
                  userExists = true;
                }
              }
              if (this.manager.department.manager.email == email || userExists) {
                this.getCorrectUser(email);
              } else {
                this.router.navigate(['/my-department']);
              }
            }
          });
        }
      } else {
        if (email != this.authService.getLoggedInEmail()) {
          this.router.navigate(['/401']).then(r => console.log('Error status 401'));
        } else {
          this.getCorrectUser(email);
        }
      }
    } else {
      email = this.authService.getLoggedInEmail();
      this.getCorrectUser(email);
    }
  }

  getCorrectUser(email: string) {
    this.userService.getUserByEmail(email).subscribe({
      next: data => {
        this.user = data;
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.errorMessage = `<strong>Problems fetching User!</strong> ${err.error}`;
          this.notification.error(err.error, "Problems fetching User!");
        } else {
          this.notification.error("", "Problems fetching User!");
        }
      }
    });

  }

  getDepartmentName(data: any): void {
    let email = this.getEmail();
    this.departmentService.getDepartmentByManagerEmail(email).subscribe({
      next: data => {
        this.department = data;
        this.user.departmentName = this.department.name;
      },
    });
  }


  RenderChart() {

    const psychoTraitNames = this.psychoTraits.map(trait => trait.trait);
    const neuroTraitNames = this.neuroTraits.map(trait => trait.trait);
    const processTraitNames = this.processTraits.map(trait => trait.trait);
    const psychoTraitPercentage = this.psychoTraits.map(trait => trait.resultPercentage);
    const neuroTraitPercentage = this.neuroTraits.map(trait => trait.resultPercentage);
    const processTraitPercentage = this.processTraits.map(trait => trait.resultPercentage);

    this.initializePieChart("pieChartPsycho", psychoTraitNames, psychoTraitPercentage);
    this.initializePieChart("pieChartNeuro", neuroTraitNames, neuroTraitPercentage);
    this.initializePieChart("pieChartProcess", processTraitNames, processTraitPercentage);

    this.initializeOverallBarChart("overallBarChartPsycho", psychoTraitNames, psychoTraitPercentage);
    this.initializeOverallBarChart("overallBarChartNeuro", neuroTraitNames, neuroTraitPercentage);
    this.initializeOverallBarChart("overallBarChartProcess", processTraitNames, processTraitPercentage);
  }

  initializeOverallBarChart(canvasId, traitNames, traitData) {
    const verticalLine = {
      id: 'verticalLine',
      afterDraw(chart, args, options) {
        const {
          ctx,
          chartArea: {top, right, bottom, left, width, height},
          scales: {x, y}
        } = chart;
        ctx.save();
        ctx.strokeStyle = '#5F5F5F';
        const xPos = x.getPixelForValue(1 / traitData.length);
        const yPos = y.getPixelForValue(30);
        ctx.beginPath();
        ctx.moveTo(xPos, top);
        ctx.lineTo(xPos, bottom);
        ctx.stroke();
        ctx.restore();
      }
    };
    const horizontalLine = {
      id: 'horizontalLine',
      afterDraw(chart, args, options) {
        const {
          ctx,
          chartArea: {top, right, bottom, left, width, height},
          scales: {x, y}
        } = chart;
        ctx.save();
        ctx.strokeStyle = '#5F5F5F';
        ctx.strokeRect(right - 130, 15, 20, 0)
        ctx.fillStyle = 'grey';
        ctx.textAlign = 'left';
        ctx.font = '12px Arial';
        ctx.fillText('average', right - 100, 15);
        ctx.restore();
      }
    };


    return new Chart(canvasId, {
      type: 'bar',
      data: {
        labels: traitNames,
        datasets: [{
          label: 'Relative proportion of the respective property',
          data: traitData,
          backgroundColor: this.colors,
          hoverBackgroundColor: this.hoverBackgroundColor,
          borderWidth: 1,
        }]
      },
      options: {

        plugins: {
          legend: {
            labels: {
              color: 'grey'
            }
          }
        },
        indexAxis: 'y',
        scales: {
          x: {
            suggestedMin: 0,
            suggestedMax: 2 / traitData.length,
          }
        }
      },
      plugins: [verticalLine, horizontalLine]
    });
  }


  initializePieChart(canvasId, traitNames, traitData) {

    if (this.startedQuestionnaire) {
      let ctx = document.getElementById(canvasId);
      if (ctx == null) {
        return;
      }
    }


    return new Chart(canvasId, {
      type: 'pie',
      data: {
        labels: traitNames,
        datasets: [{
          data: traitData,
          backgroundColor: this.colors,
          hoverBackgroundColor: this.hoverBackgroundColor,
          borderWidth: 1,
          hoverOffset: 10,
        }]
      },
      options: {}
    });
  }


  getEmail(): string {
    return this.route.snapshot.paramMap.get('email');
  }

  /**
   * Following two methods necessary for confirmation dialog
   */
  onDelete() {
    this.showDeleteConfirmation = true;
  }

  onCancelDelete() {
    this.showDeleteConfirmation = false;
  }

  /**
   * Deletes currently logged in User
   */
  delete() {
    // let toDelete = new DeleteRequest(this.user.email.valueOf());
    // toDelete=new DeleteRequest("non@existing");
    this.userService.deleteUser(this.user.email.valueOf()).subscribe({
      next: () => {
        this.successMessage = "Successfully deleted user " + this.user.email.valueOf() + " from the system.";
        this.notification.success("Successfully deleted user " + this.user.email.valueOf() + " from the system.", "");
        this.router.navigate(['/user-admin']).then(r => console.log("navigated to all-members"));
      },
      error: err => {
        let message: string = JSON.parse(err.error).detail;
        this.errorMessage = JSON.parse(err.error).detail;
        if (err instanceof HttpErrorResponse && err.status === 500) {
          this.errorMessage = err.error;
          message = err.error;
          this.router.navigate(['/500']);
        } else if (err instanceof HttpErrorResponse && err.status === 404) {
          this.errorMessage = `<strong>Problems deleting User!</strong> ${err.error}`;
          message = err.error;
        } else if (err instanceof HttpErrorResponse && err.status === 400) {
          const startIndex = err.error.indexOf("[");
          const endIndex = err.error.lastIndexOf("]");
          if (startIndex !== -1 && endIndex !== -1) {
            const validationErrorsString = err.error.substring(startIndex + 1, endIndex);
            this.errorMessage = `<strong>Problems deleting User!</strong> ${validationErrorsString}`;
            message = validationErrorsString;
          }
        }
        this.notification.error(message, "Problems deleting User!");
      }
    });
  }

  /**
   * Gets assessment of currently logged-in user
   */
  getAssessment(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let observable: Observable<Trait[]>;
      let email = this.getEmail();
      if (this.getEmail() == null) {
        email = this.authService.getLoggedInEmail();
      }
      observable = this.questionService.getAssessment(email);
      observable.subscribe({
        next: data => {
          this.assessment = data;
          if (this.assessment.length !== 0) {
            this.startedQuestionnaire = true;
          }
          this.processTraits = this.assessment.slice(0, 6);
          this.psychoTraits = this.assessment.slice(6, 10);
          this.neuroTraits = this.assessment.slice(10, 17);
          resolve();
        },
        error: error => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            this.errorMessage = `<strong>Problems fetching Questionairresult!</strong> ${error.error}`;
            this.notification.error(error.error, "Problems fetching the result of the questionnaire!");
          } else {
            this.notification.error("", "Problems fetching the result of the questionnaire!");
          }
          reject(error);
        }
      });
    });
  }

  /**
   * Calls the GPT model based on the selected trait
   * @param {string} traitSelected - The selected trait ("psycho", "neuro", or "process")
   */
  callGpt(traitSelected) {
    this.submitted = true;

    if (!this.startedQuestionnaire) {
    }

    const personalKey = "sk-proj-czwT0mzhSQgfwaZ2QI7oT3BlbkFJP0q15fry79friLAFeHiD";
    const chatModel = new ChatOpenAI({
      modelName: 'gpt-3.5-turbo',
      temperature: 0.1,
      openAIApiKey: personalKey,
    });

    /**
     * Fixes Angular breaking promises in openai package (returns null)
     * https://github.com/angular/angular/issues/53380.
     */
      // patch begin
    const original = (chatModel as any)._getClientOptions;
    (chatModel as any)._getClientOptions = function (options: any) {
      original.call(this, options);
      const create = this.client.chat.completions.create;
      this.client.chat.completions.create = function (params: any) {
        return new Promise((res, rej) => {
          return create.call(this, params).then(res, rej);
        });
      }
    };
    // patch end---------------------------------

    const prompt = this.aiPrompt(traitSelected) + " 75 Woerter Limit";

    if (traitSelected == "psycho") {
      this.streamedData1 = ["loading..."];

      chatModel.predict(prompt).then((result: any) => {
        const pasteMe = result.split(/\r?\n/);

        this.streamedData1 = pasteMe;
      }).catch(
        (error: any) => {
          console.error('Error fetching data:', error);
          this.streamedData1 = ['Error fetching data. \n Please make sure you have a working internet connection.'];
        }
      );
    }

    if (traitSelected == "neuro") {
      this.streamedData2 = ["loading..."];

      chatModel.predict(prompt).then((result: any) => {
        const pasteMe = result.split(/\r?\n/);

        this.streamedData2 = pasteMe;
      }).catch(
        (error: any) => {
          console.error('Error fetching data:', error);
          this.streamedData2 = ['Error fetching data. \n Please make sure you have a working internet connection.'];
        }
      );
    }

    if (traitSelected == "process") {
      this.streamedData3 = ["loading..."];

      chatModel.predict(prompt).then((result: any) => {
        const pasteMe = result.split(/\r?\n/);

        this.streamedData3 = pasteMe;
      }).catch(
        (error: any) => {
          console.error('Error fetching data:', error);
          this.streamedData3 = ['Error fetching data. \n Please make sure you have a working internet connection.'];
        }
      );
    }
  }


  /**
   * Generates the AI prompt based on the selected trait
   * @param {string} select - The selected trait ("psycho", "neuro", or "process")
   * @returns {string} - The generated AI prompt
   */
  aiPrompt(select) {
    let mostValue = 0;
    let leastValue = 0;

    if (select == "psycho") {
      const psychoTraitNames = this.psychoTraits.map(trait => trait.trait);
      const psychoTraitResults = this.psychoTraits.map(trait => trait.result);
      const psychoTraitExplained = this.psychoTraits.map(trait => trait.explanation);


      mostValue = this.getBestTraits(mostValue, psychoTraitResults);
      leastValue = this.getWorstTraits(leastValue, psychoTraitResults);
      let psychoPrompt = this.getRequestForAi(psychoTraitNames[mostValue], psychoTraitExplained[mostValue], psychoTraitNames[leastValue], psychoTraitExplained[leastValue]);
      return psychoPrompt;
    }
    if (select == "neuro") {
      const neuroTraitNames = this.neuroTraits.map(trait => trait.trait);
      const neuroTraitResults = this.neuroTraits.map(trait => trait.result);
      const neuroTraitExplained = this.neuroTraits.map(trait => trait.explanation);

      mostValue = this.getBestTraits(mostValue, neuroTraitResults);
      leastValue = this.getWorstTraits(leastValue, neuroTraitResults);
      let neuroPrompt = this.getRequestForAi(neuroTraitNames[mostValue], neuroTraitExplained[mostValue], neuroTraitNames[leastValue], neuroTraitExplained[leastValue]);
      return neuroPrompt;
    }
    if (select == "process") {
      const processTraitNames = this.processTraits.map(trait => trait.trait);
      const processTraitResults = this.processTraits.map(trait => trait.result);
      const processTraitExplained = this.processTraits.map(trait => trait.explanation);

      mostValue = this.getBestTraits(mostValue, processTraitResults);
      leastValue = this.getWorstTraits(leastValue, processTraitResults);
      let processPrompt = this.getRequestForAi(processTraitNames[mostValue], processTraitExplained[mostValue], processTraitNames[leastValue], processTraitExplained[leastValue]);
      return processPrompt;
    }
  }

  /**
   * Generates the AI prompt for the selected traits
   * @param {string} strongTraitName - The name of the strongly pronounced trait
   * @param {string} strongTraitDescription - The description of the strongly pronounced trait
   * @param {string} weakTraitName - The name of the weakly pronounced trait
   * @param {string} weakTraitDescription - The description of the weakly pronounced trait
   * @returns {string} - The generated AI prompt
   */
  getRequestForAi(strongTraitName, strongTraitDescription, weakTraitName, weakTraitDescription) {

    let promptStart = "You are my GERMAN psychologist and have to respond to the following questions in GERMAN:\n \n";
    let request1 = "People with a strongly pronounced *" + strongTraitName + "* trait can be described as having *" + strongTraitDescription + "* For me this trait is also strongly pronounced. Explain to me what defines people who have this trait. \n \n";
    let request2 = "People with a strongly pronounced *" + weakTraitName + "* trait can be described as having *" + weakTraitDescription + "* For me this trait is not pronounced at all and I want to improve that. What can I do? \n \n";
    let promptEnd = "YOU HAVE A 75 WORD LIMIT TO RESPOND!";

    let request = promptStart + request1 + request2 + promptEnd;
    return request;
  }

  /**
   * Gets the index of the trait with the highest result value
   * @param {number} mostValue - The current index of the trait with the highest result value
   * @param {number[]} traitResults - An array of result values for the traits
   * @returns {number} - The index of the trait with the highest result value
   */
  getBestTraits(mostValue, traitResults) {

    let mostValueTrait = traitResults[0];
    for (let i = 0; i <= traitResults.length; i++) {
      if (mostValueTrait < traitResults[i]) {
        mostValueTrait = traitResults[i];
        mostValue = i;
      }
    }
    return mostValue;
  }

  /**
   * Gets the index of the trait with the lowest result value
   * @param {number} leastValue - The current index of the trait with the lowest result value
   * @param {number[]} traitResults - An array of result values for the traits
   * @returns {number} - The index of the trait with the lowest result value
   */
  getWorstTraits(leastValue, traitResults) {

    let leastValueTrait = traitResults[0];
    for (let i = 0; i <= traitResults.length; i++) {
      if (leastValueTrait > traitResults[i]) {
        leastValueTrait = traitResults[i];
        leastValue = i;
      }
    }
    return leastValue;
  }


  /*-------------------------------
  /*OLLAMA Implementation


  callOllama() {

    if (!this.startedQuestionnaire) {
      console.log("questionair has not been answered yet")
    }

    const ollamaModel = new Ollama({
      baseUrl: "http://localhost:11434", // Default value
      model: "mistral", // Default value
      temperature: 0.1,
      topK: 40,
      topP: 0.9,
    });

    // patch begin
    // for zone.js openAI APIPromise
    const original = (ollamaModel as any)._getClientOptions;
    (ollamaModel as any)._getClientOptions = function(options: any) {
      original.call(this, options);
      const create = this.client.chat.completions.create;
      this.client.chat.completions.create = function(params: any) {
        return new Promise((res, rej) => {
          return create.call(this, params).then(res, rej);
        });
      }
    };

    // patch end---------------------------------
    const prompt = this.aiPrompt2();
    console.log(prompt);


    this.streamedData= ["loading..."];

    ollamaModel.predict(prompt).then((result: any) => {
      console.log(result);
      const pasteMe = result.split(/\r?\n/);

      console.log(pasteMe);
      this.streamedData = pasteMe;
    }).catch(
      (error: any) => {
        console.error('Error fetching data:', error);
        this.streamedData = ['Error fetching data. \n Please make sure you started Ollama-Mistral.'];
      }
    );
  }



  callGpt2() {
    this.submitted=true;

    if (!this.startedQuestionnaire) {
      console.log("questionair has not been answered yet")
    }

    const personalKey = "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";

    if(personalKey==null){
      this.streamedData=["GPT only works with a valid OpenAIApiKey \n https://platform.openai.com/account/api-keys"];
    } else {
      const chatModel = new ChatOpenAI({
        modelName: 'gpt-3.5-turbo',
        temperature: 0.1,
        openAIApiKey: personalKey,
      });

      // patch begin
      // for zone.js openAI APIPromise
      const original = (chatModel as any)._getClientOptions;
      (chatModel as any)._getClientOptions = function(options: any) {
        original.call(this, options);
        const create = this.client.chat.completions.create;
        this.client.chat.completions.create = function(params: any) {
          return new Promise((res, rej) => {
            return create.call(this, params).then(res, rej);
          });
        }
      };

      // patch end---------------------------------
      const prompt = "respond with hello";
      console.log(prompt);


      this.streamedData = ["loading..."];

      chatModel.predict(prompt).then((result: any) => {
        console.log(result);
        const pasteMe = result.split(/\r?\n/);

        console.log(pasteMe);
        this.streamedData = pasteMe;
      }).catch(
        (error: any) => {
          console.error('Error fetching data:', error);
          this.streamedData = ['Error fetching data. \n Please make sure you have a working internet connection.'];
        }
      );
    }
  }

  aiPrompt2() {

    const psychoTraitNames = this.psychoTraits.map(trait => trait.trait);
    const neuroTraitNames = this.neuroTraits.map(trait => trait.trait);
    const processTraitNames = this.processTraits.map(trait => trait.trait);

    const psychoTraitResults = this.psychoTraits.map(trait => trait.result);
    const neuroTraitsResults = this.neuroTraits.map(trait => trait.result);
    const processTraitNamesResults = this.processTraits.map(trait => trait.result);


    const promptStart = "I'm giving you a list of Words and a list of values.\n" +
      "The words and the values are related. the first word corresponds to the first value, the second word corresponds to the second value, etc.\n" +
      "\n" +
      "I need you to remember it like this:\n" +
      "\n" +
      "\"highest value: <insert word with highest value>\"\n" +
      "\"lowest value: <insert word with lowest value>\"";


    const listWords = "here is the list of words: " + psychoTraitNames.toString() + ", " + neuroTraitNames.toString() + ", " + processTraitNames.toString() + " \n";
    const listValues = "here is the list of values: " + psychoTraitResults.toString() + ", " + neuroTraitsResults.toString() + ", " + processTraitNamesResults.toString() + " \n";

    const promptEnd = "Now that you remember the highest value word and the lowest value word I need you to do the following:\n" +
      "\n" +
      "Act as a psychological therapist and tell me what my highest value word is and what it means.\n" +
      "Afterwards tell me what my lowest value word is, what it means and give me 3 things I should focus on to become better at my lowest value word.\n" +
      "Respond in German, with a 125 word character limit"


    const prompt = promptStart + listWords + listValues + promptEnd;
    return prompt;
  }


  ---------------------------------*/

}
