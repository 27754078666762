import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {ProjectService} from "../../../services/project.service";
import {NgForm} from "@angular/forms";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {SafeHtml} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit {

  constructor(public authService: AuthService, private router: Router, public projectService: ProjectService, private notification: ToastrService) {
  }

  name: string;
  errorMessage: SafeHtml = '';

  ngOnInit(): void {
    if (!this.authService.isLoggedIn() || !(this.authService.isManager())) {
      this.router.navigate(['/401']).then(r => console.log('Error status 401'));
    }
  }

  save(form: NgForm) {
    this.projectService.createProject({name: this.name, managerEmail: this.authService.getLoggedInEmail()}).subscribe({
      next: data => {
        this.router.navigate(['projects/edit', data.id]).then(r => console.log('Project created'));
      },
      error: err => {
        let message: string;
        if (err instanceof HttpErrorResponse) {
          if (err.status === 500) {
            this.errorMessage = '<strong>Internal Server Error!</strong> Could not connect to server.';
            this.notification.error("Could not connect to server.", "Internal Server Error!");
            this.router.navigate(['/500']).then(r => console.log('Error status 500'));
          } else if (err.status === 404) {
            this.errorMessage = err.error;
            this.notification.error(err.error, "Problems creating Project!");
            this.router.navigate(['/my-department']);
          } else if (err.status === 400) {
            const startIndex = err.error.indexOf("[");
            const endIndex = err.error.lastIndexOf("]");
            if (startIndex !== -1 && endIndex !== -1) {
              const validationErrorsString = err.error.substring(startIndex + 1, endIndex);
              this.errorMessage = `<strong>Problems creating Project!</strong> ${validationErrorsString}`;
              this.notification.error(validationErrorsString, "Problems creating Project!");
            }
          }
        } else {
          this.errorMessage = err.error;
          this.notification.error(err.error, "Problems creating Project!");
        }
      }
    })
  }

}
