<div class="pt-5">
  <div class="row">
    <div class="col-md-6 mx-auto">
      <span class="anchor" id="formGpt"></span>

      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">AI Testing Page</h3>
        </div>

        <div class="card-body">
          <form class="form" [formGroup]="gptForm" (ngSubmit)="callGpt()">

            <div class="form-group">
              <label for="inputPrompt">Prompt</label>
              <input type="text" name="prompt" formControlName="prompt" class="form-control" id="inputPrompt"
                     aria-describedby="promptHelp" placeholder="Enter prompt">
              <div *ngIf="submitted && gptForm.controls.prompt.errors" class="error">
                <small class="form-text text-warning" *ngIf="gptForm.controls.prompt.errors.required">Prompt is
                  required!
                </small>
              </div>
            </div>


            <button type="button" (click)="callGpt()" class="btn btn-success float-right">Call Gpt</button>
            <button type="button" (click)="callCustomGpt()" class="btn btn-success float-right">Call Custom Gpt</button>
            <button type="button" (click)="callOllama()" class="btn btn-success float-right">Call Ollama</button>

          </form>

          <div *ngIf="streamedData">
            <h6>Response:</h6>
            <p>
              {{ streamedData }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
