<div *ngIf="authService.isLoggedIn() && authService.isAdmin()" class="mt-4 container">

  <div class="row pb-3">
    <div class="col-12">
      <div class="title"><h1>Departments</h1></div>
      <p class="lead">Manage all departments and their members.</p>
    </div>
  </div>

  <div class="row">

    <!-- ---------- Cards View - All Departments ---------- -->

    <div *ngIf="(departments); else noDepartments"></div>
    <div class="cards_item col-lg-4 p-2 round-border" *ngFor="let department of departments">
      <div class="card mb-4 shadow-light border-light round-border">
        <div
          class="card-header fs-4 header-color bg-white border-white px-4 pt-4 round-border">{{department.name}}</div>
        <div class="card-body px-4">

          <div class="row mt-2">
            <div class="col-md-1">
              <div class="form-group mb-3 mb-md-0">
                <div class="card-subtitle fs-3"><i class="bi bi-person"></i></div>
              </div>
            </div>
            <div class="col-md-4 ">
              <div class="form-group mb-3 mb-md-0 mx-2">
                <div class="card-subtitle fs-3 text-red">{{department.members.length}}</div>
              </div>
            </div>
            <div class="align-self-center col-md-6 ">
              <div class="mb-3 mb-md-0">
                <div class="card-subtitle header-color">Manager</div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <div class="form-group mb-3 mb-md-0 mx-2">
                <div
                  class="card-subtitle text-color lead">{{department.members.length !== 1 ? 'Members' : 'Member'}}</div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="form-group mb-3 mb-md-0">
                <div class="card-subtitle lead text-red">
                  {{department.manager.firstName}} {{department.manager.lastName}}
                </div>
              </div>
            </div>
          </div>
          <hr class="my-4">
          <div class="card-subtitle mb-2 text-muted-color"> Created at: {{department.createdAt | date}}</div>

          <!-- ---------- Department Edit Details Modal ---------- -->

          <ng-template #departmentModal>
            <div class="card">
              <div class="card-header fade show d-flex align-items-center">
                <h4 class="flex-grow-1 modal-title">Department Details</h4>
                <!--<button class="btn btn-link p-0" type="button" (click)="closeEditModal()">
                  <span class="bi-x-lg text-dark icon-big p-0"></span>
                </button>-->
              </div>
              <div class="card-body">

                <!-- Update  Department Name -->

                <form #form="ngForm" (ngSubmit)="save(form)" class="pb-4">
                  <label for="depName" class="card-subtitle text-muted-color">Name</label>
                  <div class="row">
                    <div class="col-md-8">
                      <input id="depName" name="name" type="text"
                             class="form-control rounded"
                             [(ngModel)]="department.name" required/>
                    </div>
                    <div *ngIf="submitted" class="error mt-2">
                      <small class="form-text text-warning offset-md-4 offset-lg-4"
                             *ngIf="!validDepartmentName">The name of your department is required!
                      </small>
                    </div>
                    <div class="col-md-4">
                      <button type="submit" class="button form-control btn btn-primary"
                              [disabled]="!form.valid">
                        Update Name
                      </button>
                    </div>
                  </div>
                </form>

                <!-- Update Department Manager -->

                <form #managerForm="ngForm"
                      (ngSubmit)="updateManager(managerForm)">
                  <label for="depName"
                         class="card-subtitle text-muted-color">Manager: {{department.manager.firstName}} {{department.manager.lastName}}</label>
                  <div class="row">
                    <div class="col-md-8">
                      <input name="name" type="text"
                             class="form-control rounded"
                             [(ngModel)]="department.manager.email" required/>
                    </div>
                    <div class="col-md-4">
                      <button type="submit" class="button form-control btn btn-primary"
                              [disabled]="!managerForm.valid">
                        Update Manager
                      </button>
                    </div>
                  </div>

                  <!-- Table: All Managers without department -->
                  <div *ngIf="managersWithoutDepartment.length > 0">

                    <div class="card-body px-0 table-responsive">
                      <label for="managerSuggestions"
                             class="card-subtitle text-muted-color pb-2">Managers without department</label>
                      <div style="overflow-y: auto; max-height: 300px;">
                        <table class="table table-borderless table-hover" role="grid" id="managerSuggestions">
                          <tbody>
                          <tr *ngFor="let user of managersWithoutDepartment">
                            <td class="col-1 text-center"><img ngxGravatar [email]="user.email"/></td>
                            <td class="col-6">
                              <div class="py-1">{{user.firstName}} <b>{{user.lastName}}</b></div>
                              <div class="card-subtitle text-muted-color"
                                   style="font-weight: normal; font-size: smaller">{{user.email}}</div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </form>
              </div>

              <!-- Department Detail Modal Footer: Delete Department and Close Modal -->

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-outline-danger" id="close-modal-btn"
                        (click)="openDialog(department.id, department.name, deleteDepartment)">Delete Department
                </button>
                <button type="button" class="btn btn-outline-secondary"
                        (click)="closeEditModal()">Close
                </button>
              </div>
            </div>
          </ng-template>
        </div>

        <!-- Buttons to open Member and Department Modals -->

        <div class="card-footer round-border-bottom ">
          <form class="form">
            <div class="row mb-2 mt-2">
              <div class="col-md-6">
                <div class="form-group mb-3 mb-md-0">
                  <button (click)="openDialog(department.id, null, memberModal)" type="button"
                          class="form-control btn btn-primary">
                    Members
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <button (click)="openDialog(department.id, null, departmentModal)" type="button"
                          class="form-control btn btn-primary ">
                    Edit Department
                  </button>
                </div>
              </div>
            </div>
          </form>

          <!-- Department Members Modal -->

          <ng-template #memberModal let-modal>
            <div class="card px-3 p-2" id="memberModal">
              <div class="card-header bg-white fade show d-flex align-items-center">
                <h5 class="flex-grow-1 lead modal-title">Members</h5>
                <button class="btn btn-link p-0" type="button" (click)="modal.dismiss()">
                  <span class="bi-x-lg text-dark icon-big p-0"></span>
                </button>
              </div>
              <div class="card-body px-0">
                <div *ngIf="department.members.length > 0; else noMembers">
                  <table class="table table-borderless table-hover" role="grid">
                    <tbody>
                    <tr *ngFor="let user of department.members">
                      <td class="col-1 text-center"><img ngxGravatar [email]="user.email"/></td>

                      <td class="col-6">
                        <div class="py-1">{{user.firstName}} <b>{{user.lastName}}</b></div>
                        <div class="card-subtitle text-muted-color"
                             style="font-weight: normal; font-size: smaller">{{user.email}}</div>
                      </td>

                      <td>
                        <a class="btn btn-sm responsive-info-button pb-0"
                           [routerLink]="['../profile', user.email]"
                           (click)="modal.dismiss()"
                           aria-label="Show user details">
                          <i class="bi bi-info-circle align-middle"></i> See Details
                        </a>
                      </td>

                      <td class="text-center pt-3 pb-3">
                        <div class="btn-group">
                          <button (click)="openDialog(null, user.email, removeUserFromDepartment)"
                                  class="btn btn-sm rounded"><i
                            class="bi bi-x-circle align-middle"></i> Remove
                          </button>
                        </div>
                      </td>

                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noDepartments>
  <tr>
    <td colspan="4" class="text-center p4">No departments found</td>
  </tr>
</ng-template>

<ng-template #noMembers>
  <div class="lead text-center p-4" style="font-size: large">The department '{{currentDepartment.name}}' doesn't have
    any
    members.
  </div>
</ng-template>

<ng-template #removeUserFromDepartment let-modal>
  <div class="modal-content p-2">
    <div class="modal-header">
      <h4 class="modal-title">Confirm removal</h4>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              (click)="modal.close()"></button>
    </div>
    <div class="modal-body text-start">
      Are your sure you want to remove <b>{{userForRemoval.email}}</b> from the department
      <b>{{currentDepartment.name}}</b>?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" (click)="modal.close()">Cancel
      </button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="remove(userForRemoval.email)">
        Confirm
      </button>
    </div>
  </div>
</ng-template>

<ng-template #deleteDepartment let-modal>
  <div class="modal-content p-2">
    <div class="modal-header">
      <h4 class="modal-title">Confirm deletion</h4>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              (click)="modal.close()"></button>
    </div>
    <div class="modal-body text-start">
      Are your sure you want to delete the department <b>{{currentDepartment.name}}</b>?
      If you delete the department, all members will be removed from the department and you will not be able to
      restore it.
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" (click)="modal.close()">Cancel
      </button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="deleteDep(currentDepartment.id)">
        Delete Department
      </button>
    </div>
  </div>
</ng-template>
