<footer class="py-4 bg-footer mt-auto">
  <div>
    <div class="text-center">
      <span>Copyright &copy; M.A.L.S 2024 |
      </span>
      <span>
        <a routerLink="/impressum">Impressum</a>
      </span>
    </div>
  </div>
</footer>

