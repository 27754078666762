<nav class="sb-sidenav-menu pt-4">
    <!-- Sidebar-Inhalt hier -->
    <ul class="list-group-flush" id="list-group">

      <div class="sb-sidenav-menu-heading text-sb-caption">Core</div>

      <a routerLink="/" aria-expanded="false" class="list-group-item list-group-item-action"
         (click)="selectButton('/')">
        <div class="circle-container" data-bs-toggle="tooltip" data-bs-placement="right"
             title="Home" [ngClass]="{'selected': selectedButton === '/'}"><i
          class="bi bi-house-door icon"></i>
        </div>
      </a>

      <a routerLink="/questionnaire" aria-expanded="false" class="list-group-item list-group-item-action"
         (click)="selectButton('/questionnaire')">
        <div class="circle-container" data-bs-toggle="tooltip" data-bs-placement="right"
             title="Questionnaire" [ngClass]="{'selected': selectedButton === '/questionnaire'}"><i
          class="bi bi-quora icon"></i>
        </div>
      </a>

      <div *ngIf="authService.isAdmin() || authService.isManager()" class="sb-sidenav-menu-heading text-sb-caption">Admin</div>

      <a *ngIf="authService.isAdmin()" routerLink="/user-admin" aria-expanded="false"
         class="list-group-item list-group-item-action"
         (click)="selectButton('/user-admin')">
        <div class="circle-container" data-bs-toggle="tooltip" data-bs-placement="right"
             title="User Administration" [ngClass]="{'selected': selectedButton === '/user-admin'}"><i
          class="bi bi-people icon"></i>
        </div>
      </a>

      <a *ngIf="authService.isAdmin()" routerLink="/departments" aria-expanded="false"
         class="list-group-item list-group-item-action"
         (click)="selectButton('/departments')">
        <div class="circle-container" data-bs-toggle="tooltip" data-bs-placement="right"
             title="Departments" [ngClass]="{'selected': selectedButton === '/departments'}"><i
          class="bi bi-diagram-3 icon"></i>
        </div>
      </a>

      <a *ngIf="authService.isManager()" routerLink="/projects" aria-expanded="false"
         class="list-group-item list-group-item-action"
         (click)="selectButton('/projects')">
        <div class="circle-container" data-bs-toggle="tooltip" data-bs-placement="right"
             title="Project Teams" [ngClass]="{'selected': selectedButton === '/projects'}"><i
          class="bi bi-people-fill icon"></i>
        </div>
      </a>

      <a *ngIf="authService.isManager()" routerLink="/my-department" aria-expanded="false"
         class="list-group-item list-group-item-action"
         (click)="selectButton('/my-department')">
        <div class="circle-container" data-bs-toggle="tooltip" data-bs-placement="right"
             title="My Department" [ngClass]="{'selected': selectedButton === '/my-department'}"><i
          class="bi bi-database icon"></i>
        </div>
      </a>
    </ul>
</nav>


