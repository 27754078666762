import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {Department, DepartmentCreate} from "../dtos/department";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  private departmentBaseUri: string = this.globals.backendUri + '/department';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  addDepartment(department: DepartmentCreate) {
    return this.httpClient.post<Department>(this.departmentBaseUri, department);
  }

  getAllDepartments() {
    return this.httpClient.get<Department[]>(this.departmentBaseUri);
  }

  getDepartment(id: number) {
    return this.httpClient.get<Department>(this.departmentBaseUri + "/" + id);
  }

  sendInvitation(email: string, departmentId: number) {
    const data = {
      email: email,
      departmentId: departmentId
    };
    return this.httpClient.post<void>(this.departmentBaseUri + "/sendInvitation", data);
  }

  getDepartmentByManagerEmail(email: string) {
    return this.httpClient.get<Department>(this.departmentBaseUri + "/manager/" + email);
  }

  updateDepartment(department: Department) {
    return this.httpClient.put<Department>(this.departmentBaseUri + '/' + department.id, department);
  }

  updateDepartmentManager(email: string, departmentId: number): Observable<Department> {
    return this.httpClient.put<Department>(this.departmentBaseUri + '/' + departmentId + "/manager", email);
  }

  deleteDepartment(id: number) {
    return this.httpClient.delete<void>(this.departmentBaseUri + '/' + id);
  }

}
