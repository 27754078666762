import {Component, OnInit} from '@angular/core';
import {User} from "../../dtos/user";
import {AuthService} from "../../services/auth.service";
import {UserService} from "../../services/user.service";
import {ActivatedRoute} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {SafeHtml} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'jw-dropdown-blase',
  templateUrl: './dropdown-blase.component.html',
  styleUrls: ['./dropdown-blase.component.scss']
})
export class DropdownBlaseComponent implements OnInit {

  user: User = {
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    birthday: new Date(),
    jobrole: "",
    password: "",
    role: "",
    department: null,
  }

  errorMessage: SafeHtml = '';
  error = false;

  constructor(public authService: AuthService,
              public userService: UserService,
              private route: ActivatedRoute,
              private notification: ToastrService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.getUser(data);
    });
  }

  getUser(data: any) {
    let email = this.getEmail();
    if (!email)
      email = this.authService.getLoggedInEmail();
    this.userService.getUserByEmail(email).subscribe({
      next: data => {
        this.user = data;
      },
      error: error => {
        this.error = true;
        this.errorMessage = error.error.errorMessage;
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.errorMessage = `<strong>Problems fetching User!</strong> ${error.error}`;
          this.notification.error(`<strong>Problems fetching User!</strong> ${error.error}`, "", {enableHtml: true});
        } else {
          this.notification.error(error.error.errorMessage);
        }

      }
    });
  }

  getEmail(): string {
    return this.route.snapshot.paramMap.get('email');
  }
}
