import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {AuthRequest} from '../../dtos/auth-request';
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  // After first submission attempt, form validation will start
  submitted = false;

  constructor(private formBuilder: UntypedFormBuilder, public authService: AuthService, private router: Router, private notification: ToastrService
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  /**
   * Form validation will start after the method is called, additionally an AuthRequest will be sent
   */
  loginUser() {
    this.submitted = true;
    if (this.loginForm.valid) {
      const authRequest: AuthRequest = new AuthRequest(this.loginForm.controls.username.value, this.loginForm.controls.password.value);
      this.authenticateUser(authRequest);
    }
  }

  /**
   * Send authentication data to the authService. If the authentication was successfully, the user will be forwarded to the message page
   *
   * @param authRequest authentication data from the user login form
   */
  authenticateUser(authRequest: AuthRequest) {
    this.authService.loginUser(authRequest).subscribe({
      next: () => {
        this.router.navigate(['/']);
      },
      error: err => {
        if (typeof err.error === 'object') {
          this.notification.error(err.error.error, "Authentication problems!");
        } else {
          if (err instanceof HttpErrorResponse && err.status === 500) {
            this.notification.error(err.error, "Authentication problems!");
            this.router.navigate(['/500']);
          } else if (err.status == 401) {
            this.router.navigate(['/401']);
            this.notification.error(JSON.parse(err.error).detail, "Authentication problems!");
          } else {
            this.notification.error(err.error, "Authentication problems!");
          }
        }
      }
    });
  }


  ngOnInit() {
  }

}
