<a class="nav-link dropdown-toggle" id="userDropdown" role="button"
   data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <i class="fs-5"><img ngxGravatar [email]="user.email" size="35"/></i>
</a>
<div class="profile_info_inner dropdown-menu dropdown-menu-end p-0 m-0 mt-5 pb-2"
     aria-labelledby="userDropdown">
  <div class="profile_info_inner">
    <div class="profile_author_name sprechblase">
      <p *ngIf="this.authService.isAdmin()"> Administrator </p>
      <p *ngIf="this.authService.isManager()"> Manager </p>
      <p *ngIf="this.authService.isUser()"> Team Member </p>
      <h5 *ngIf="!authService.isAdmin()">
        {{this.user.firstName}} {{this.user.lastName}}
        <!--{{ this.authService.getLoggedInEmail() | slice: 0:this.authService.getLoggedInEmail().indexOf('@') }}-->
      </h5>
    </div>
    <a class="dropdown-item pt-3" data-toggle="modal" data-target="#logoutModal"
       routerLink="/profile">Profile</a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item " data-toggle="modal" data-target="#logoutModal" routerLink="/login"
       (click)="authService.logoutUser()">Logout</a>
  </div>
</div>

