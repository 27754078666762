<div *ngIf="error" class="alert alert-warning alert-dismissible fade show d-flex align-items-center" role="alert">
  <div [innerHTML]="errorMessage" class="flex-grow-1"></div>
  <button type="button" (click)="vanishError()" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<body>
<div class="side-by-side" id="summary">
  <div class="product-container" id="table1">
    <label style="font-size: 40px">{{this.project1.name}}<a
      style="font-size: 25px; margin-left: 20px;"
      class="btn btn-sm responsive-info-button"
      [routerLink]="['../../../edit', project1.id]"
      aria-label="Show user details">
      <i class="bi bi-pen"></i>
    </a></label>


    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">First Name</th>
        <th scope="col">Last Name</th>
        <th scope="col">Jobrole</th>
        <th class="min-width">Actions</th>
      </tr>
      </thead>
      <tbody *ngIf="(project1.members.length > 0); else noUsers">
      <tr *ngFor="let member of project1.members">
        <td>{{member.firstName}}</td>
        <td>{{member.lastName}}</td>
        <td>{{member.jobrole}}</td>
        <td>
          <div class="btn-group">
            <a class="btn btn-sm responsive-info-button"
               [routerLink]="['../../../../profile', member.email]"
               aria-label="Show user details">
              <i class="bi bi-info-lg"></i>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
      <ng-template #noUsers>
        <tr>
          <td colspan="3" style="text-align: center">No members added</td>
        </tr>
      </ng-template>
    </table>

  </div>

  <div class="product-container" id="table2">

    <label style="font-size: 40px">{{this.project2.name}}<a
      style="font-size: 25px; margin-left: 20px;"
      class="btn btn-sm responsive-info-button"
      [routerLink]="['../../../edit', project2.id]"
      aria-label="Show user details">
      <i class="bi bi-pen"></i>
    </a></label>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">First Name</th>
        <th scope="col">Last Name</th>
        <th scope="col">Jobrole</th>
        <th class="min-width">Actions</th>
      </tr>
      </thead>
      <tbody *ngIf="(project2.members.length > 0); else noUsers">
      <tr *ngFor="let member of project2.members">
        <td>{{member.firstName}}</td>
        <td>{{member.lastName}}</td>
        <td>{{member.jobrole}}</td>
        <td>
          <div class="btn-group">
            <a class="btn btn-sm responsive-info-button"
               [routerLink]="['../../../../profile', member.email]"
               aria-label="Show user details">
              <i class="bi bi-info-lg"></i>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
      <ng-template #noUsers>
        <tr>
          <td colspan="3" style="text-align: center">No members added</td>
        </tr>
      </ng-template>
    </table>

  </div>
</div>

<div class="row justify-content-center" id="outerAccordion">
  <div class="accordion card-body border-light" id="accordionPanelsStayOpenExample">
    <div class="accordion-item-compare  border-0">
      <h2 class="accordion-header">
        <button class="accordion-button-compare collapsed border-0" type="button"
                data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                aria-controls="panelsStayOpen-collapseOne">
          <h5 class="px-1 py-0 m-0">Zusammensetzung der Eigenschaften</h5>

        </button>
      </h2>
      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse"
           aria-labelledby="panelsStayOpen-headingOne">
        <div class="side-by-side">
          <div class="product-container" id="graphs1">
            <div *ngIf="project1.members.length>0; else noMembers1">
              <div class="pieCharts">
                <div class="pieChart">
                  <div>
                    <h4>Psychologische Eigenschaften</h4></div>
                  <canvas id="pieChartPsycho1"></canvas>
                </div>
                <div class="pieChart">
                  <div>
                    <h4>Prozess Eigenschaften</h4>
                  </div>
                  <canvas id="pieChartProcess1"></canvas>
                </div>
                <div class="pieChart">
                  <div>
                    <h4>Psychologischer Trim Trail</h4>
                  </div>
                  <canvas id="pieChartNeuro1"></canvas>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noMembers1>
            <div>
              <label>Add members to view assessment</label>
            </div>
          </ng-template>

          <div class="product-container" id="graphs2">
            <div *ngIf="project2.members.length>0; else noMembers2">
              <div class="pieCharts">
                <div class="pieChart">
                  <div>
                    <h4>Psychologische Eigenschaften</h4></div>
                  <canvas id="pieChartPsycho2"></canvas>
                </div>
                <div class="pieChart">
                  <div>
                    <h4>Prozess Eigenschaften</h4>
                  </div>
                  <canvas id="pieChartProcess2"></canvas>
                </div>
                <div class="pieChart">
                  <div>
                    <h4>Psychologischer Trim Trail</h4>
                  </div>
                  <canvas id="pieChartNeuro2"></canvas>
                </div>
              </div>
            </div>
            <ng-template #noMembers2>
              <div>
                <label>Add members to view assessment</label>
              </div>
            </ng-template>
          </div>

        </div>
      </div>
    </div>
    <div class="accordion-item-compare  border-light">
      <h2 class="accordion-header">
        <button class="accordion-button-compare collapsed border-0" type="button"
                data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo">
          <h5 class="px-1 py-0 m-0">Differenz der Eigenschaften</h5>

        </button>
      </h2>

      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
           aria-labelledby="panelsStayOpen-headingTwo">
        <div class="accordion-body" id="barBody">

          <div class="overallBarCharts">
            <div class="overallBarChart">
              <h4>Psychologische Eigenschaften</h4>
              <canvas id="overallBarChartPsycho"></canvas>
              <div *ngFor="let trait of psychoTraits1">
                <strong>{{ trait.trait }}:</strong> {{ trait.explanation }}
              </div>
            </div>

            <div class="overallBarChart">
              <h4>Prozess Eigenschaften</h4>
              <canvas id="overallBarChartProcess"></canvas>
              <div *ngFor="let trait of processTraits1">
                <strong>{{ trait.trait }}:</strong> {{ trait.explanation }}
              </div>
            </div>
            <div class="overallBarChart">
              <h4>Psychologischer Trim Trail</h4>
              <canvas id="overallBarChartNeuro"></canvas>
              <div *ngFor="let trait of neuroTraits1">
                <strong>{{ trait.trait }}:</strong> {{ trait.explanation }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item-compare  border-0">
      <h2 class="accordion-header">
        <button class="accordion-button-compare collapsed border-0" type="button"
                data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                aria-controls="panelsStayOpen-collapseThree">
          <h5 class="px-1 py-0 m-0">Neurologische Verteilung</h5>

        </button>
      </h2>
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
           aria-labelledby="panelsStayOpen-headingThree">
        <div class="accordion-body" id="neuroBody">
          <div id="neuroContent">
            <div id="neuroMapDiv">
              <canvas id="neuroMap"></canvas>
            </div>
            <div id="outerNeuroMapDescription">
              <div class="neuroMapDescription">
                Die 1. Axe stellt die <strong>Willensbahnung</strong> dar. <br> Mit steigendem Wert nimmt die
                Handlungsbereitschaft
                ab und die
                prospektive Lageorientierung zu.<br>
                Stark negative Werte weisen auf einen ausgeprägten Mangel an Selbstbremsung, stark positive Werte weisen
                auf
                einen ausgeprägten Mangel an Selbstmotivation.
              </div>
              <div class="neuroMapDescription">
                Die 2. Axe stellt das <strong>Selbstwachstum</strong> dar. <br>Mit steigendem Wert nimmt die
                Vermeidungsuche
                von
                Konflikten ab und
                die Rigidität zu.<br> Stark negative Werte weisen auf einen ausgeprägten Mangel an
                Selbstkonfrontationsfähigkeit,<br>
                stark positive Werte
                weisen auf einen ausgeprägten Mangel an Selbstberuhigung.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


</body>

