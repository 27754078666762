import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";

declare var $: any; // Declare $ to use jQuery/Bootstrap for tooltips

@Component({
  selector: 'app-sidebar-shrink',
  templateUrl: './sidebar-shrink.component.html',
  styleUrls: ['./sidebar-shrink.component.scss']
})
export class SidebarShrinkComponent implements OnInit {

  errorMessage = '';
  submitted = false;

  constructor(public authService: AuthService) {
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $('[data-bs-toggle="tooltip"]').tooltip();
    });
  }

  selectedButton: string = '';

  selectButton(button: string): void {
    this.selectedButton = button;
  }

}
