import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {SimpleUser} from "../../../dtos/user";
import {UserService} from "../../../services/user.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {SafeHtml} from "@angular/platform-browser";
import {NgForm} from "@angular/forms";
import {Observable} from "rxjs";
import {JobRoles} from "../../../dtos/register-request";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  user: SimpleUser = {
    firstName: "",
    lastName: "",
    birthday: new Date(),
    jobrole: "",
    email: "",
    role: "",
    departmentName: null,
  }

  errorMessage: SafeHtml = '';
  successMessage: SafeHtml = '';
  submitted = false;
  showDeleteConfirmation: boolean = true;
  minDate: string;
  maxDate: string;
  jobRoles = Object.values(JobRoles);


  constructor(private userService: UserService,
              public authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private notification: ToastrService) {
    this.setDates();
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/401']).then(r => console.log('Error status 401'));
    }
    this.route.data.subscribe(data => {
      if (this.authService.getLoggedInEmail() == "admin@email.com" || this.authService.getLoggedInEmail() == this.getEmail()) {
        this.getUser(data);
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  getUser(data: any) {
    this.userService.getUserByEmail(this.getEmail()).subscribe({
      next: data => {
        this.user = data;
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 500) {
          this.errorMessage = `<strong>Internal Server Error!</strong> ${err.error}`;
          this.notification.error(err.error, "Internal Server Error!");
          this.router.navigate(['/500']);
        }
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.errorMessage = `<strong>Problems fetching User!</strong> ${err.error}`;
          this.notification.error(err.error, "Problems fetching User!");
        }
      }
    });
  }

  getEmail(): string {
    return this.route.snapshot.paramMap.get('email');
  }

  /**
   * Saves changed profile data
   */
  save(form: NgForm) {
    if (form.valid) {
      let observable: Observable<SimpleUser>;
      observable = this.userService.updateUser(this.user);
      observable.subscribe({
        next: data => {
          this.user = data;
          this.successMessage = `Successfully updated User! ${this.user.firstName} ${this.user.lastName}`;
          this.router.navigate(['/profile', this.user.email]).then(r => console.log("navigated to profile"));
        },
        error: err => {
          if (err instanceof HttpErrorResponse && err.status === 404) {
            this.errorMessage = err.error.detail;
            this.notification.error(err.error.detail, "Problems updating User!");
          }
          if (err instanceof HttpErrorResponse && err.status === 400) {
            if (err.error.detail) {
              this.errorMessage = `<strong>Problems deleting User!</strong> ${err.error.detail}`;
              this.notification.error(err.error.detail, "Problems updating User!");
            } else {
              const validationErrorsString = err.error;
              this.errorMessage = `<strong>Problems updating User!</strong> ${validationErrorsString}`;
              const startIndex = err.error.indexOf("[");
              const endIndex = err.error.lastIndexOf("]");
              if (startIndex !== -1 && endIndex !== -1) {
                const validationErrorsString = err.error.substring(startIndex + 1, endIndex);
                this.errorMessage = `<strong>Problems deleting User!</strong> ${validationErrorsString}`;
                this.notification.error(validationErrorsString, "Problems updating User!");
              } else {
                this.notification.error(validationErrorsString, "Problems updating User!");
              }
            }
          }
        }
      });
    }
  }

  /**
   * Following two methods necessary for confirmation dialog
   */
  onDelete() {
    this.showDeleteConfirmation = true;
  }

  onCancelDelete(): void {
    this.showDeleteConfirmation = false;
  }

  /**
   * Deletes currently logged in User
   */
  delete() {
    this.showDeleteConfirmation = false;
    this.userService.deleteUser(this.user.email.valueOf()).subscribe({
      next: () => {
        this.successMessage = "Successfully deleted user " + this.user.email.valueOf() + " from the system.";
        this.notification.success("Successfully deleted user " + this.user.email.valueOf() + " from the system.");
        this.router.navigate(['/user-admin']).then(r => console.log("navigated to all-members"));
      },
      error: error => {
        this.errorMessage = JSON.parse(error.error).detail;
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.errorMessage = `<strong>Problems deleting User!</strong> ${error.error}`;
          this.notification.error(error.error, "Problems deleting User!");
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {
          const startIndex = error.error.indexOf("[");
          const endIndex = error.error.lastIndexOf("]");
          if (startIndex !== -1 && endIndex !== -1) {
            const validationErrorsString = error.error.substring(startIndex + 1, endIndex);
            this.errorMessage = `<strong>Problems deleting User!</strong> ${validationErrorsString}`;
            this.notification.error(validationErrorsString, "Problems deleting User!");
          } else {
            this.notification.error(JSON.parse(error.error).detail, "Problems deleting User!");
          }
        } else {
          this.notification.error(JSON.parse(error.error).detail, "Problems deleting User!");
        }
      }
    });
  }

  setDates() {
    let maxDate = new Date();
    this.maxDate = maxDate.toISOString().split('T')[0]
    let minDate = new Date(1900, 0, 1);
    this.minDate = minDate.toISOString().split('T')[0];
  }
}
