import {Project} from "./project";
import {SimpleUser} from "./user";

export interface Department {
  id: number;
  name: string;
  manager: SimpleUser;
  members: SimpleUser[];
  projects: Project[];
  createdAt: string;
}

export interface DepartmentCreate {
  name?: string;
  manager?: string;
  members?: SimpleUser[];
}

export class CreateRequest {
  constructor(
    public name: string,
    public manager: string,
    public members: SimpleUser[]
  ) {
  }
}
