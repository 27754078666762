<nav class="navbar navbar-expand-lg bg-header d-flex justify-content-between align-items-center" id="bg-blur">
  <img class="navbar-logo" src="../../../assets/Logo.png" alt="Logo">
  <a class="navbar-brand lead ps-3" href="">
    <strong class="fs-5 font-monospace">M</strong>astering <strong
    class="fs-5 font-monospace">A</strong>scendancy
    <strong class="fs-5 font-monospace">L</strong>eadership <strong
    class="fs-5 font-monospace">S</strong>pectrum
  </a>
  <!--

  Toggle Button (navbar-toggler ml-5)-->
  <!--<button class="btn btn-link" *ngIf="authService.isLoggedIn()"
          type="button" (click)="toggleSidebar()"
          data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
  </button>-->

  <form class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
    <!-- <div class="input-group" *ngIf="authService.isLoggedIn()">
         <div class="sidebar-search pt-1 pb-1 pl-3 pr-3">
             <input class="form-control rounded-pill" name="search" placeholder="Search ..." type="search">
         </div>
     </div>-->
    <div *ngIf="!authService.isLoggedIn()">
      <ul class="navbar-nav mr-5">
        <a class="nav-link" *ngIf="!authService.isLoggedIn()" routerLink="/login" routerLinkActive="active">Login</a>
        <a class="nav-link" *ngIf="!authService.isLoggedIn()" routerLink="/register"
           routerLinkActive="active">Register</a>
      </ul>
    </div>
  </form>

  <!-- Dropdown Toggle Items-->
  <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4" *ngIf="authService.isLoggedIn()">
    <li class="nav-item dropdown text-decoration-none">
      <jw-dropdown-blase></jw-dropdown-blase>
    </li>
  </ul>
</nav>
