// invite.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  private apiUrl = 'http://localhost:8080/api/v1/user/departmentInvite';

  constructor(private httpClient: HttpClient) {}

  processInviteLink(email: string, token: string, user:string): Observable<any> {
    const url = `${this.apiUrl}/${email}/${token}/${user}`;
    return this.httpClient.put(url, {});
  }
}
