import {Component} from '@angular/core';
import {QuestionService} from "../../services/question.service";
import {Question} from "../../dtos/question";
import {AuthService} from "../../services/auth.service";
import {AnswerService} from "../../services/answer.service";
import {Answer} from "../../dtos/answer";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {ceil} from "lodash";
import {HttpErrorResponse} from "@angular/common/http";
import {SafeHtml} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";


@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent {

  questionnaire: Question[]
  selectedOption: string[]
  questionsAnswered: boolean = false;
  counter: number;
  pageSize: number = 7;
  pageOfItems: Array<any>;
  errorMessage: SafeHtml = '';
  protected readonly ceil = ceil;

  constructor(private questionService: QuestionService, private authenticationService: AuthService,
              private answerService: AnswerService, private router: Router, private notification: ToastrService
  ) {
  }

  ngOnInit() {
    if (!this.authenticationService.isLoggedIn()) {
      this.router.navigate(['/401']).then(r => console.log('Error status 401'));
    }
    this.getQuestionnaire();
    this.getAnswers();
  }


  //Gets all questions from level 1 (that is process oriented) the other questions are not yet stored in the backend.
  //After all questions are stored in the backend the level can be omitted
  private getQuestionnaire() {
    this.questionService.getAllQuestions().subscribe({
      next: (questionnaire: Question[]) => {
        this.questionnaire = questionnaire;
        this.selectedOption = new Array(this.questionnaire.length);
      },
      error: error => {
      }
    });
  }

  //Gets all Answers from the logged in User
  private getAnswers() {
    const email = this.authenticationService.getLoggedInEmail();

    try {
      if (email) {
        this.answerService.getAnswers(email).subscribe({
          next: (answers: Answer[]) => {
            for (let i = 0; i < this.selectedOption?.length; i++) {
              this.selectedOption[i] = answers[i]?.answer?.toString();
            }
          },
          error: error => {
            let message: string;
            if (error instanceof HttpErrorResponse && error.status === 404) {
              this.errorMessage = `<strong>Problems fetching answers !</strong> ${error.error}`;
              message = error.error;
            }
            if (error instanceof HttpErrorResponse && error.status === 400) {
              if (error.error && error.error.detail) {
                this.errorMessage = `<strong>Problems fetching answers !</strong> ${error.error.detail}`;
                message = error.error.detail;
              } else {
                const validationErrors = error.error?.detail?.match(/\[(.*?)\]/);
                if (validationErrors) {
                  const validationErrorsString = validationErrors[1];
                  this.errorMessage = `<strong>Problems fetching answers !</strong> ${validationErrorsString}`;
                  message = validationErrorsString;
                } else {
                  this.errorMessage = `<strong>Problems fetching answers !</strong> ${error.error}`;
                  message = error.error
                }
              }
            }
            this.notification.error(message, "Error getting answers!");
          }
        });
      } else {
        console.error('User email not available.');
      }
    } catch (e) {
    }
  }

  // On submit of the form first array of answerDtos is created with the function mapToDto. To identify the user the email is used
  // Therefore the email is equal for all users. The questions are fetched from the array questionnaire
  // Then Answers are send to backend
  public onSubmit(NgForm): void {
    let answersDto = this.mapToDto(this.selectedOption)
    let observable: Observable<Answer[]>;
    observable = this.answerService.putAnswers(answersDto);
    observable.subscribe({
      next: data => {
        this.router.navigate(['']);
      },
      error: error => {
        let message: string;
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.errorMessage = `<strong>Problems with Questionnaire !</strong> ${error.error}`;
          message = error.error;
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {
          if (error.error && error.error.detail) {
            this.errorMessage = `<strong>Problems with Questionnaire !</strong> ${error.error.detail}`;
            message = error.error.detail;
          } else {
            const validationErrors = error.error?.detail?.match(/\[(.*?)\]/);
            if (validationErrors) {
              const validationErrorsString = validationErrors[1];
              this.errorMessage = `<strong>Problems with Questionnaire!</strong> ${validationErrorsString}`;
              message = validationErrorsString;
            } else {
              this.errorMessage = `<strong>Problems with Questionnaire!</strong> ${error.error}`;
              message = error.error
            }
          }
        }
        this.notification.error(message, "Problems with Questionnaire!");
      }
    });
  }

  public isAnswered(): boolean {
    return this.questionsAnswered;
  }

  private mapToDto(answers: String[]): Answer[] {
    let email: string = this.authenticationService.getLoggedInEmail();
    let answersDto = new Array(answers.length);
    for (let i = 0; i < answers.length; i++) {
      if (answers[i] == undefined) {
        answers[i] = "0";
      }
      let answerDto: Answer = {
        emailUser: email,
        question: this.questionnaire[i],
        answer: parseInt(String(answers[i]))
      };
      answersDto[i] = answerDto;
      this.questionsAnswered = true
    }
    return answersDto;
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  setCounter(counter: number) {
    this.counter = counter + 1;
  }

}
