import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {ChatOpenAI} from "langchain/chat_models/openai";
import {Ollama} from "langchain/llms/ollama";
import {DynamicTool, formatToOpenAIFunction} from "langchain/tools";
import {ChatPromptTemplate, MessagesPlaceholder,} from "@langchain/core/prompts";
import {RunnableSequence} from "@langchain/core/runnables";
import {AgentExecutor} from "langchain/agents";
import {AgentStep} from "langchain/schema";
import {formatForOpenAIFunctions} from "langchain/agents/format_scratchpad";
import {OpenAIFunctionsAgentOutputParser} from "langchain/agents/openai/output_parser";


@Component({
  selector: 'app-gpt',
  templateUrl: './gpt.component.html',
  styleUrls: ['./gpt.component.scss']
})
export class GptComponent implements OnInit {

  gptForm: UntypedFormGroup;
  submitted = false;
  errorMessage = '';
  streamedData: string = ' ';

  constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private router: Router) {
    this.gptForm = this.formBuilder.group({
      prompt: ['', [Validators.required]],
    });
  }

  /**
   * Form validation will start after the method is called
   */
  callGpt() {
    this.submitted = true;
    if (this.gptForm.valid) {

      const personalKey = null;


      if (personalKey == null) {
        this.streamedData = "GPT only works with a valid openAIApiKey \n https://platform.openai.com/account/api-keys"
      } else {

        const chatModel = new ChatOpenAI({
          modelName: 'gpt-3.5-turbo',
          temperature: 0.1,
          openAIApiKey: personalKey,
          maxTokens: 10,
        });

        // patch begin
        // for zone.js openAI APIPromise
        const original = (chatModel as any)._getClientOptions;
        (chatModel as any)._getClientOptions = function (options: any) {
          original.call(this, options);
          const create = this.client.chat.completions.create;
          this.client.chat.completions.create = function (params: any) {
            return new Promise((res, rej) => {
              return create.call(this, params).then(res, rej);
            });
          }
        };

        // patch end---------------------------------

        //const text = 'What would be a good company name for a company that makes colorful socks?';
        const text = this.gptForm.controls.prompt.value;

        this.streamedData = "loading...";

        // Using a Promise
        // This call throws immediately and doesn't wait for response
        // response looks good in the Network tab
        chatModel.predict(text).then((result: any) => {
          this.streamedData = result;
        });
      }
    } else {
    }
  }


  callOllama() {
    this.submitted = true;
    if (this.gptForm.valid) {

      const ollamaModel = new Ollama({
        baseUrl: "http://localhost:11434", // Default value
        model: "mistral", // Default value
      });

      // patch begin
      // for zone.js openAI APIPromise
      const original = (ollamaModel as any)._getClientOptions;
      (ollamaModel as any)._getClientOptions = function (options: any) {
        original.call(this, options);
        const create = this.client.chat.completions.create;
        this.client.chat.completions.create = function (params: any) {
          return new Promise((res, rej) => {
            return create.call(this, params).then(res, rej);
          });
        }
      };

      // patch end---------------------------------
      const text = this.gptForm.controls.prompt.value;


      this.streamedData = "loading...";

      ollamaModel.predict(text).then((result: any) => {
        this.streamedData = result;
      });

    } else {
    }
  }


  callCustomGpt() {

    const model = new ChatOpenAI({
      modelName: "gpt-3.5-turbo",
      temperature: 0,
      openAIApiKey: "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"
    });

    const customTool = new DynamicTool({
      name: "get_word_length",
      description: "Returns the length of a word.",
      func: async (input: string) => input.length.toString(),
    });

    /** Define your list of tools. */
    const tools = [customTool];

    const prompt = ChatPromptTemplate.fromMessages([
      ["system", "You are very powerful assistant, but don't know current events"],
      ["human", "{input}"],
      new MessagesPlaceholder("agent_scratchpad"),
    ]);

    const modelWithFunctions = model.bind({
      functions: tools.map((tool) => formatToOpenAIFunction(tool)),
    });

    const runnableAgent = RunnableSequence.from([
      {
        input: (i: { input: string; steps: AgentStep[] }) => i.input,
        agent_scratchpad: (i: { input: string; steps: AgentStep[] }) =>
          formatForOpenAIFunctions(i.steps),
      },
      prompt,
      modelWithFunctions,
      new OpenAIFunctionsAgentOutputParser(),
    ]);

    const executor = AgentExecutor.fromAgentAndTools({
      agent: runnableAgent,
      tools,
    });


    const input = "How many letters in the word educa?";


    // patch begin
    // for zone.js openAI APIPromise
    const original = (model as any)._getClientOptions;
    (model as any)._getClientOptions = function (options: any) {
      original.call(this, options);
      const create = this.client.chat.completions.create;
      this.client.chat.completions.create = function (params: any) {
        return new Promise((res, rej) => {
          return create.call(this, params).then(res, rej);
        });
      }
    };

    // patch end---------------------------------


    model.predict(input).then((result: any) => {
      this.streamedData = result;
    });
    /*
      Loaded agent executor
      Calling agent executor with query: What is the weather in New York?
      {
        input: 'How many letters in the word educa?',
        output: 'There are 5 letters in the word "educa".'
      }
    */


  }


  ngOnInit() {
  }

}
