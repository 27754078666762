import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../global/globals';
import {RegisterRequest} from '../dtos/register-request';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private authBaseUri: string = this.globals.backendUri + '/register';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  registerUser(registerRequest: RegisterRequest): Observable<string> {
    return this.httpClient.put(this.authBaseUri, registerRequest, {responseType: 'text'});
  }

  getToken() {
    return localStorage.getItem('registerToken');
  }

}
