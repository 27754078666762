import {Component, OnInit} from '@angular/core';
import {Project} from "../../dtos/project";
import {AuthService} from "../../services/auth.service";
import {UserService} from "../../services/user.service";
import {ProjectService} from "../../services/project.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {DepartmentService} from "../../services/department.service";

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  constructor(public authService: AuthService,
              public departmentService: DepartmentService,
              private router: Router,
              public userService: UserService,
              private notification: ToastrService,
              public projectService: ProjectService) {
  }

  errorMessage = '';
  projects: Project[];
  submitted = false;

  selectedProject: Project = {id: -1, name: "", members: [], department: null}

  showDeleteConfirmation: boolean = true;

  name: string;

  counter: number;
  pageSize: number = 10;
  pageOfItems: Array<Project>;

  onDelete(project: Project) {
    this.selectedProject = project;
    this.showDeleteConfirmation = true;
  }

  onCancelDelete() {
    this.showDeleteConfirmation = false;
  }

  deleteProject() {
    this.projectService.deleteProject(this.selectedProject.id).subscribe({
      next: data => {
        this.reloadProjects();
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 500) {
          this.errorMessage = `<strong>Internal Server Error!</strong> ${err.error}`;
          this.router.navigate(['/500']).then(r => console.log('Error status 500'));
        }
      }
    });
  }

  reloadProjects() {
    this.projectService.getAllOfDepartment(this.authService.getLoggedInEmail()).subscribe({
      next: data => {
        this.projects = data;
      }
    });
  }

  ngOnInit(): void {
    if (!this.authService.isLoggedIn() || !(this.authService.isManager())) {
      this.router.navigate(['/401']).then(r => console.log('Error status 401'));
    }
    this.projectService.getAllOfDepartment(this.authService.getLoggedInEmail()).subscribe({
      next: data => {
        this.projects = data;
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 500) {
          this.errorMessage = `<strong>Internal Server Error!</strong> ${err.error}`;
          this.router.navigate(['/500']).then(r => console.log('Error status 500'));
        }
      }
    });
  }

  createProject(form: NgForm) {
    if (form.valid) {
      this.submitted = true;

      this.projectService.createProject({
        name: this.name,
        managerEmail: this.authService.getLoggedInEmail()
      }).subscribe({
        next: data => {
          this.reloadProjects();
          this.notification.success("Project created", "Success!");
          this.name = '';
        },
        error: err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 500) {
              this.errorMessage = '<strong>Internal Server Error!</strong> Could not connect to server.';
              this.notification.error("Could not connect to server.", "Internal Server Error!");
              this.router.navigate(['/500']).then(r => console.log('Error status 500'));
            } else if (err.status === 404) {
              this.errorMessage = err.error;
              if (err.error == "Department could not be found") {
                this.notification.error("Please create a department before creating projects");
              } else {
                this.notification.error(err.error, "Problems creating Project!");
              }
              console.error(err.error);
              this.router.navigate(['/my-department']).then(r => console.log('Error status 404'));
            } else if (err.status === 400) {
              const startIndex = err.error.indexOf("[");
              const endIndex = err.error.lastIndexOf("]");
              if (startIndex !== -1 && endIndex !== -1) {
                const validationErrorsString = err.error.substring(startIndex + 1, endIndex);
                this.errorMessage = `<strong>Problems creating Project!</strong> ${validationErrorsString}`;
                this.notification.error(validationErrorsString, "Problems creating Project!");
              }
            }
          } else {
            this.errorMessage = err.error;
            this.notification.error(err.error, "Problems creating Project!");
            console.error(err.error)
          }
        }
      })
    }
  }

  onChangePage(pageOfItems: Array<Project>) {
    this.pageOfItems = pageOfItems;
  }

  setCounter(counter: number) {
    this.counter = counter + 1;
  }

}
