import {Component, OnInit, TemplateRef} from '@angular/core';
import {SimpleUser, User} from "../../dtos/user";
import {NgForm} from "@angular/forms";
import {debounceTime, Observable, Subject} from "rxjs";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {UserService} from "../../services/user.service";
import {HttpErrorResponse} from "@angular/common/http";
import {DepartmentService} from "../../services/department.service";
import {Department} from "../../dtos/department";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],
  providers: [UserService, DepartmentService]
})
export class DepartmentsComponent implements OnInit {

  errorMessage = '';
  successMessage = '';
  submitted = false;

  departments: Department[];
  currentDepartment: Department | undefined;
  userForRemoval: User | undefined;

  users: SimpleUser[];
  searchChangedObservable = new Subject<void>();

  validDepartmentName = true;
  validManagerEmail = true;

  managersWithoutDepartment: SimpleUser[];

  isWideScreen: boolean;

  user: User = {
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    birthday: null,
    jobrole: "",
    password: "",
    role: "",
    department: null,
  }

  constructor(private departmentService: DepartmentService,
              public authService: AuthService,
              private router: Router,
              public userService: UserService,
              public modal: NgbModal,
              private modalService: NgbModal,
              private notification: ToastrService) {
    this.checkScreenWidth1400();
    window.addEventListener('resize', () => this.checkScreenWidth1400());
  }

  private checkScreenWidth1400() {
    this.isWideScreen = window.innerWidth >= 1400;
  }

  ngOnInit() {
    if (!(this.authService.isLoggedIn()) || !(this.authService.isAdmin())) {
      this.router.navigate(['/401']).then(r => console.log(401));
    } else {
      this.userService.getAllUsers().subscribe((data) => {
        this.users = data;
      });
      this.departmentService.getAllDepartments().subscribe((data) => {
        this.departments = data;
      });
      this.searchChangedObservable
        .pipe(debounceTime(300))
        .subscribe({next: () => this.reloadDepartments()});

      this.getAvailableManagers();
    }
  }

  getAvailableManagers() {
    this.userService.getManagersWithoutDepartment().subscribe({
      next: (users: SimpleUser[]) => {
        this.managersWithoutDepartment = users;
      },
      error: error => {
        this.defaultServiceErrorHandling(error);
      }
    });
  }

  private reloadDepartments() {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/401']).then(r => console.log('Error status 401'));
    }
    this.departmentService.getAllDepartments().subscribe({
      next: (department: Department[]) => {
        this.departments = department;
      },
      error: error => {
        this.defaultServiceErrorHandling(error);
      }
    });
  }


  save(form: NgForm) {
    if (form.valid) {
      let observable: Observable<Department>;
      this.currentDepartment.name = form.controls.name.value;
      observable = this.departmentService.updateDepartment(this.currentDepartment);
      observable.subscribe({
        next: data => {
          this.currentDepartment = data;
          this.validDepartmentName = true;
          this.successMessage = `<strong>Successfully updated Department "${this.currentDepartment.name}"!</strong>`;
          this.reloadDepartments();
          this.notification.success(this.successMessage, "", {enableHtml: true});
        },
        error: error => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            this.errorMessage = error.error;
            this.validDepartmentName = false;
          }
          if (error instanceof HttpErrorResponse && error.status === 400) {
            const startIndex = error.error.indexOf("[");
            const endIndex = error.error.lastIndexOf("]");
            this.validDepartmentName = false;
            if (startIndex !== -1 && endIndex !== -1) {
              const validationErrorsString = error.error.substring(startIndex + 1, endIndex);
              this.errorMessage = `<strong>Problems updating Department!</strong> ${validationErrorsString}`;
            }
          }
          this.notification.error(this.errorMessage, "", {enableHtml: true});
        }
      });
    }
  }

  updateManager(managerForm: NgForm) {
    if (managerForm.valid) {
      let observable: Observable<Department>;
      this.currentDepartment.manager.email = managerForm.controls.name.value;
      observable = this.departmentService.updateDepartmentManager(this.currentDepartment.manager.email, this.currentDepartment.id);
      observable.subscribe({
        next: data => {
          this.currentDepartment = data;
          this.validManagerEmail = true;
          this.successMessage = `<strong>Successfully updated Department "${this.currentDepartment.name}"!</strong>`;
          this.notification.success(this.successMessage, "", {enableHtml: true});
          this.reloadDepartments();
        },
        error: err => {
          this.modal.dismissAll('Confirm click');
          if (err instanceof HttpErrorResponse && err.status === 404) {
            this.errorMessage = err.error.detail;
            this.validManagerEmail = false;
          }
          if (err instanceof HttpErrorResponse && err.status === 400) {
            const validationErrorsString = err.error.detail;
            this.validManagerEmail = false;
            this.errorMessage = `<strong>Problems updating Manager of Department! </strong> ${validationErrorsString}`;
          }
          this.notification.error(this.errorMessage, "", {enableHtml: true});
        }
      });
    }
  }

  remove(removalRequest: string) {
    this.userService.removeUserFromDepartment(removalRequest).subscribe({
      next: () => {
        this.successMessage = "Successfully removed user " + removalRequest + " from the department.";
        this.notification.success(this.successMessage);
        this.ngOnInit();
      },
      error: error => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.errorMessage = "Error: " + error.error + "!";
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {

          const startIndex = error.error.indexOf("[");
          const endIndex = error.error.lastIndexOf("]");
          if (startIndex !== -1 && endIndex !== -1) {
            const validationErrorsString = error.error.substring(startIndex + 1, endIndex);

            this.errorMessage = validationErrorsString;
          }
        }
        this.errorMessage = error.error;
        this.notification.error(this.errorMessage);
      }
    });
    this.modal.dismissAll('Confirm click');
  }

  deleteDep(id: number) {
    this.departmentService.deleteDepartment(id).subscribe({
      next: () => {
        this.successMessage = "Successfully deleted department " + id + ".";
        this.notification.success(this.successMessage);
        this.ngOnInit();
      },
      error: error => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.errorMessage = "Error: " + error.error + "!";
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {
          const startIndex = error.error.indexOf("[");
          const endIndex = error.error.lastIndexOf("]");
          if (startIndex !== -1 && endIndex !== -1) {
            const validationErrorsString = error.error.substring(startIndex + 1, endIndex);
            this.errorMessage = validationErrorsString;
          }
        }
        this.errorMessage = error.error;
        this.notification.error(this.errorMessage);
      }
    });
    this.modal.dismissAll('Confirm click');
  }


  private defaultServiceErrorHandling(error: any) {
    if (typeof error.error === 'object') {
      this.errorMessage = error.error.error;
    } else {
      this.errorMessage = error.error;
    }
    this.notification.error(this.errorMessage)
  }

  openDialog(id: number, removeWhat: string, dialogModal: TemplateRef<any>) {
    if (id == null) {
      this.userService.getUserByEmail(removeWhat).subscribe({
        next: res => {
          this.userForRemoval = res;
          this.modalService.open(dialogModal, {ariaLabelledBy: 'modal-basic-title'});
        },
        error: err => {
          this.defaultServiceErrorHandling(err);
        }
      });
    } else if (removeWhat == null) {
      this.departmentService.getDepartment(id).subscribe({
        next: res => {
          this.currentDepartment = res;
          this.modalService.open(dialogModal, {ariaLabelledBy: 'modal-basic-title'});
        },
        error: err => {
          this.defaultServiceErrorHandling(err);
        }
      });
    } else if (id == this.currentDepartment.id && removeWhat == this.currentDepartment.name) {
      this.modalService.open(dialogModal, {ariaLabelledBy: 'modal-basic-title'});
    }
  }

  closeEditModal() {
    this.modal.dismissAll('Close');
    this.reloadDepartments();
  }

}

