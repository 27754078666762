import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {User} from "../../../dtos/user";
import {SafeHtml} from "@angular/platform-browser";
import {UserService} from "../../../services/user.service";


@Component({
    selector: 'app-not-authorized',
    templateUrl: './not-authorized.component.html',
    styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent {

    constructor(public authService: AuthService) {
    }
}
