import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {Project, ProjectCreate} from "../dtos/project";
import {Observable} from "rxjs";
import {TeamAssessment} from "../dtos/teamAssessment";
import {NeuroMap} from "../dtos/neuromap";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private projectBaseUri: string = this.globals.backendUri + '/projects';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  public createProject(projectCreate: ProjectCreate): Observable<Project> {
    return this.httpClient.post<Project>(this.projectBaseUri, {
      name: projectCreate.name,
      managerEmail: projectCreate.managerEmail
    });
  }

  public getById(id: number): Observable<Project> {
    return this.httpClient.get<Project>(this.projectBaseUri + "/" + id);
  }

  public getAll(): Observable<Project[]> {
    return this.httpClient.get<Project[]>(this.projectBaseUri);
  }

  public getAllOfDepartment(managerEmail: string): Observable<Project[]> {
    return this.httpClient.get<Project[]>(this.projectBaseUri + "/ofDepartment/" + managerEmail);
  }

  public updateProject(project: Project): Observable<Project> {
    return this.httpClient.put<Project>(this.projectBaseUri, project);
  }

  public deleteProject(id: number): Observable<void> {
    return this.httpClient.delete<void>(this.projectBaseUri + "/" + id);
  }

  public getAssessment(id: number): Observable<TeamAssessment> {
    return this.httpClient.get<TeamAssessment>(this.projectBaseUri + "/assessment/" + id);
  }

  public getNeuroMap(id: number): Observable<NeuroMap[]> {
    return this.httpClient.get<NeuroMap[]>(this.projectBaseUri + "/assessment/neuromap/" + id);
  }

  public createOptimalTeam(numberOfMembers: number, teamMembers: string[], projectId: number, scores: number[]) {
    return this.httpClient.post(this.projectBaseUri + "/optimalTeam/", {
      numberOfMembers: numberOfMembers,
      requiredRoles: teamMembers,
      projectId: projectId,
      scores: scores
    });
  }
}
