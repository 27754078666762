<div class="container">

  <div class="row justify-content-center pb-4 pt-lg-5" id="personalInformation">
    <div class="card shadow-light rounded-5 border-0 px-0 mb-4">

      <div *ngIf="user.role === 'USER' || user.role === 'MANAGER' || user.role === 'ADMIN'" class="card-header border-0"
           [ngClass]="{'user-bg': user.role === 'USER', 'manager-bg': user.role === 'MANAGER', 'admin-bg': user.role === 'ADMIN'}">
        <div class="card-skills text-end mt-2">
          <div class="role text-end">{{this.user.role}}</div>
        </div>
        <div class="circle circle5">
          <i class="p-2"><img ngxGravatar [email]="user.email" size="155"/></i>
        </div>
      </div>

      <div class="card-body bg-white">
        <div class="row mt-4">
          <div class="col title">
            <h1>{{this.user.firstName}} {{this.user.lastName}}</h1>
            <h2 class="lead mt-0">{{this.user.email}}</h2>
          </div>
          <div class="col text-end pb-0 pt-2">
            <h2 class="lead mt-0">Current jobrole <i class="bi bi-suitcase-lg px-2"></i></h2>
            <div class="card-skills text-end">
              <div class="jobrole text-end">{{this.user.jobrole}}</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col title">
            <div *ngIf="(this.user.role === 'USER' && (this.user.departmentName))">
              <h2 class="lead"><i class="bi bi-tree"></i> Department member </h2>
              <div class="department">{{this.user.departmentName}}</div>
            </div>
            <div *ngIf="(this.user.role === 'MANAGER' && (this.user.departmentName))">
              <h2 class="lead"><i class="bi bi-tree"></i> Department manager </h2>
              <div class="department">{{this.user.departmentName}}</div>
            </div>
          </div>
          <div class="col title text-end">
            <h2 class="lead">Birth date <i class="bi bi-cake2 px-2"></i></h2>

            <div class="birthdate text-end">{{this.user.birthday}}</div>
          </div>
        </div>

        <div class="pt-4 mt-4 card-footer bg-white">
          <form
            *ngIf="this.authService.getUserRole() === 'ADMIN' && user.email !== this.authService.getLoggedInEmail(); else notAuthenticated"
            class="form">
            <div class="button-container">
              <button (click)="onDelete()" type="button" class="btn btn-outline-danger button-profile"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteDialog"><i class="bi bi-trash3 button-icon"></i> Delete Account
              </button>
              <app-confirm-delete-dialog *ngIf="showDeleteConfirmation" (confirm)="delete()"
                                         (cancel)="onCancelDelete()"
                                         [deleteWhat]="this.user.email"
                                         id="deleteDialog">
              </app-confirm-delete-dialog>
              <button type="button" class="btn btn-primary button-profile rounded-4"
                      [routerLink]="['/edit', user.email]">
                <i class="bi bi-pen button-icon p-1"></i> Edit Profile
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>

    <ng-template #notAuthenticated>
      <div *ngIf="user.email === this.authService.getLoggedInEmail()">
        <div class="form-group text-end">
          <button type="submit" class="btn btn-primary button-profile rounded-4"
                  [routerLink]="['/edit', user.email]">
            <i class="bi bi-pen button-icon p-1"></i> Edit Profile
          </button>
        </div>
      </div>
    </ng-template>
  </div>

  <!-- Assessment -->
  <div>
    <h3 class="pb-3">Assessment</h3>
    <div *ngIf="!startedQuestionnaire">
      You can see your Assessment here if you have finished the <a routerLink="/questionnaire">Questionnaire</a>.
    </div>
    <div class="row justify-content-center pb-4">
      <div class="accordion p-0 card-body border-light shadow-light" id="accordionPanelsStayOpenExample">
        <div class="accordion-item-profile  border-0">
          <h2 class="accordion-header">
            <button *ngIf="startedQuestionnaire" class="accordion-button-profile collapsed border-0" type="button"
                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseOne">
              <h5 class="px-1 py-0 m-0">Eigenschaften Zusammensetzung</h5>
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse pb-5"
               aria-labelledby="panelsStayOpen-headingOne">
            <div class="accordion-body">
              <div class="pieCharts">
                <div class="pieChart">
                  <div *ngIf="startedQuestionnaire">
                    <h5>Psychologische Eigenschaften</h5></div>
                  <canvas id="pieChartPsycho"></canvas>
                </div>
                <div class="pieChart">
                  <div *ngIf="startedQuestionnaire">
                    <h5>Prozess Eigenschaften</h5>
                  </div>
                  <canvas id="pieChartProcess"></canvas>
                </div>
                <div class="pieChart">
                  <div *ngIf="startedQuestionnaire">
                    <h5>Psychologischer Trim Trail</h5>
                  </div>
                  <canvas id="pieChartNeuro"></canvas>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="accordion-item-profile  border-light">
          <h2 class="accordion-header">
            <button *ngIf="startedQuestionnaire" class="accordion-button-profile collapsed border-0" type="button"
                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo">
              <h5 class="px-1 py-0 m-0">Eigenschaften Prozentual</h5>
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
               aria-labelledby="panelsStayOpen-headingTwo">
            <div class="accordion-body">

              <div class="overallBarCharts">

              <div class="overallBarChart">
                <div *ngIf="startedQuestionnaire">
                  <h5>Psychologische Eigenschaften</h5>
                </div>
                <div class="chart-container">
                  <canvas id="overallBarChartPsycho"></canvas>
                  <div class="trait-description">
                    <div *ngFor="let trait of psychoTraits" style="padding-top: 15px">
                      <strong>{{ trait.trait }}: </strong> <span style="color: #63656c">{{ trait.explanation }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="overallBarChart">
                <div *ngIf="startedQuestionnaire">
                  <h5>Prozess Eigenschaften</h5>
                </div>
                <div class="chart-container">
                  <canvas id="overallBarChartProcess"></canvas>
                  <div class="trait-description">
                    <div *ngFor="let trait of processTraits" style="padding-top: 15px">
                      <strong>{{ trait.trait }}: </strong> <span style="color: #63656c">{{ trait.explanation }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="overallBarChart">
                <div *ngIf="startedQuestionnaire">
                  <h5>Psychologischer Trim Trail</h5>
                </div>
                <div class="chart-container">
                  <canvas id="overallBarChartNeuro"></canvas>
                  <div class="trait-description">
                    <div *ngFor="let trait of neuroTraits" style="padding-top: 15px">
                      <strong>{{ trait.trait }}: </strong> <span style="color: #63656c">{{ trait.explanation }}</span>
                    </div>
                  </div>
                </div>
              </div>

              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item-profile border-0">
          <h2 class="accordion-header">
            <button *ngIf="startedQuestionnaire" class="accordion-button-profile collapsed border-0" type="button"
                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree">
              <h5 class="px-1 py-0 m-0">KI Tipps</h5>
            </button>
          </h2>
          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div *ngIf="startedQuestionnaire">
                <h6>Psychologische Eigenschaften:</h6>
                <button type="button" (click)="callGpt(selectGptCall[0])" class="btn btn-primary float-right">KI Analyse
                </button>
                <div *ngIf="streamedData1">
                  <strong>KI-Vorschlag:</strong>
                  <div *ngFor="let line of streamedData1">
                    <p>
                      {{ line }}
                    </p>
                  </div>
                </div>


                <h6>Psychologischer Trim Trail:</h6>
                <button type="button" (click)="callGpt(selectGptCall[1])" class="btn btn-primary float-right">KI Analyse
                </button>
                <div *ngIf="streamedData2">
                  <strong>KI-Vorschlag:</strong>
                  <div *ngFor="let line of streamedData2">
                    <p>
                      {{ line }}
                    </p>
                  </div>
                </div>


                <h6>Prozess Eigenschaften:</h6>
                <button type="button" (click)="callGpt(selectGptCall[2])" class="btn btn-primary float-right">KI Analyse
                </button>
                <div *ngIf="streamedData3">
                  <strong>KI-Vorschlag:</strong>
                  <div *ngFor="let line of streamedData3">
                    <p>
                      {{ line }}
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Assessment -->


    <!--<div ngbAccordion class="p-0 card-body border-0">
         <div ngbAccordionItem [collapsed]="true" class="shadow-light card-body border-0">
           <h2 ngbAccordionHeader>
             <button ngbAccordionButton class="bg-light-warm rounded-pill border-0"><h5
               class="px-1 py-0 m-0">Assessment</h5></button>
           </h2>
           <div ngbAccordionCollapse>
             <div ngbAccordionBody>

               <div *ngIf="startedQuestionnaire" class="pt-3" id="Eigenschaften-Zusammensetzung">
                 <h3 class="m-0 py-2">Eigenschaften Zusammensetzung</h3>
               </div>

               <div class="pieCharts">
                 <div class="pieChart">
                   <div *ngIf="startedQuestionnaire">
                     <h4>Psychologische Eigenschaften</h4></div>
                   <canvas id="pieChartPsycho"></canvas>
                 </div>
                 <div class="pieChart">
                   <div *ngIf="startedQuestionnaire">
                     <h4>Prozess Eigenschaften</h4>
                   </div>
                   <canvas id="pieChartProcess"></canvas>
                 </div>
                 <div class="pieChart">
                   <div *ngIf="startedQuestionnaire">
                     <h4>Psychologischer Trim Trai</h4>
                   </div>
                   <canvas id="pieChartNeuro"></canvas>
                 </div>
               </div>

               <div>
                 <div class="pt-5 mt-3 pb-1" id="Eigenschaften-Prozentual" *ngIf="startedQuestionnaire">
                   <h3 class="m-0 py-2">Eigenschaften Prozentual</h3>
                 </div>
                 <div class="overallBarCharts">

                   <div class="overallBarChart">
                     <div *ngIf="startedQuestionnaire">
                       <h4>Psychologische Eigenschaften</h4>
                     </div>
                     <canvas id="overallBarChartPsycho"></canvas>
                     <div *ngFor="let trait of psychoTraits">
                       <strong>{{ trait.trait }}:</strong> {{ trait.explanation }}
                     </div>
                   </div>

                   <div class="overallBarChart">
                     <div *ngIf="startedQuestionnaire">
                       <h4>Prozess Eigenschaften</h4>
                     </div>
                     <canvas id="overallBarChartProcess"></canvas>
                     <div *ngFor="let trait of processTraits">
                       <strong>{{ trait.trait }}:</strong> {{ trait.explanation }}
                     </div>
                   </div>
                   <div class="overallBarChart">
                     <div *ngIf="startedQuestionnaire">
                       <h4>Psychologischer Trim Trai</h4>
                     </div>
                     <canvas id="overallBarChartNeuro"></canvas>
                     <div *ngFor="let trait of neuroTraits">
                       <strong>{{ trait.trait }}:</strong> {{ trait.explanation }}
                     </div>
                   </div>
                   <div class="overallBarChart">
                     <div *ngIf="startedQuestionnaire">
                       <h4>KI Tipps:</h4>

                       <h6>Psychologische Eigenschaften:</h6>
                       <button type="button" (click)="callGpt(selectGptCall[0])" class="btn btn-success float-right">Call
                         GPT
                       </button>
                       <div *ngIf="streamedData1">
                         <strong>KI-Vorschlag:</strong>
                         <div *ngFor="let line of streamedData1">
                           <p>
                             {{ line }}
                           </p>
                         </div>
                       </div>


                       <h6>Psychologischer Trim Trai:</h6>
                       <button type="button" (click)="callGpt(selectGptCall[1])" class="btn btn-success float-right">Call
                         GPT
                       </button>
                       <div *ngIf="streamedData2">
                         <strong>KI-Vorschlag:</strong>
                         <div *ngFor="let line of streamedData2">
                           <p>
                             {{ line }}
                           </p>
                         </div>
                       </div>


                       <h6>Prozess Eigenschaften:</h6>
                       <button type="button" (click)="callGpt(selectGptCall[2])" class="btn btn-success float-right">Call
                         GPT
                       </button>
                       <div *ngIf="streamedData3">
                         <strong>KI-Vorschlag:</strong>
                         <div *ngFor="let line of streamedData3">
                           <p>
                             {{ line }}
                           </p>
                         </div>
                       </div>

                       &lt;!&ndash;<h6>Ollama Implementation</h6>
                       <button type="button" (click)="callOllama()" class="btn btn-success float-right">Call Ollama</button>
                       <button type="button" (click)="callGpt2()" class="btn btn-success float-right">Call GPT</button>
                       <div *ngIf="streamedData">
                         <strong>Antwort:</strong>
                         <div *ngFor="let line of streamedData">
                           <p>
                             {{ line }}
                           </p>
                         </div>
                       </div>&ndash;&gt;

                     </div>
                   </div>
                 </div>
               </div>
             </div>

           </div>
         </div>
       </div>-->
  </div>

  <ng-template #noQuestionair>
    <div *ngIf="user.email === this.authService.getLoggedInEmail(); else notMyAssessment">
      <div class="row pb-3">
        <div class="align-self-start">
          <strong for="firstName" class="col-form-label text-md-start text-muted col-md-2 col-lg-4">You
            have not started the questionnaire yet</strong>
        </div>
      </div>
      <div class="row">
        <div class="align-self-start">
          <button type="submit" class="btn btn-primary"
                  [routerLink]="['/questionnaire']">Start Questionnaire
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #notMyAssessment>
    <div class="row pb-3">
      <div class="align-self-start">
        <strong class="col-form-label text-md-start lead text-muted col-md-2 col-lg-4">This
          user has not filled out the questionnaire yet</strong>
      </div>
    </div>
  </ng-template>
