<div class="container">
  <div class="d-flex flex-row w-auto">
    <label style="font-size: 40px">{{this.project.name}}</label>
    <span style="flex: 1"></span>
    <button class="btn btn-secondary" (click)="openDialog(memberModal)" aria-label="Compare to other team..."
            style="margin-bottom: 10px">
      <span aria-hidden="true">Compare to other team...</span>
    </button>
  </div>

  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">First Name</th>
      <th scope="col">Last Name</th>
      <th scope="col">Jobrole</th>
      <th class="min-width">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tbody *ngIf="(project.members.length > 0); else noUsers">
    <tr *ngFor="let member of project.members">
      <td>{{member.firstName}}</td>
      <td>{{member.lastName}}</td>
      <td>{{member.jobrole}}</td>
      <td>
        <div class="btn-group">
          <a class="btn btn-sm responsive-info-button"
             [routerLink]="['../../../profile', member.email]"
             aria-label="Show user details">
            <i class="bi bi-info-lg"></i>
          </a>
          <button (click)="onDelete(member)"
                  data-bs-toggle="modal" data-bs-target="#deleteDialog"
                  class="btn btn-sm responsive-danger-button"
                  aria-label="Remove user from Project">
            <i class="bi bi-trash"></i>
          </button>
          <app-confirm-remove-dialog *ngIf="showDeleteConfirmation" (confirm)="removeUser(this.selectedUser)"
                                     (cancel)="onCancelDelete()" [removeWhat]="this.selectedUser.email"
                                     id="deleteDialog"></app-confirm-remove-dialog>
        </div>
      </td>
    </tr>
    </tbody>
    <ng-template #noUsers>
      <tr>
        <td colspan="3" style="text-align: center">No members added</td>
      </tr>
    </ng-template>
  </table>


  <span layout="row"><hr flex/><hr flex/></span>
  <label style="font-weight: bold; margin-top: 5%; display: block; text-align: center; font-size: 30px">add new
    members</label>

  <div class="accordion" id="teamAccordion">
    <div class="accordion-item ">
      <h2 class="accordion-header" id="teamAccordionHeader">
        <button class="accordion-button border border-secondary-subtle rounded p-3" type="button"
                data-bs-toggle="collapse" data-bs-target="#teamGenerator" aria-expanded="true"
                aria-controls="teamGenerator">
          Try to generate an optimal team
        </button>
      </h2>
      <div id="teamGenerator" class="accordion-collapse collapse border border-secondary-subtle rounded p-3"
           aria-labelledby="teamAccordionHeader" data-bs-parent="#teamAccordion">
        <div class="row mb-3">
          <div class="col-md-3">
            <label for="numberOfMembers">Number of Members:</label>
            <select id="numberOfMembers" name="numberOfMembers" [(ngModel)]="numberOfMembers" class="form-select"
                    required>
              <option value="0" disabled selected>Select number of members</option>
              <option *ngFor="let number of numberofmembers()">{{ number }}</option>
            </select>

          </div>

          <div *ngFor="let member of Array.from({ length: numberOfMembers }).keys()" class="col-md-3">
            <label for="jobRole{{ member + 1 }}">Job Role for Member {{ member + 1 }} (optional):</label>
            <select id="jobRole{{ member + 1 }}" name="jobRole{{ member + 1 }}" [(ngModel)]="selectedJobRoles[member]"
                    class="form-select">
              <option *ngFor="let role of jobRoles" [value]="role">{{ role }}</option>
            </select>
          </div>
          <div *ngIf="numberOfMembers > 0" class="row">
            <h6 style=" margin-top: 20px;">Optimal Scores:</h6>
            <div *ngFor="let criterion of criteria; let i = index" class="col-md-3">
              <label for="{{ criterion }}">{{ criterion }} (required, 0-30):</label>
              <input type="number" [id]="criterion" [name]="criterion" [(ngModel)]="scores[i]" class="form-control"
                     min="0" max="30" (ngModelChange)="scores[i] = limitScore(scores[i])">
            </div>
          </div>

          <div *ngIf="selectedTeamMembers.length > 0">
            <label style="font-size: 20px; margin-top: 10px;">Suggested Team Members:</label>
            <ul>
              <li *ngFor="let member of selectedTeamMembers">
                {{ member.firstName }} {{ member.lastName }}
              </li>
            </ul>
          </div>
          <div style="margin-top: 10px;" *ngIf="this.loading ">
            Loading...
          </div>

          <div *ngIf="selectedTeamMembersAi.length > 0">
            <label style="font-size: 20px; margin-top: 10px;">KI-Vorschlag:</label>
            <div *ngFor="let line of selectedTeamMembersAi">
              <p>
                {{ line }}
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <button role="button" class="btn btn-primary btn-lg mb-3" (click)="createOptimalTeam()"
                    [disabled]="!numberOfMembers || !areAllScoresFilled()">
              Create Optimal Team
            </button>
            <button *ngIf="selectedTeamMembers.length > 1" role="button" class="btn btn-primary btn-lg mb-3"
                    (click)="onAddMails()" [disabled]="selectedTeamMembers.length < 1">
              Fill Up with Optimal Team
            </button>
          </div>
          <div class="col-md-6 d-flex justify-content-end">
            <button role="button" class="btn btn-primary btn-lg mb-3" (click)="createOptimalGptTeam()"
                    [disabled]="!numberOfMembers || !areAllScoresFilled()">
              Create Optimal Team with AI
            </button>
            <button *ngIf="selectedTeamMembersAi.length >= 3" role="button"
                    class="btn btn-primary btn-lg mb-3 float-right" (click)="onAddAiMails()"
                    [disabled]="selectedTeamMembersAi.length < 3">
              Fill Up with AI
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-grow-1 pb-2">
    <input type="text" id="searchMember" name="searchMember"
           class="form-control shadow-light rounded-pill bg-white border" placeholder="Search ..."
           [(ngModel)]="searchInput" (ngModelChange)="updateSearchResult()">
  </div>

  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">First Name</th>
      <th scope="col">Last Name</th>
      <th scope="col">Jobrole</th>
      <th class="min-width">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tbody *ngIf="(suggestions.length > 0); else noSuggestions">
    <tr *ngFor="let suggestion of suggestions">
      <td>{{suggestion.firstName}}</td>
      <td>{{suggestion.lastName}}</td>
      <td>{{suggestion.jobrole}}</td>
      <td>
        <div class="btn-group">
          <a class="btn btn-sm responsive-info-button"
             [routerLink]="['../../../profile', suggestion.email]"
             aria-label="Show user details">
            <i class="bi bi-info-lg"></i>
          </a>
          <button
            (click)="addUser(suggestion)"
            class="btn btn-sm"
            aria-label="Add user">
            <i class="bi bi-plus"></i>
          </button>
        </div>
      </td>
    </tr>
    </tbody>

    <ng-template #noSuggestions>
      <tr>
        <td colspan="3" style="text-align: center">No fitting users found</td>
      </tr>
    </ng-template>
  </table>
  <div *ngIf="teamassessment===true">
    <div class="row" id="titleAssessment">
      <div class="title">
        <h1 style="margin-top: 5%;">Assessment</h1>
      </div>
    </div>

    <div *ngIf="project.members.length>0; else noMembers">
      <div class="row pt-3" id="Eigenschaften-Zusammensetzung">
        <h3 class="m-0 p-2">Eigenschaften Zusammensetzung</h3>
      </div>
      <div class="pieCharts">
        <div class="pieChart">
          <div>
            <h4>Psychologische Eigenschaften</h4></div>
          <canvas id="pieChartPsycho"></canvas>
        </div>
        <div class="pieChart">
          <div>
            <h4>Prozess Eigenschaften</h4>
          </div>
          <canvas id="pieChartProcess"></canvas>
        </div>
        <div class="pieChart">
          <div>
            <h4>Psychologischer Trim Trail</h4>
          </div>
          <canvas id="pieChartNeuro"></canvas>
        </div>
      </div>

      <div>
        <div class="row pt-5" id="Eigenschaften-Prozentual">
          <h3 class="m-0 p-2">Eigenschaften Prozentual</h3>
        </div>

        <div class="overallBarCharts">
          <div class="overallBarChart">
            <h4>Psychologische Eigenschaften</h4>
            <div style="height: 40%">
              <canvas id="overallBarChartPsycho"></canvas>
            </div>
            <div *ngFor="let trait of psychoTraits">
              <strong>{{ trait.trait }}:</strong> {{ trait.explanation }}
            </div>
          </div>

          <div class="overallBarChart">
            <h4>Prozess Eigenschaften</h4>
            <div style="height: 40%">
              <canvas id="overallBarChartProcess"></canvas>
            </div>
            <div *ngFor="let trait of processTraits">
              <strong>{{ trait.trait }}:</strong> {{ trait.explanation }}
            </div>
          </div>
          <div class="overallBarChart">
            <h4>Psychologischer Trim Trai</h4>
            <div style="height: 40%">
              <canvas id="overallBarChartNeuro"></canvas>
            </div>
            <div *ngFor="let trait of neuroTraits">
              <strong>{{ trait.trait }}:</strong> {{ trait.explanation }}
            </div>
          </div>

        </div>
        <div>
          <canvas id="neuroMap"></canvas>
        </div>
      </div>
      <div class="neuroMapDescription">
        Die 1. Axe stellt die <strong>Willensbahnung</strong> dar. Mit steigendem Wert nimmt die Handlungsbereitschaft
        ab und die
        prospektive Lageorientierung zu.
        Stark negative Werte weisen auf einen ausgeprägten Mangel an Selbstbremsung, stark positive Werte weisen auf
        einen ausgeprägten Mangel an Selbstmotivation.
      </div>
      <div class="neuroMapDescription">
        Die 2. Axe stellt das <strong>Selbstwachstum</strong> dar. Mit steigendem Wert nimmt die Vermeidungsuche von
        Konflikten ab und
        die Rigidität zu. Stark negative Werte weisen auf einen ausgeprägten Mangel an Selbstkonfrontationsfähigkeit,
        stark positive Werte
        weisen auf einen ausgeprägten Mangel an Selbstberuhigung.
      </div>
    </div>
  </div>


  <ng-template #memberModal let-modal>
    <div class="card" id="memberModal">
      <div class="card-header fade show d-flex align-items-center">
        <h5 class="flex-grow-1 modal-title">Compare to</h5>
        <button class="btn btn-link p-0" type="button" (click)="modal.dismiss()">
          <span class="bi-x-lg text-dark icon-big p-0"></span>
        </button>
      </div>
      <div class="card-body">
        <div class="table table-hover">
          <table class="table table-borderless table-hover" role="grid">
            <thead>
            <tr>
              <th scope="col">Name</th>
              <th class="text-center" tabindex="0">Comparison</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let listProject of this.projectsToCompare()">
              <td>{{listProject.name}}</td>
              <td class="text-center">
                <a class="btn btn-sm responsive-info-button pb-0"
                   [routerLink]="['../../compare', this.project.id, listProject.id]"
                   (click)="modal.dismiss()"
                   aria-label="Show user details">
                  <i class="bi bi-arrow-right-circle"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-template>
</div>


<ng-template #noMembers>
  <div>
    <label>Add members to view assessment</label>
  </div>
</ng-template>


