import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {UserService} from "../../services/user.service";
import {AnswerService} from "../../services/answer.service";
import {User} from "../../dtos/user";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {SafeHtml} from "@angular/platform-browser";
import {Answer} from "../../dtos/answer";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  user: User = {
    id: null,
    firstName: "",
    lastName: "",
    birthday: null,
    jobrole: "",
    email: "",
    password: "",
    role: "",
    department: null,
  }

  answered: boolean = true;

  errorMessage: SafeHtml = '';
  error = false;
  showDeleteConfirmation: boolean = true;

  constructor(public authService: AuthService,
              public userService: UserService,
              public answerService: AnswerService,
              private router: Router,
              private route: ActivatedRoute,
              private notification: ToastrService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.getUser(data);
    });
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/401']).then(r => console.log('Error status 401'));
    }
  }


  getUser(data: any) {
    let email = this.getEmail();
    if (!email)
      email = this.authService.getLoggedInEmail();
    this.answerService.getAnswers(email).subscribe({
      next: (answers: Answer[]) => {
        if (answers == null || answers.length < 68) {
          this.answered = false;
        }
        answers.forEach(answer => {
          if (answer.answer == null || answer.answer <= 0) {
            this.answered = false;
          }
        });
      },
      error: err => {
        this.answered = false;
      }
    });
    this.userService.getUserByEmail(email).subscribe({
      next: data => {
        this.user = data;
      },
      error: err => {
        this.error = true;
        if (err instanceof HttpErrorResponse) {
          if (err.status === 500) {
            this.errorMessage = err.error;
            this.router.navigate(['/500']).then(r => console.log('Error status 500'));
          } else if (err.status === 404) {
            this.errorMessage = `<strong>Problems fetching User!</strong> ${err.error}`;
            this.router.navigate(['/404']).then(r => console.log('Error status 404'));
          } else if (err.status === 401) {
            this.errorMessage = `<strong>Problems fetching User!</strong> ${err.error}`;
            this.router.navigate(['/401']).then(r => console.log('Error status 401'));
          } else {
            this.router.navigate(['/login']).then(r => console.log('Login'));
          }
        }
      }
    });
  }

  getEmail(): string {
    return this.route.snapshot.paramMap.get('email');
  }
}
