<div class="impressum-container">
  <div class="mt-4 container ">
    <div class="mt-4 container">
      <p class="copyright">
        Copyright by
        <strong>School of Life - Stephanie Coleman und Christian von Saint Paul ©</strong>
      </p>
      <p class="haftungshinweis">
        <strong>Haftungshinweis:</strong> Trotz sorgfältiger inhaltlicher Kontrolle übernimmt die Betreiberin dieser
        Website keine Haftung für die Inhalte externer Links.
        Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
      </p>
      <p class="rights">
        © 2024 School of Life - Alle Rechte vorbehalten
      </p>
    </div>
  </div>
</div>
