<div *ngIf="authService.isLoggedIn() && authService.isManager()" class="mt-4 container">

  <div class="row pb-4">
    <div class="title">
      <h1>My Project Teams</h1>
    </div>
  </div>
  <div class="accordion p-0 card-body border-light shadow-light rounded-5">
    <div class="accordion-item-profile border-0 rounded-5">
      <h2 class="accordion-header rounded-5">
        <button class="accordion-button-profile collapsed rounded-5 border-0" type="button"
                data-bs-toggle="collapse" data-bs-target="#createProject" aria-expanded="false"
                aria-controls="createProject">
          <h5 class="px-1 py-0 m-0 "><i class="bi bi-plus"></i> new Project</h5>
        </button>
      </h2>
      <div id="createProject" class="accordion-collapse collapse py-3" aria-labelledby="createProject">
        <div class="accordion-body">
          <form #form="ngForm" (ngSubmit)="createProject(form)">
            <div class="row mx-1">
              <div class="col-md-10">
                <input id="departmentName" name="departmentName" type="text"
                       placeholder="Project Name"
                       class="form-control col-form-label input-group rounded mb-3 px-3"
                       [(ngModel)]="name" required/>
              </div>
              <div class="col-md-2">
                <button type="submit" class="button form-control btn btn-primary"
                        [disabled]="!form.valid">Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="projects.length > 0; else noProjects" class="row">
    <div class="col-lg-12">
      <div class="QA_section">
        <div class="p-3">
        </div>
        <div class="card shadow-light flex-grow-1 pt-4 p-4 rounded-5 bg-white border-white">
          <div class="p-1"></div>
          <div style="overflow-x:auto;">
            <table class="table table-hover table-responsive-lg">
              <thead class="card-top form-floating bg-thead border-white">
              <tr role="row" class="form-floating bg-thead border-white mb-3">
                <th class="form-floating bg-thead left-round-border border-white text-start px-4" scope="col"
                    rowspan="1"
                    colspan="1" aria-label="Project name">Name
                </th>
                <th class="form-floating bg-thead text-end" scope="col" rowspan="1" colspan="1"
                    aria-label="Info">Details
                </th>
                <th class="form-floating bg-thead right-round-border border-white text-center"
                    scope="col" rowspan="1" colspan="1"
                    aria-label="Delete Project">Delete
                </th>
              </tr>
              </thead>
              <tbody class="p-2">
              <div class="p-2"></div>
              <tbody *ngIf="(projects)">

              <tr *ngFor="let project of pageOfItems; let i = index">
                <td class="col-8 px-3">{{project.name}}</td>
                <td class="text-end">
                  <a class="btn btn-sm rounded responsive-info-button pb-0"
                     *ngIf="authService.isLoggedIn()"
                     [routerLink]="['edit', project.id]"
                     aria-label="Details">
                    <i class="bi bi-info-circle icon-big"></i>
                  </a>
                </td>
                <td class="col-2 text-center">
                  <button (click)="onDelete(project)"
                          data-bs-toggle="modal"
                          data-bs-target="#deletionDialog"
                          class="btn btn-sm responsive-danger-button pb-0"
                          aria-label="Delete project">
                    <i class="bi bi-trash icon-big"></i>
                  </button>
                  <app-confirm-delete-dialog *ngIf="showDeleteConfirmation" (confirm)="deleteProject()"
                                             (cancel)="onCancelDelete()" [deleteWhat]="this.selectedProject.name"
                                             id="deletionDialog"></app-confirm-delete-dialog>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="container mt-5" id="prev-next-buttons">
            <jw-pagination [items]="projects" (counter)="setCounter($event)"
                           [needsSubmit]=false
                           (changePage)="onChangePage($event)"
                           [pageSize]="pageSize"></jw-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noProjects>
    <div class="lead">You have no projects. Please create one</div>
  </ng-template>
</div>



