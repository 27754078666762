<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-7">
      <div class="card shadow-lg border-0 rounded-5 mt-5">
        <div class="card-header rounded-top-5">
          <h3 class="text-center font-weight-light my-4">Create Account</h3></div>
        <div class="card-body">
          <form class="form" [formGroup]="registerForm" (ngSubmit)="registerUser()">
            <div class="row form-label">
              <div class="mb-1 mt-3"><h3 class="lead">Basic Details</h3></div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <div class="form-floating mb-3 mb-md-0">
                  <input class="form-control" id="inputFirstName" type="text" formControlName="firstName"
                         name="firstName"
                         placeholder="Enter your first name">
                  <label for="inputFirstName">First name</label>
                  <div *ngIf="submitted && registerForm.controls.firstName.errors" class="error">
                    <small class="form-text text-warning"
                           *ngIf="registerForm.controls.firstName.errors.required">Your firstname is
                      required!
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input class="form-control" id="inputLastName" type="text" name="lastName" formControlName="lastName"
                         aria-describedby="lastNameHelp"
                         placeholder="Enter your last name">
                  <label for="inputLastName">Last name</label>
                  <div *ngIf="submitted && registerForm.controls.lastName.errors" class="error">
                    <small class="form-text text-warning"
                           *ngIf="registerForm.controls.lastName.errors.required">Your lastname is
                      required!
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-6">
                <div class="form-floating mb-3 mb-md-0">
                  <input class="form-control" id="inputBirthday" type="date" placeholder="   " name="birthday"
                         formControlName="birthday" aria-describedby="birthdayHelp" [min]="minDate" [max]="maxDate">
                  <label for="inputBirthday">Birth date</label>
                  <div *ngIf="submitted && registerForm.controls.birthday.errors" class="error">
                    <small class="form-text text-warning"
                           *ngIf="registerForm.controls.birthday.errors.required">Your birthday is
                      required!
                    </small>
                    <small class="form-text text-warning"
                           *ngIf="registerForm.controls.birthday.errors.birthday">Email address is not valid
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <select class="form-select" id="inputJobRole" name="jobRole" formControlName="jobRole">
                    <option *ngFor="let role of jobRoles" [value]="role">{{ role }}</option>
                  </select>
                  <label for="inputJobRole">Job Role</label>
                  <div *ngIf="submitted && registerForm.controls.jobRole.errors" class="error">
                    <small class="form-text text-warning" *ngIf="registerForm.controls.jobRole.errors.required">
                      Your job role is required!
                    </small>
                    <small class="form-text text-warning"
                           *ngIf="registerForm.controls.jobRole.errors.jobRole">Job role is not valid
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-floating mb-3">
              <input class="form-control" id="inputEmail" type="text" placeholder="name@example.com" name="email"
                     formControlName="email" aria-describedby="emailHelp">
              <label for="inputEmail">Email address</label>
              <div *ngIf="submitted && registerForm.controls.email.errors" class="error">
                <small class="form-text text-warning"
                       *ngIf="registerForm.controls.email.errors.required">Your email address is
                  required!
                </small>
                <small class="form-text text-warning"
                       *ngIf="registerForm.controls.email.errors.email">Email address is not valid
                </small>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <div class="form-floating mb-3 mb-md-0">
                  <input class="form-control" id="inputPassword" type="password"
                         placeholder="Create a password" name="password" formControlName="password">
                  <label for="inputPassword">Password</label>
                  <div *ngIf="submitted && registerForm.controls.password.errors" class="error">
                    <small class="form-text text-warning"
                           *ngIf="registerForm.controls.password.errors.required">Your password
                      is
                      required!
                    </small>
                    <small class="form-text text-warning"
                           *ngIf="registerForm.controls.password.errors.minlength">Your password
                      must be at least 8 characters long!
                    </small>
                  </div>
                  <small class="form-text text-warning"
                         *ngIf="submitted && registerForm.hasError('passwordMismatch') && registerForm.getError('passwordMismatch')">Passwords
                    do not match
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3 mb-md-0">
                  <input class="form-control" id="inputPasswordConfirm" type="password" name="password2"
                         formControlName="password2"
                         placeholder="Confirm password">
                  <label for="inputPasswordConfirm">Confirm Password</label>
                  <div *ngIf="submitted && registerForm.controls.password2.errors" class="error">
                    <small class="form-text text-warning"
                           *ngIf="registerForm.controls.password2.errors.required">Your password
                      is
                      required!
                    </small>
                    <small class="form-text text-warning"
                           *ngIf="registerForm.controls.password2.errors.minlength">Your password
                      must be at least 8 characters long!
                    </small>
                  </div>
                  <small class="form-text text-warning"
                         *ngIf="submitted && registerForm.hasError('passwordMismatch') && registerForm.getError('passwordMismatch')">Passwords
                    do not match
                  </small>
                </div>
              </div>
            </div>


            <!-- radio buttons -->
            <div class="row form-label">
              <div class="card-subtitle mb-1 mt-3"><h3 class="lead">Select your role</h3></div>
            </div>
            <!-- ------------------------ -->

            <div class="row mb-3" id="inputRole">
              <div class="col-md-3">
                <button class="btn option-button form-control rounded border-light-subtle" type="button"
                        [class.btn-primary]="registerForm.controls.role.value === 'manager'"
                        (click)="selectRole('manager')">Manager
                </button>
              </div>
              <div class="col-md-3">
                <button class="btn option-button form-control rounded border-light-subtle" type="button"
                        [class.btn-primary]="registerForm.controls.role.value === 'user'"
                        (click)="selectRole('user')">User
                </button>
              </div>
              <div *ngIf="submitted && registerForm.controls.role.errors" class="error">
                <small class="form-text text-warning" *ngIf="registerForm.controls.role.errors.required">
                  Please select a role!
                </small>
              </div>
            </div>

            <!-- ------------------------ -->

            <div class="mt-4 mb-0">
              <div class="d-grid">
                <button type="button" (click)="registerUser()" class="btn btn-primary btn-block">Create
                  Account
                </button>
              </div>
            </div>

          </form>
        </div>
        <div class="card-footer rounded-bottom-5 text-center py-4">
          <div class="small"><a routerLink="/login">Have an account? Go to login</a></div>
        </div>
      </div>
    </div>
  </div>
</div>
