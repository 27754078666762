<div class="container">
  <div class="row">
    <div class="title">
      <h1>Personal Information</h1>
    </div>
  </div>
  <div class="row justify-content-center pb-5 pt-2" id="personalInformation">
    <div class="card rounded pt-4 pb-4">
      <form #form="ngForm" [class.was-validated]="form.submitted">


        <div class="row">
          <div class="col-md-6 align-self-center">
            <label for="firstName" class="col-form-label text-md-start text-muted col-md-2 col-lg-4">First
              name:</label>
            <input class="col-form-label border rounded text-md-start col-md-2 col-lg-8 p-2"
                   id="firstName" name="firstName"
                   [(ngModel)]="user.firstName" required/>


          </div>
          <div class="right-column col-md-6">
            <label for="lastName" class="col-form-label text-md-start text-muted col-md-2 col-lg-4 ">Last
              name:</label>
            <input class="col-form-label border rounded text-md-start col-md-2 col-lg-8 p-2" id="lastName"
                   name="lastName" [(ngModel)]="user.lastName"
                   required>
          </div>
        </div>
        <div class="row">
          <div *ngIf="this.user.role !== 'ADMIN'" class="col-md-6 align-self-center">
            <label for="jobrole" class="col-form-label text-md-start text-muted col-md-2 col-lg-4">Jobrole:</label>
            <select class="col-form-label border rounded text-md-start col-md-2 col-lg-8 p-2" id="jobrole"
                    name="jobrole" [(ngModel)]="user.jobrole" required>
              <option *ngFor="let role of jobRoles" [value]="role">{{ role }}</option>
            </select>
          </div>

          <div class="right-column col-md-6">
            <label for="birthday" class="col-form-label text-md-start text-muted col-md-2 col-lg-4">Birthday:</label>
            <input class="col-form-label border rounded text-md-start col-md-2 col-lg-8 p-2"
                   id="birthday"
                   name="birthday"
                   [(ngModel)]="user.birthday"
                   required
                   type="date"
                   [min]="minDate"
                   [max]="maxDate">
          </div>
        </div>


        <div class="row">
          <div class="col-md-6 align-self-center">
            <label for="email" class="col-form-label text-md-start text-muted col-md-2 col-lg-4">Email
              Address:</label>
            <label class="col-form-label text-md-start col-md-2 col-lg-8 p-2" id="email">{{this.user.email}}</label>
          </div>
          <div *ngIf="this.user.role !== 'ADMIN'" class="col-md-6 d-flex">
            <label for="email" class="col-form-label text-md-start text-muted col-md-2 col-lg-4">Role:</label>
            <label *ngIf="!this.authService.isAdmin()" class="col-form-label text-md-start col-md-2 col-lg-8 p-2"
                   id="role">{{this.user.role}}</label>

            <select *ngIf="this.authService.isAdmin()" id="roleSelector" name="role"
                    class="col-form-label border rounded text-md-start col-md-2 col-lg-8 p-2"
                    [(ngModel)]="user.role" required>
              <option value="USER" [selected]="'USER' === user.role">USER</option>
              <option value="MANAGER" [selected]="user.role === 'MANAGER'">MANAGER</option>
            </select>
          </div>

        </div>
        <div
          *ngIf="this.authService.getUserRole() === 'ADMIN' && user.email !== this.authService.getLoggedInEmail(); else notAuthenticated"
          class="row">
          <div class="col-form-label text-md-start col-md-6 pt-2 pb-0">
            <button (click)="onDelete()" class="btn btn-danger" data-bs-toggle="modal"
                    data-bs-target="#deleteDialog">
              Delete
            </button>
            <app-confirm-delete-dialog *ngIf="showDeleteConfirmation" (confirm)="delete()"
                                       (cancel)="onCancelDelete()"
                                       [deleteWhat]="this.user.email"
                                       id="deleteDialog"></app-confirm-delete-dialog>

          </div>
          <div class="col-form-label text-md-end right-column col-md-6 pt-2 pb-0">
            <button type="submit" class="btn btn-primary" [disabled]="!form.valid" (click)="save(form)"> Save
            </button>
          </div>
        </div>

        <ng-template #notAuthenticated>
          <div *ngIf="user.email === this.authService.getLoggedInEmail()" class="p-4">
            <div class="col-form-label text-md-end pt-2 pb-0">
              <button type="submit" class="btn btn-primary" [disabled]="!form.valid" (click)="save(form)">Save
              </button>
            </div>
          </div>
        </ng-template>

      </form>
    </div>
  </div>
</div>

