<div class="mt-4 mb-4 container">

  <div class="row pb-3">
    <div class="title">
      <h1>Questionnaire</h1>
    </div>
    <p class="lead">Please take your time and answer all of the following questions.</p>
  </div>

  <div class="card shadow-light border-0 rounded-5">
    <div class="row">
      <div class="col-lg-12">

        <!-- progress-bar -->
        <div class="card-header bg-white rounded-top-5">
          <h3 class="text-center progress-text-color mt-3 mb-3">{{counter}} / {{ceil(questionnaire.length / 7)}}</h3>
          <!--<div class="progress w-25 ">
            <div class="progress-bar" role="progressbar"
                 [style.width]="completionPercentage() + '%'">
              {{ completionPercentage() }}%
            </div>
          </div>-->
        </div>

        <!-- card-body -->
        <div class="card-body pt-4 mb-3">
          <form #form="ngForm" (ngSubmit)="onSubmit(form)">
            <div class="mt-5"></div>
            <div *ngFor="let question of pageOfItems; let i = index">
              <div class="mx-0 mx-sm-auto">
                <div class="text-center lead">
                  {{question.question}}
                </div>
                <div class="row p-4 pb-5">
                  <div class="col-md-3 figure-caption text-end align-self-center">
                    Trifft nicht zu
                  </div>
                  <div class="line-container">
                    <label>
                      <input class="form-check-input radio-button mb-1 border-color" type="radio"
                             name="{{i+((counter - 1)*pageSize)}}" id="inlineRadio1"
                             value="1"
                             [(ngModel)]="selectedOption[i+((counter - 1)*pageSize)]"/>
                    </label>
                    <div class="line"></div>
                    <label>
                      <input class="form-check-input radio-button mb-1 border-color" type="radio"
                             name="{{i+((counter - 1)*pageSize)}}" id="inlineRadio2"
                             value="2"
                             [(ngModel)]="selectedOption[i+((counter - 1)*pageSize)]"/>
                    </label>
                    <div class="line"></div>
                    <label>
                      <input class="form-check-input radio-button mb-1 border-color" type="radio"
                             name="{{i+((counter - 1)*pageSize)}}"
                             id="inlineRadio3"
                             value="3"
                             [(ngModel)]="selectedOption[i+((counter - 1)*pageSize)]"/>
                    </label>
                    <div class="line"></div>
                    <label>
                      <input class="form-check-input radio-button mb-1 border-color" type="radio"
                             name="{{i+((counter - 1)*pageSize)}}"
                             id="inlineRadio4"
                             value="4" [(ngModel)]="selectedOption[i+((counter - 1)*pageSize)]"/>
                    </label>
                  </div>
                  <div class="col-md-3  figure-caption align-self-center">
                    Trifft zu
                  </div>
                </div>

              </div>
            </div>
            <div class="container mt-5" id="prev-next-buttons">
              <jw-pagination [items]="questionnaire" (counter)="setCounter($event)"
                             [needsSubmit]=true
                             (changePage)="onChangePage($event)"
                             [pageSize]="pageSize"></jw-pagination>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>


