import {Component, OnInit} from '@angular/core';
import {SimpleUser, User} from "../../dtos/user";
import {UserService} from "../../services/user.service";
import {AuthService} from "../../services/auth.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {debounceTime, Subject} from "rxjs";
import {Router} from "@angular/router";
import {UserSearch} from "../../dtos/userSearch";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-all-members',
  templateUrl: './all-members.component.html',
  styleUrls: ['./all-members.component.scss'],

  providers: [UserService],
})
export class AllMembersComponent implements OnInit {

  users: SimpleUser[];
  userForDeletion: SimpleUser | undefined;

  updateForm: UntypedFormGroup;
  submitted = false;

  errorMessage = '';
  successMessage = '';

  showDeleteConfirmation: boolean = true;
  searchChangedObservable = new Subject<void>();
  searchInput: string = '';

  counter: number;
  pageSize: number = 10;
  pageOfItems: Array<SimpleUser>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public authService: AuthService,
    private router: Router,
    public userService: UserService,
    private notification: ToastrService
  ) {
    this.updateForm = this.formBuilder.group({
      username: ['', [Validators.required]]
    });
    this.userService.getAllUsers().subscribe((data) => {
      this.users = data;
    });

  }

  user: User = {
    id: null,
    firstName: "",
    lastName: "",
    birthday: null,
    jobrole: "",
    email: "",
    password: "",
    role: "USER",
    department: null,
  }

  ngOnInit(): void {
    if (!(this.authService.isLoggedIn()) || !(this.authService.isAdmin())) {
      this.router.navigate(['/401']).then(r => console.log('Error status 401'));
    }
    this.reloadUsers();
    this.searchChangedObservable
      .pipe(debounceTime(300))
      .subscribe({
          next: () => this.reloadUsers(),
          error: error => {
            this.router.navigate(['/login']);
          }
        }
      );
  }


  private reloadUsers() {
    this.userService.getAllUsers().subscribe({
      next: (user: User[]) => {
        this.users = user;
      },
      error: err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 500) {
            this.router.navigate(['/500']).then(r => console.log('Error status 500'));
          } else if (err.status === 404) {
            this.router.navigate(['/404']).then(r => console.log('Error status 404'));
          } else if (!this.authService.isLoggedIn() || err.status === 404) {
            this.router.navigate(['/401']).then(r => console.log('Error status 401'));
          }
        } else {
          this.defaultServiceErrorHandling(err);
        }
      }
    });
  }

  /**
   * Following two methods necessary for confirmation dialog
   */
  onCancelDelete() {
    this.showDeleteConfirmation = false;
  }

  onDelete() {
    this.showDeleteConfirmation = true;
  }

  delete(deleteRequest: string) {
    this.userService.deleteUser(deleteRequest).subscribe({
      next: () => {
        this.notification.success("Successfully deleted user " + deleteRequest + " from the system.");
        this.successMessage = "Successfully deleted user " + deleteRequest + " from the system.";
        this.reloadUsers();
      },
      error: error => {
        this.errorMessage = JSON.parse(error.error).detail;
        this.reloadUsers();
        if (deleteRequest == this.authService.getLoggedInEmail() && this.authService.isAdmin()) {
        } else {

          if (error instanceof HttpErrorResponse && error.status === 404) {
            this.errorMessage = error.error;
          }
          if (error instanceof HttpErrorResponse && error.status === 400) {
            const startIndex = error.error.indexOf("[");
            const endIndex = error.error.lastIndexOf("]");
            if (startIndex !== -1 && endIndex !== -1) {
              const validationErrorsString = error.error.substring(startIndex + 1, endIndex);
              this.errorMessage = validationErrorsString;
            }

          }
        }
        this.notification.error(this.errorMessage);
      }
    });
  }

  updateSearchResult() {
    var v = this.userService.getUsersByName(this.readInput());
    v.subscribe({
      next: data => {
        this.users = data;
      }
    });
  }

  readInput(): UserSearch {
    let parts = this.searchInput.split(" ");
    return {
      firstInput: parts[0], lastInput: parts[1], maxResults: null
    }
  }

  private defaultServiceErrorHandling(error: any) {
    if (typeof error.error === 'object') {
      this.errorMessage = error.error.error;
    } else {
      this.errorMessage = error.error;
    }
    this.notification.error(this.errorMessage);
  }

  /*
  * Pagination
   */

  onChangePage(pageOfItems: Array<SimpleUser>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  setCounter(counter: number) {
    this.counter = counter + 1;
  }
}
