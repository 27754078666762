<div class="container">

  <div *ngIf="!authService.isLoggedIn(); else alreadyLoggedIn" class="row justify-content-center">
    <div class="col-lg-5">
      <div class="card shadow-light border-0  rounded-5 mt-5">
        <div class="card-header rounded-top-5">
          <h3 class="text-center font-weight-light my-4">Login</h3></div>
        <div class="card-body">
          <form class="form" [formGroup]="loginForm" (ngSubmit)="loginUser()">
            <div class="form-group form-floating mb-3">
              <input class="form-control" id="inputUsername" formControlName="username" type="email"
                     placeholder="name@example.com" aria-describedby="usernameHelp">
              <label for="inputUsername">Email address</label>
              <div *ngIf="submitted && loginForm.controls.username.errors" class="error">
                <small class="form-text text-warning"
                       *ngIf="loginForm.controls.username.errors.required">Your username
                  is
                  required!
                </small>
              </div>
            </div>
            <div class="form-floating mb-3">
              <input class="form-control" id="inputPassword" formControlName="password" type="password"
                     placeholder="Password">
              <label for="inputPassword">Password</label>
              <div *ngIf="submitted && loginForm.controls.password.errors" class="error">
                <small class="form-text text-warning"
                       *ngIf="loginForm.controls.password.errors.required">Your password
                  is
                  required!
                </small>
                <small class="form-text text-warning"
                       *ngIf="loginForm.controls.password.errors.minlength">Your password
                  must be at least 8 characters long!
                </small>
              </div>
            </div>
            <!--<div class="form-check mb-3">
              <input class="form-check-input" id="inputRememberPassword" type="checkbox" value="">
              <label class="form-check-label" for="inputRememberPassword">Remember Password</label>
            </div>-->
            <div class="d-flex align-items-center justify-content-between mt-4 mb-0">
              <a class="small" routerLink="/resetPassword">Forgot Password</a>
              <button class="btn btn-primary" type="button" [disabled]="!loginForm.valid"
                      (click)="loginUser()">Login
              </button>
            </div>
          </form>
        </div>
        <div class="card-footer text-center py-3 rounded-bottom-5">
          <div class="small"><a routerLink="/register">Need an account? Sign up!</a></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #alreadyLoggedIn>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="card shadow-light border-0 rounded-5 mt-5 bg-white">
          <div class="card-header bg-white rounded-top-5">
            <h3 class="text-center font-weight-light my-4">You are already logged in!</h3>
          </div>
          <div class="card-body px-4 my-3">
            <p>You must log out before logging in again with different details.</p>
          </div>
          <div class="card-footer rounded-bottom-5 text-center py-3">
            <div class="button-container my-2">
              <button class="btn btn-outline-secondary rounded-3" type="button" routerLink="/">Cancel
              </button>
              <button class="btn btn-primary rounded-3" type="button" routerLink="/login"
                      (click)="authService.logoutUser()">Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
