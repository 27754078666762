import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ResetPasswordService} from "../../services/resetPassword.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetToken: string;
  tokenIsPresent: boolean = false;
  submitted = false;
  //requestSent = false;
  resetPasswordForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private resetPasswordService: ResetPasswordService, private authService: AuthService,
              private router: Router, private route: ActivatedRoute, private notification: ToastrService) {
    this.resetPasswordForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  resetPassword() {
    this.notification.success("If your email is valid we sent you an email");
    if (!this.tokenIsPresent) {
      // Code für den Fall, wenn das Token nicht vorhanden ist
      if (this.resetPasswordForm.controls.username.valid) {
        const email = this.resetPasswordForm.controls.username.value;
        this.requestEmail(email);
      }
    }
  }

  requestEmail(email: string) {
    this.resetPasswordService.resetPassword(email).subscribe({
      next: () => {
      },
      error: () => {
      }
    });
  }

  setPassword() {
    if (this.passwordsMatch()) {
      this.resetPasswordService.updatePassword(this.resetPasswordForm.controls.newPassword.value, this.resetToken).subscribe({
        next: () => {
          this.router.navigate(['/login'])
        },
        error: (err) => {
          if (err.status === 404) {
            this.notification.error("The password reset token has expired. Please request a new one.", "Request error");
          }
        }
      });

    } else {
      this.notification.error("Passwords do not match", "Request error");
    }
  }

  passwordsMatch(): boolean {
    return this.resetPasswordForm.controls.newPassword.value === this.resetPasswordForm.controls.confirmPassword.value;
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/login']).then(r => console.log('Already logged in'));
    }
    this.route.paramMap.subscribe(params => {
      this.resetToken = params.get('resetToken');
    });
    this.tokenIsPresent = !!this.resetToken;
  }
}
