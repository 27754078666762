import {Component, OnInit} from '@angular/core';
import {Project} from "../../../dtos/project";
import {AuthService} from "../../../services/auth.service";
import {UserService} from "../../../services/user.service";
import {ProjectService} from "../../../services/project.service";
import {QuestionService} from "../../../services/question.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TeamAssessment} from "../../../dtos/teamAssessment";
import {Chart} from "chart.js/auto";
import {NeuroMap} from "../../../dtos/neuromap";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit {

  constructor(public authService: AuthService, public userService: UserService, public projectService: ProjectService, public questionService: QuestionService, private router: Router, private route: ActivatedRoute) {
  }

  id1: number;
  id2: number;
  project1: Project = {id: 0, name: "", members: [], department: null};
  project2: Project = {id: 0, name: "", members: [], department: null};
  error = false;
  errorMessage;

  #assessment
  processTraits1 = [];
  psychoTraits1 = [];
  neuroTraits1 = [];
  processTraits2 = [];
  psychoTraits2 = [];
  neuroTraits2 = [];
  colors = ['darkgrey', 'lightblue', 'lightpink', '#B4DEC2', '#D7AFFF', '#ECE7A2', '#FFBA9F'];
  hoverBackgroundColor = ['grey', '#86C6DA', '#FF9BA9', '#94D0A8', '#C58BFF', "#E2DA70", 'lightsalmon'];
  assessment1: TeamAssessment;
  assessment2: TeamAssessment;
  pieChartPsycho1;
  pieChartNeuro1;
  pieChartProcess1;
  pieChartPsycho2;
  pieChartNeuro2;
  pieChartProcess2;
  overallBarChartPsycho;
  overallBarChartNeuro;
  overallBarChartProcess;

  neuroMap1: NeuroMap[];
  neuroMap2: NeuroMap[];
  neuroMapScatterChart;

  ngOnInit(): void {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
    if (!(this.authService.isManager())) {
      this.router.navigate(['/401']).then(r => console.log('Error status 401')); // redirect to 401 component if not a manager
    }
    this.id1 = this.route.snapshot.params.id1;
    this.id2 = this.route.snapshot.params.id2;

    let project1Exists = false;
    let project2Exists = false;
    let projects = [];
    this.projectService.getAllOfDepartment(this.authService.getLoggedInEmail()).subscribe({
      next: data => {
        projects = data;
        for (let i = 0; i < projects.length; i++) {
          if (this.id1 == projects[i].id) {
            project1Exists = true;
          }
          if (this.id2 == projects[i].id) {
            project2Exists = true;
          }
        }
        if (!project1Exists || !project2Exists) {
          this.router.navigate(['/projects']);
        }
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 500) {
          this.errorMessage = `<strong>Internal Server Error!</strong> ${err.error}`;
          this.router.navigate(['/500']).then(r => console.log('Error status 500'));
        }
      }
    });

    //get projects
    this.projectService.getById(this.id1).subscribe({
      next: data => {
        this.project1 = data;
      },
      error: err => {
        this.error = true;
        this.errorMessage = err.error;
      }
    });
    this.projectService.getById(this.id2).subscribe({
      next: data => {
        this.project2 = data;
      },
      error: err => {
        this.error = true;
        this.errorMessage = err.error;
      }
    });

    //get assessments
    this.projectService.getAssessment(this.id1).subscribe({
      next: data => {
        this.assessment1 = data;
        this.processTraits1 = this.assessment1.traits.slice(0, 6);
        this.psychoTraits1 = this.assessment1.traits.slice(6, 10);
        this.neuroTraits1 = this.assessment1.traits.slice(10, 17);
        this.projectService.getAssessment(this.id2).subscribe({
          next: data => {
            this.assessment2 = data;
            this.processTraits2 = this.assessment2.traits.slice(0, 6);
            this.psychoTraits2 = this.assessment2.traits.slice(6, 10);
            this.neuroTraits2 = this.assessment2.traits.slice(10, 17);
            this.renderChart();
          },
          error: err => {
            this.error = true;
            this.errorMessage = err.error();
          }
        });
      },
      error: err => {
        this.error = true;
        this.errorMessage = err.error();
      }
    });

    let coordinates = [];
    this.projectService.getNeuroMap(this.id1).subscribe(
      {
        next: data1 => {
          this.neuroMap1 = data1;
          this.neuroMap1.forEach((element) => coordinates.push({x: element.xvalue, y: element.yvalue}));

          this.projectService.getNeuroMap(this.id2).subscribe(
            {
              next: data2 => {
                let inBoth = []
                let in1 = [];
                let in2 = [];

                for (let i = 0; i < data1.length; i++) {
                  let inBothBool = false;
                  for (let j = 0; j < data2.length; j++) {
                    if (data1[i].name == data2[j].name) {
                      inBothBool = true;
                    }
                  }
                  if (!inBothBool) {
                    in1.push([data1[i].xvalue, data1[i].yvalue, data1[i].name]);
                  }
                }

                for (let i = 0; i < data2.length; i++) {
                  let inBothBool = false;
                  for (let j = 0; j < data1.length; j++) {
                    if (data1[j].name == data2[i].name) {
                      inBothBool = true;
                    }
                  }
                  if (!inBothBool) {
                    in2.push([data2[i].xvalue, data2[i].yvalue, data2[i].name]);
                  }
                }

                for (let i = 0; i < data1.length; i++) {
                  for (let j = 0; j < data2.length; j++) {
                    if (data1[i].name == data2[j].name) {
                      inBoth.push([data1[i].xvalue, data1[i].yvalue, data1[i].name]);
                    }
                  }
                }
                this.neuroMapScatterChart = this.initializeOverallNeuroChart("neuroMap",
                  in1, in2, inBoth);
              }
            });
        }
      }
    );

  }

  renderChart() {
    const psychoTraitNames = this.psychoTraits1.map(trait => trait.trait);
    const neuroTraitNames = this.neuroTraits1.map(trait => trait.trait);
    const processTraitNames = this.processTraits1.map(trait => trait.trait);

    const psychoTraitPercentage1 = this.psychoTraits1.map(trait => trait.resultPercentage);
    const neuroTraitPercentage1 = this.neuroTraits1.map(trait => trait.resultPercentage);
    const processTraitPercentage1 = this.processTraits1.map(trait => trait.resultPercentage);

    const psychoTraitPercentage2 = this.psychoTraits2.map(trait => trait.resultPercentage);
    const neuroTraitPercentage2 = this.neuroTraits2.map(trait => trait.resultPercentage);
    const processTraitPercentage2 = this.processTraits2.map(trait => trait.resultPercentage);

    this.pieChartPsycho1 = this.initializePieChart("pieChartPsycho1", psychoTraitNames, psychoTraitPercentage1);
    this.pieChartNeuro1 = this.initializePieChart("pieChartNeuro1", neuroTraitNames, neuroTraitPercentage1);
    this.pieChartProcess1 = this.initializePieChart("pieChartProcess1", processTraitNames, processTraitPercentage1);

    this.pieChartPsycho2 = this.initializePieChart("pieChartPsycho2", psychoTraitNames, psychoTraitPercentage2);
    this.pieChartNeuro2 = this.initializePieChart("pieChartNeuro2", neuroTraitNames, neuroTraitPercentage2);
    this.pieChartProcess2 = this.initializePieChart("pieChartProcess2", processTraitNames, processTraitPercentage2);

    let psychoDiff = [];
    for (let i = 0; i < psychoTraitPercentage1.length; i++) {
      psychoDiff.push(psychoTraitPercentage2[i] - psychoTraitPercentage1[i]);
    }
    let neuroDiff = [];
    for (let i = 0; i < neuroTraitPercentage1.length; i++) {
      neuroDiff.push(neuroTraitPercentage2[i] - neuroTraitPercentage1[i]);
    }
    let processDiff = [];
    for (let i = 0; i < processTraitPercentage1.length; i++) {
      processDiff.push(processTraitPercentage2[i] - processTraitPercentage1[i]);
    }

    this.overallBarChartPsycho = this.initializeOverallBarChart("overallBarChartPsycho", psychoTraitNames, psychoDiff);
    this.overallBarChartNeuro = this.initializeOverallBarChart("overallBarChartNeuro", neuroTraitNames, neuroDiff);
    this.overallBarChartProcess = this.initializeOverallBarChart("overallBarChartProcess", processTraitNames, processDiff);

  }

  initializePieChart(canvasId, traitNames, traitData) {
    return new Chart(canvasId, {
      type: 'pie',
      data: {
        labels: traitNames,
        datasets: [{
          data: traitData,
          backgroundColor: this.colors,
          hoverBackgroundColor: this.hoverBackgroundColor,
          borderWidth: 1,
          hoverOffset: 10,
        }]
      },
      options: {
        animation: false,
      }
    });
  }

  initializeOverallBarChart(canvasId, traitNames, traitData) {

    let maxValue = Math.max.apply(Math, traitData);
    let minValue = Math.min.apply(Math, traitData);
    let finalValue = Math.max(minValue * -1, maxValue);

    return new Chart(canvasId, {
      type: 'bar',
      data: {
        labels: traitNames,
        datasets: [{
          label: 'Difference in values',
          data: traitData,
          backgroundColor: this.colors,
          hoverBackgroundColor: this.hoverBackgroundColor,
          borderWidth: 1,
        }]
      },
      options: {

        plugins: {
          legend: {
            labels: {
              color: 'grey'
            }
          }
        },
        animation: false,
        indexAxis: 'y',
        scales: {
          x: {
            suggestedMin: finalValue * -1.3,
            suggestedMax: finalValue * 1.3,
          }
        }
      },
      plugins: []
    });
  }

  initializeOverallNeuroChart(canvasId, in1, in2, inBoth) {
    return new Chart(canvasId, {
      type: 'scatter',
      data: {
        datasets: [{
          label: this.project1.name,
          data: in1,
          backgroundColor: 'lightblue',
          hoverBackgroundColor: '#86C6DA',
          borderWidth: 1,
        },
          {
            label: this.project2.name,
            data: in2,
            backgroundColor: 'lightpink',
            hoverBackgroundColor: '#FF9BA9',
            borderWidth: 1,
          },
          {
            label: "in beiden enthalten",
            data: inBoth,
            backgroundColor: '#B4DEC2',
            hoverBackgroundColor: '#94D0A8',
            borderWidth: 1,
          }]
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (item) {
                return "(" + Math.round(item["raw"][0] * 1000) / 1000 + ", " + Math.round(item["raw"][1] * 1000) / 1000 + ") " + item["raw"][2];
              }
            }
          }
        },
        animation: false,
        elements: {
          point: {
            radius: 20,
            hoverRadius: 25
          }
        },
        scales: {
          x: {
            min: -1,
            max: 1,
            title: {
              display: true,
              text: 'Willensbahnung',
              font: {
                size: 15
              }
            }
          },
          y: {
            min: -1,
            max: 1,
            title: {
              display: true,
              text: 'Selbstwachstum',
              font: {
                size: 15
              }
            }
          }
        }
      },
    });
  }

  vanishError() {
    this.error = false;
  }
}
