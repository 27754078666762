import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '../../services/auth.service';


import {SidebarService} from '../../services/sidebar.service';
import {User} from "../../dtos/user";
import {UserService} from "../../services/user.service";
import {ActivatedRoute} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: User = {
    id: null,
    firstName: "",
    lastName: "",
    birthday: null,
    jobrole: "",
    email: "",
    password: "",
    role: "",
    department: null,
  } || null;

  error = false;
  errorMessage = '';
  // After first submission attempt, form validation will start
  submitted = false;
  isLoggedIn = false;


  constructor(
    public authService: AuthService,
    private sidebarService: SidebarService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
  }


  ngOnInit() {
    this.route.data.subscribe(data => {
      this.getUser(data);
    });
  }

  getUser(data: any) {
    let email = this.getEmail();
    if (!email)
      email = this.authService.getLoggedInEmail();
    this.userService.getUserByEmail(email).subscribe({
      next: data => {
        this.user = data;
      },
      error: error => {
        this.error = true;
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.errorMessage = `<strong>Problems fetching User!</strong> ${error.error}`;
        }
      }
    });
  }

  getEmail(): string {
    return this.route.snapshot.paramMap.get('email');
  }


  @Output() sidebarStateChanged = new EventEmitter<boolean>();


  showSidebar = false;

  toggleSidebar(): void {
    this.sidebarService.toggleCollapse();
    this.showSidebar = !this.showSidebar;
    this.sidebarStateChanged.emit(!this.showSidebar);
  }

}

