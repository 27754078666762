<div class="container">
    <div class="row justify-content-center">
        <div class="col-lg-6">
            <div class="text-center mt-4">
                <h1 class="display-1">401</h1>
                <p class="lead">Unauthorized</p>
                <p>Access to this resource is denied.</p>
                <a *ngIf="authService.isLoggedIn()" href="../../../../#"><i class="bi bi-arrow-left me-1"></i>Return to Home</a>
                <a *ngIf="!(authService.isLoggedIn())" href="../../../../#/login">Login <i class="bi bi-arrow-right me-1"></i></a>
            </div>
        </div>
    </div>
</div>
