import {Component, enableProdMode, OnInit, TemplateRef} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {UserService} from "../../../services/user.service";
import {ProjectService} from "../../../services/project.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Project} from "../../../dtos/project";
import {User} from "../../../dtos/user";
import {UserSuggestionsSearch} from "../../../dtos/userSearch";
import {Chart} from "chart.js/auto";
import {Trait} from "../../../dtos/trait";
import {HttpErrorResponse} from "@angular/common/http";
import {TeamAssessment} from "../../../dtos/teamAssessment";
import {SafeHtml} from "@angular/platform-browser";
import {JobRoles} from "../../../dtos/register-request";
import {Observable} from "rxjs";
import {QuestionService} from "../../../services/question.service";
import {ChatOpenAI} from "langchain/chat_models/openai";
import {NeuroMap} from "../../../dtos/neuromap";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";

enableProdMode();

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent implements OnInit {

  constructor(public authService: AuthService, public userService: UserService, public projectService: ProjectService,
              public questionService: QuestionService, private router: Router, private route: ActivatedRoute,
              private notification: ToastrService, private modalService: NgbModal) {
  }

  protected readonly Array = Array;
  project: Project = {id: 0, name: "", members: [], department: null};
  teamassessment = true;
  errorMessage: SafeHtml = '';
  searchInput: string = "";
  suggestions: User[] = [];
  numberOfMembers: number = 0;

  neuroMap: NeuroMap[];
  jobRoles = [null, ...Object.values(JobRoles)];

  selectedJobRoles: string[] = [];
  assessmentTrait: Trait[];
  unselectedUsers: User[] = [];
  allUsers: User[] = [];
  allUserTraits = [];
  projects: Project[];
  dontSendGpt = false;
  loading = false;


  selectedUser: User = {
    id: -1,
    email: "",
    firstName: "",
    lastName: "",
    birthday: null,
    jobrole: "",
    role: "",
    password: "",
    department: null
  };

  showDeleteConfirmation: boolean = true;
  id: number = 0;

  //variables for assessment
  processTraits = [];
  psychoTraits = [];
  neuroTraits = [];
  colors = ['darkgrey', 'lightblue', 'lightpink', '#B4DEC2', '#D7AFFF', '#ECE7A2', '#FFBA9F'];
  hoverBackgroundColor = ['grey', '#86C6DA', '#FF9BA9', '#94D0A8', '#C58BFF', "#E2DA70", 'lightsalmon'];
  assessment: TeamAssessment;
  pieChartPsycho;
  pieChartNeuro;
  pieChartProcess;
  overallBarChartPsycho;
  overallBarChartNeuro;
  overallBarChartProcess;
  neuroMapScatterChart;
  selectedTeamMembers: any[] = [];
  selectedTeamMembersAi: Array<string> = [];
  criteria = ['Entdeckergeist', 'Erfindungsgabe', 'Urteilsvermögen', 'Begeisterung', 'Befähigung', 'Beharrlichkeit'];
  scores: number[] = new Array(this.criteria.length).fill(null);


  addUser(user: User): void {
    this.project.members.push(user);
    this.projectService.updateProject(this.project).subscribe({
      next: data => {
        this.project = data;
        this.userService.getSuggestionsByName(this.readInput()).subscribe({
          next: data => {
            this.suggestions = data;
          }
        });
        if (this.project.members.length == 1) {
          this.renderChart();
        }
        this.updateCharts();
      },
      error: error => {
      }
    });
  }

  removeUser(user: User): void {
    let index = this.project.members.indexOf(user, 0);
    if (index > -1) {
      this.project.members.splice(index, 1);
    }
    this.projectService.updateProject(this.project).subscribe({
      next: data => {
        this.project = data;
        this.userService.getSuggestionsByName(this.readInput()).subscribe({
          next: data => {
            this.suggestions = data;
          }
        });
        this.updateCharts();
      },
      error: error => {
      }
    });
  }

  onDelete(member: User) {
    this.selectedUser = member;
    this.showDeleteConfirmation = true;
  }

  onCancelDelete() {
    this.showDeleteConfirmation = false;
  }

  updateSearchResult() {
    var v = this.userService.getSuggestionsByName(this.readInput());
    v.subscribe({
      next: data => {
        this.suggestions = data;
      }
    });
  }

  readInput(): UserSuggestionsSearch {
    let parts = this.searchInput.split(" ");
    return {
      firstInput: parts[0], lastInput: parts[1], maxResults: 10, projectId: this.project.id,
      managerEmail: this.authService.getLoggedInEmail()
    }
  }

  ngOnInit(): void {
    if (!this.authService.isLoggedIn() || !(this.authService.isManager())) {
      this.router.navigate(['/401']).then(r => console.log('Error status 401')); // redirect to 401 component if not logged in
    }
    let projectExists = false;
    this.id = this.route.snapshot.params.id;
    this.projectService.getAllOfDepartment(this.authService.getLoggedInEmail()).subscribe({
      next: data => {
        this.projects = data;
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 500) {
          this.errorMessage = `<strong>Internal Server Error!</strong> ${err.error}`;
          this.router.navigate(['/500']).then(r => console.log('Error status 500'));
        }
      },
      complete: () => {
        for (let i = 0; i < this.projects.length; i++) {
          if (this.id == this.projects[i].id) {
            projectExists = true;
          }
        }
        if (projectExists) {
          this.projectService.getById(this.id).subscribe({
            next: data => {
              this.project = data;
            }
          });

          this.userService.getSuggestionsByName({
            firstInput: "",
            lastInput: "",
            maxResults: 10,
            projectId: this.id,
            managerEmail: this.authService.getLoggedInEmail()
          }).subscribe({
            next: data => {
              this.suggestions = data;
            }
          });

          this.getAssessment().then(() => {
            this.renderChart()
          }).catch((error) => {
          });


          this.userService.getSuggestionsByName({
            firstInput: "",
            lastInput: "",
            maxResults: null,
            projectId: this.id,
            managerEmail: this.authService.getLoggedInEmail()
          }).subscribe({
            next: data => {
              this.unselectedUsers = data;

              for (let i = 0; i < this.project.members.length; i++) {
                this.questionService.getAssessment(this.project.members[i].email).subscribe({
                  next: data => {
                    //this.allUnselectedUsersTraits = data;
                    this.allUserTraits.push(data);
                    this.allUsers.push(this.project.members[i]);
                  }
                });
              }

              for (let j = 0; j < this.unselectedUsers.length; j++) {
                this.questionService.getAssessment(this.unselectedUsers[j].email).subscribe({
                  next: data => {
                    //this.allUnselectedUsersTraits = data;
                    this.allUserTraits.push(data);
                    this.allUsers.push(this.unselectedUsers[j]);
                  }
                });
              }
            }
          });
        } else {
          this.router.navigate(['/projects']);
        }
      }
    });
  }

  // assessment

  renderChart() {
    const psychoTraitNames = this.psychoTraits.map(trait => trait.trait);
    const neuroTraitNames = this.neuroTraits.map(trait => trait.trait);
    const processTraitNames = this.processTraits.map(trait => trait.trait);
    const psychoTraitPercentage = this.psychoTraits.map(trait => trait.resultPercentage);
    const neuroTraitPercentage = this.neuroTraits.map(trait => trait.resultPercentage);
    const processTraitPercentage = this.processTraits.map(trait => trait.resultPercentage);

    this.pieChartPsycho = this.initializePieChart("pieChartPsycho", psychoTraitNames, psychoTraitPercentage);
    this.pieChartNeuro = this.initializePieChart("pieChartNeuro", neuroTraitNames, neuroTraitPercentage);
    this.pieChartProcess = this.initializePieChart("pieChartProcess", processTraitNames, processTraitPercentage);

    this.overallBarChartPsycho = this.initializeOverallBarChart("overallBarChartPsycho", psychoTraitNames, psychoTraitPercentage);
    this.overallBarChartNeuro = this.initializeOverallBarChart("overallBarChartNeuro", neuroTraitNames, neuroTraitPercentage);
    this.overallBarChartProcess = this.initializeOverallBarChart("overallBarChartProcess", processTraitNames, processTraitPercentage);

    let coordinates = [];
    this.projectService.getNeuroMap(this.id).subscribe(
      {
        next: data => {

          this.neuroMap = data;
          let names = this.project.members.map((user) => "");
          let counter = 0;
          this.neuroMap.forEach((element) => {
            let r = 0;
            this.neuroMap.forEach((element2) => {
              if (element.xvalue == element2.xvalue && element.yvalue == element2.yvalue) {
                r += 15;
                if (r > 15) {
                  names[counter] = names[counter].concat(", " + element2.name);
                } else {
                  names[counter] = names[counter].concat(element2.name);
                }
              }
            })
            coordinates.push({
              x: element.xvalue,
              y: element.yvalue,
              r: r
            });
            counter++;
          });
          this.neuroMapScatterChart = this.initializeOverallNeuroChart("neuroMap", names,
            coordinates);
        }
      }
    );
  }

  initializePieChart(canvasId, traitNames, traitData) {
    return new Chart(canvasId, {
      type: 'pie',
      data: {
        labels: traitNames,
        datasets: [{
          data: traitData,
          backgroundColor: this.colors,
          hoverBackgroundColor: this.hoverBackgroundColor,
          borderWidth: 1,
          hoverOffset: 10,
        }]
      },
      options: {}
    });
  }

  getAssessment(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.teamassessment = true;
      let observable: Observable<TeamAssessment>;
      observable = this.projectService.getAssessment(this.id);
      observable.subscribe({
        next: data => {
          this.assessment = data;
          this.processTraits = this.assessment.traits.slice(0, 6);
          this.psychoTraits = this.assessment.traits.slice(6, 10);
          this.neuroTraits = this.assessment.traits.slice(10, 17);
          resolve();
        },
        error: error => {
          this.teamassessment = false;
          if (error instanceof HttpErrorResponse && error.status === 404) {
            this.notification.error(error.error, "Problems fetching result of questionnaire");
            this.errorMessage = `<strong>Problems fetching Questionairresult!</strong> ${error.error}`;
          }
          if (error instanceof HttpErrorResponse && error.status === 400) {
            this.notification.error(error.error.detail, "Problems fetching result of questionnaire");
            this.errorMessage = `<strong>Problems fetching Questionairresult!</strong> ${error.error.detail}`;
          }
          reject(error);
        }
      });
    });
  }

  updateCharts() {

    this.pieChartPsycho.destroy();
    this.pieChartNeuro.destroy();
    this.pieChartProcess.destroy();

    this.overallBarChartPsycho.destroy();
    this.overallBarChartNeuro.destroy();
    this.overallBarChartProcess.destroy();
    if (this.neuroMapScatterChart != undefined) {
      this.neuroMapScatterChart.destroy();
    }


    this.getAssessment().then(() => {
      this.renderChart()
    }).catch((error) => {
    });

  }

  initializeOverallBarChart(canvasId, traitNames, traitData) {
    const verticalLine = {
      id: 'verticalLine',
      afterDraw(chart, args, options) {
        const {
          ctx,
          chartArea: {top, right, bottom, left, width, height},
          scales: {x, y}
        } = chart;
        ctx.save();
        ctx.strokeStyle = '#5F5F5F';
        const xPos = x.getPixelForValue(1 / traitData.length);
        const yPos = y.getPixelForValue(30);
        ctx.beginPath();
        ctx.moveTo(xPos, top);
        ctx.lineTo(xPos, bottom);
        ctx.stroke();
        ctx.restore();
      }
    };
    const horizontalLine = {
      id: 'horizontalLine',
      afterDraw(chart, args, options) {
        const {
          ctx,
          chartArea: {top, right, bottom, left, width, height},
          scales: {x, y}
        } = chart;
        ctx.save();
        ctx.strokeStyle = '#5F5F5F';
        ctx.strokeRect(right - 130, 15, 20, 0)
        ctx.fillStyle = 'grey';
        ctx.textAlign = 'left';
        ctx.font = '12px Arial';
        ctx.fillText('average', right - 100, 15);
        ctx.restore();
      }
    };


    return new Chart(canvasId, {
      type: 'bar',
      data: {
        labels: traitNames,
        datasets: [{
          label: 'rel. proportion',
          data: traitData,
          backgroundColor: this.colors,
          hoverBackgroundColor: this.hoverBackgroundColor,
          borderWidth: 1,
        }]
      },
      options: {
        maintainAspectRatio: false, // Set this to false to control the size manually
        plugins: {
          legend: {
            labels: {
              color: 'grey'
            }
          }
        },
        indexAxis: 'y',
        scales: {
          x: {
            suggestedMin: 0,
            suggestedMax: 2 / traitData.length,
          }
        }
      },
      plugins: [verticalLine, horizontalLine]
    });
  }

  createOptimalTeam() {
    this.loading = true;
    let projectId = this.route.snapshot.params.id;
    //check if values are valid otherwise set 0 or 30
    const sanitizedScores = this.scores.map(score => this.limitScore(score));
    this.projectService.createOptimalTeam(this.numberOfMembers, this.selectedJobRoles, projectId, sanitizedScores).subscribe({
      next: (data: User[]) => {
        this.loading = false;
        this.selectedTeamMembers = data;
      },
      error: error => {
        this.selectedTeamMembers = [];
        let message: string;
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.errorMessage = `<strong>Problems generating Team!</strong> ${error.error}`;
          message = error.error;
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {
          if (error.error && error.error.detail) {
            this.errorMessage = `<strong>Problems generating team!</strong> ${error.error.detail}`;
            message = error.error.detail;
          } else {
            const validationErrors = error.error?.detail?.match(/\[(.*?)\]/);
            if (validationErrors) {
              const validationErrorsString = validationErrors[1];
              this.errorMessage = `<strong>Problems generating team!</strong> ${validationErrorsString}`;
              message = validationErrorsString;
            } else {
              this.errorMessage = `<strong>Problems generating team!</strong> ${error.error}`;
              message = error.error
            }
          }
        }
        this.notification.error(message, "Problems generating team!");
      }
    });
  }


  createOptimalGptTeam() {
    this.sendAnotherRequest(1);
  }

  sendAnotherRequest(counter: number) {
    const personalKey = "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";
    const chatModel = new ChatOpenAI({
      modelName: 'gpt-3.5-turbo',
      temperature: 0.1,
      openAIApiKey: personalKey,
    });

    /**
     * Fixes Angular breaking promises in openai package (returns null)
     * https://github.com/angular/angular/issues/53380.
     */
      // patch begin
    const original = (chatModel as any)._getClientOptions;
    (chatModel as any)._getClientOptions = function (options: any) {
      original.call(this, options);
      const create = this.client.chat.completions.create;
      this.client.chat.completions.create = function (params: any) {
        return new Promise((res, rej) => {
          return create.call(this, params).then(res, rej);
        });
      }
    };
    // patch end---------------------------------

    this.dontSendGpt = false;

    let prompt = this.createGptPrompt(); // Create a new prompt
    if (counter == 1) {
      this.selectedTeamMembersAi = ["loading..."];
    }


    //Checks If there are enough Users with required Jobs
    if (this.dontSendGpt == false) {
      chatModel.predict(prompt).then((result: any) => {
        if (typeof result == "string") {
          const pasteMe = result.split(/\r?\n/);


          this.selectedTeamMembersAi = pasteMe;

          //Checks If Responded with "username" and sends another Request
          let hadUsername = false;
          for (let i = 0; i < this.selectedTeamMembersAi.length; i++) {
            const containsUsername = this.selectedTeamMembersAi.some((element) =>
              element.includes("username"));
            if (containsUsername == true) {
              hadUsername = true;
              break;
            }
          }

          //Checks If Responded without required amount of mails and sends another Request
          let hadEnoughMails = true;
          let mails = this.extractMailsFromResponse(pasteMe.toString(), this.numberOfMembers);
          if (mails.length < this.numberOfMembers) {
            hadEnoughMails = false;
          }

          //Checks if Responded with Blacklisted Elements
          let hadBlackList = false;
          for (let i = 0; i < this.selectedTeamMembersAi.length; i++) {
            const containsBlackListed1 = this.selectedTeamMembersAi.some((element) =>
              element.includes("folgende Paare"));
            const containsBlackListed2 = this.selectedTeamMembersAi.some((element) =>
              element.includes("Teams"));
            const containsBlackListed3 = this.selectedTeamMembersAi.some((element) =>
              element.includes("Daher kann ich kein Team mit der angegebenen Größe erstellen."));
            const containsBlackListed4 = this.selectedTeamMembersAi.some((element) =>
              element.includes("ögliche Kombinationen"));
            if (containsBlackListed1 == true) {
              hadBlackList = true;
              break;
            }
            if (containsBlackListed2 == true) {
              hadBlackList = true;
              break;
            }
            if (containsBlackListed3 == true) {
              hadBlackList = true;
              break;
            }
            if (containsBlackListed4 == true) {
              hadBlackList = true;
              break;
            }
          }


          //Checks if Responded with WhiteListed Elements
          let hadWhiteList = false;
          for (let i = 0; i < this.selectedTeamMembersAi.length; i++) {
            const containsWhiteListed1 = this.selectedTeamMembersAi.some((element) =>
              element.includes("Ein möglich"));
            const containsWhiteListed2 = this.selectedTeamMembersAi.some((element) =>
              element.includes("Um ein"));
            const containsWhiteListed3 = this.selectedTeamMembersAi.some((element) =>
              element.includes("Basierend auf"));
            if (containsWhiteListed1 == true) {
              //console.log("Whitelisted Element [Ein möglich] found");
              hadWhiteList = true;
              break;
            }
            if (containsWhiteListed2 == true) {
              //console.log("Whitelisted Element [Um ein] found");
              hadWhiteList = true;
              break;
            }
            if (containsWhiteListed3 == true) {
              //console.log("Whitelisted Element [Basierend auf] found");
              hadWhiteList = true;
              break;
            }
          }

          if (hadWhiteList == false) {
          }

          if (hadWhiteList == false || hadBlackList == true || hadUsername == true || hadEnoughMails == false) {
            this.selectedTeamMembersAi = ["Response from KI wasn't good enough, \n Attempt: " + counter + "\n loading new response..."];
            counter += 1;
            if (counter < 4) {
              this.sendAnotherRequest(counter);
            } else {
              this.selectedTeamMembersAi = ["Failed generating Response from KI too often.. :( \n Please Try again."]
            }
          }


        } else {
          this.selectedTeamMembersAi = ["Error with Response from KI, typeof is not string"]
        }
      }).catch(
        (error: any) => {
          console.error('Error fetching data:', error);
          this.selectedTeamMembersAi = ['Error fetching data. \n Please make sure you have a working internet connection.'];
        }
      );
    } else {
      //Sends Error to selectedTeamMembersAi If not enough Users with Job
      this.selectedTeamMembersAi = [prompt];
    }
  }

  onAddAiMails() {
    let response = this.selectedTeamMembersAi.toString();
    this.addAiMailsToTeam(response, this.numberOfMembers);
  }

  onAddMails() {
    this.project.members = this.selectedTeamMembers;
    this.updateProject();
  }

  addAiMailsToTeam(gptResponse: string, expectedLength: number) {

    let mails = this.extractMailsFromResponse(gptResponse, expectedLength);

    let users: User[] = [];

    // Verwenden Sie mails.length anstelle von length
    for (let i = 0; i < mails.length; i++) {
      const userObservable = this.userService.getUserByEmail(mails[i]);

      userObservable.subscribe({
        next: (userData: User) => {
          users.push(userData);
        },
        error: (error: any) => {
          console.error('Error fetching user data:', error);
        },
        complete: () => {
          this.project.members = users;
          this.updateProject();
        }
      });
    }
  }

  updateProject() {
    this.projectService.updateProject(this.project).subscribe({
      next: data => {
        this.project = data;
        this.userService.getSuggestionsByName(this.readInput()).subscribe({
          next: data => {
            this.suggestions = data;
          }
        });
        this.updateCharts();
      },
      error: error => {
        this.selectedTeamMembers = [];
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.errorMessage = `<strong>Problems generating Team!</strong> ${error.error}`;
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {
          if (error.error && error.error.detail) {
            this.errorMessage = `<strong>Problems generating team!</strong> ${error.error.detail}`;
          } else {
            const validationErrors = error.error?.detail?.match(/\[(.*?)\]/);
            if (validationErrors) {
              const validationErrorsString = validationErrors[1];
              this.errorMessage = `<strong>Problems generating team!</strong> ${validationErrorsString}`;
            } else {
              this.errorMessage = `<strong>Problems generating team!</strong> ${error.error}`;
            }
          }
        }
        this.notification.error(this.errorMessage.toString(), "", {enableHtml: true});
      }
    });
  }

  extractMailsFromResponse(inputString: string, expectedLength: number) {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Z|a-z]{2,}\b/g;
    const extractedEmails = inputString.match(emailRegex);

    if (extractedEmails) {
      const lastEmails = extractedEmails.slice(-expectedLength);
      return lastEmails;
    } else {
    }
  }

  countEmailsInResponse(inputString: string): number {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Z|a-z]{2,}\b/g;
    const extractedEmails = inputString.match(emailRegex);

    if (extractedEmails) {
      return extractedEmails.length;
    } else {
      return 0;
    }
  }

  createGptPrompt() {
    let prompt = "";

    prompt += "Du bist ein Datenanalyst und ein Psychologe\n" +
      "\n" +
      "Ich werde dir ein 2D Array an Leuten geben in diesem Format:\n" +
      "Username, beste eigenschaft, schlechteste eigenschaft\n" +
      "\n" +
      "\n" +
      "erstelle mir 1 ausgeglichenes Team aus diesen leuten:\n" +
      "\n";


    for (let i = 0; i < this.allUsers.length; i++) {
      prompt += this.getQuestionResultsOfUser(this.allUsers[i].email);
    }
    prompt += "\n";


    let securitySpecialistCount = this.selectedJobRoles.filter(item => item === "Security Specialist").length;
    let specialistCount = this.selectedJobRoles.filter(item => item === "Specialist").length;
    let developerCount = this.selectedJobRoles.filter(item => item === "Developer").length;
    let designerCount = this.selectedJobRoles.filter(item => item === "Designer").length;
    let checkMe = "";
    if (securitySpecialistCount != 0) {
      checkMe = this.getMailsWithJobRole("Security Specialist")
      if (securitySpecialistCount < this.countEmailsInResponse(checkMe) + 1) {
        prompt += "Wir wollen auch Security Specialist haben, daher MUSS " + securitySpecialistCount + " dieser Personen dabei sein:"
        prompt += checkMe + " \n";
      } else {
        this.dontSendGpt = true;
        return "In diesem Department gibt es nicht " + securitySpecialistCount + " Security Specialists";
      }
      checkMe = "";
    }
    if (specialistCount != 0) {
      checkMe = this.getMailsWithJobRole("Specialist")
      if (specialistCount < this.countEmailsInResponse(checkMe) + 1) {
        prompt += "Wir wollen auch Specialist haben, daher MUSS " + specialistCount + " dieser Personen dabei sein:"
        prompt += checkMe + " \n";
      } else {
        this.dontSendGpt = true;
        return "In diesem Department gibt es nicht " + specialistCount + " Specialists";
      }
      checkMe = "";
    }
    if (developerCount != 0) {
      checkMe = this.getMailsWithJobRole("Developer")
      if (developerCount < this.countEmailsInResponse(checkMe) + 1) {
        prompt += "Wir wollen auch Developer haben, daher MUSS " + developerCount + " dieser Personen dabei sein:"
        prompt += checkMe + " \n";
      } else {
        this.dontSendGpt = true;
        return "In diesem Department gibt es nicht " + developerCount + " Developer";
      }
      checkMe = "";
    }
    if (designerCount != 0) {
      checkMe = this.getMailsWithJobRole("Designer")
      if (designerCount < this.countEmailsInResponse(checkMe) + 1) {
        prompt += "Wir wollen auch Designer haben, daher MUSS " + designerCount + " dieser Personen dabei sein: "
        prompt += checkMe + " \n";
      } else {
        this.dontSendGpt = true;
        return "In diesem Department gibt es nicht " + designerCount + " Designer";
      }
      checkMe = "";
    }

    prompt += "Erstelle mir 1 TEAM mit Maximale Teamgroesse " + this.numberOfMembers + "\n";

    prompt += "Antworte in diesem format: \n"
    for (let i = 1; i <= this.numberOfMembers; i++) {
      prompt += "username" + i + "@mail.com, explanation \n"
    }

    if (this.numberOfMembers == 2) {
      prompt += "\n" +
        "DU HAST EIN 50 WOERTER LIMIT UM ZU ANTWORTEN";
    } else {
      prompt += "\n" +
        "DU HAST EIN 100 WOERTER LIMIT UM ZU ANTWORTEN";
    }
    return prompt;
  }

  getMailsWithJobRole(job: string) {
    let list = " \n";

    for (let i = 0; i < this.allUsers.length; i++) {
      if (this.allUsers[i].jobrole == job) {
        list += this.allUsers[i].email + " \n";
      }
    }

    return list;
  }


  getQuestionResultsOfUser(mail: string) {

    let mostValue = 0;
    let leastValue = 0;
    let traitsOfUser = [];
    for (let i = 0; i < this.allUsers.length; i++) {
      if (mail == this.allUsers[i].email) {
        traitsOfUser = this.allUserTraits[i];
        break;
      }
    }
    const TraitNames = traitsOfUser.map(trait => trait.trait);
    const TraitResults = traitsOfUser.map(trait => trait.result);

    mostValue = this.getBestTraits(mostValue, TraitResults);
    leastValue = this.getWorstTraits(leastValue, TraitResults);

    return mail + ", " + TraitNames[mostValue] + ", " + TraitNames[leastValue] + "\n";
  }


  /**
   * Gets the index of the trait with the highest result value
   * @param {number} mostValue - The current index of the trait with the highest result value
   * @param {number[]} traitResults - An array of result values for the traits
   * @returns {number} - The index of the trait with the highest result value
   */
  getBestTraits(mostValue, traitResults) {

    let mostValueTrait = traitResults[0];
    for (let i = 0; i <= traitResults.length; i++) {
      if (mostValueTrait < traitResults[i]) {
        mostValueTrait = traitResults[i];
        mostValue = i;
      }
    }
    return mostValue;
  }

  /**
   * Gets the index of the trait with the lowest result value
   * @param {number} leastValue - The current index of the trait with the lowest result value
   * @param {number[]} traitResults - An array of result values for the traits
   * @returns {number} - The index of the trait with the lowest result value
   */
  getWorstTraits(leastValue, traitResults) {

    let leastValueTrait = traitResults[0];
    for (let i = 0; i <= traitResults.length; i++) {
      if (leastValueTrait > traitResults[i]) {
        leastValueTrait = traitResults[i];
        leastValue = i;
      }
    }
    return leastValue;
  }

  numberofmembers() {
    if (this.suggestions.length + this.project.members.length < 5) {
      let array: number[] = [];
      for (let i = 2; i <= this.suggestions.length + this.project.members.length; i++) {
        array.push(i);
      }
      return array;
    } else {
      return [2, 3, 4, 5]
    }
  }

  areAllScoresFilled(): boolean {
    return this.scores.every(score => score !== null && score !== undefined);
  }

  limitScore(score: number): number {
    const minScore = 0;
    const maxScore = 30;
    return Math.min(Math.max(score, minScore), maxScore);
  }


  openDialog(dialogModal: TemplateRef<any>) {

    this.modalService.open(dialogModal, {ariaLabelledBy: 'modal-basic-title'});

  }

  projectsToCompare() {
    let returnArray = [];
    for (let i = 0; i < this.projects.length; i++) {
      if (this.project.id != this.projects[i].id) {
        returnArray.push(this.projects[i]);
      }
    }
    return returnArray;
  }

  initializeOverallNeuroChart(canvasId, traitNames, traitData) {
    return new Chart(canvasId, {
      type: 'bubble',
      data: {
        labels: traitNames,
        datasets: [{
          label: 'Neurological distribution',
          data: traitData,
          backgroundColor: this.colors,
          hoverBackgroundColor: this.hoverBackgroundColor,
          borderWidth: 1,
        }]
      },
      options: {
        animation: {
          duration: 2000,
          easing: "easeInQuad"
        },
        elements: {
          point: {
            radius: 20,
            hoverRadius: 25
          }
        },
        scales: {
          x: {
            min: -1,
            max: 1,
            title: {
              display: true,
              text: 'Willensbahnung',
              font: {
                size: 15
              }
            }
          },
          y: {
            min: -1,
            max: 1,
            title: {
              display: true,
              text: 'Selbstwachstum',
              font: {
                size: 15
              }
            }
          }
        }
      },
    });
  }
}
