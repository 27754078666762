
  export enum JobRoles {
  Security_Specialist = 'Security Specialist',
  Specialist = 'Specialist',
  Developer = 'Developer',
  Designer = 'Designer',
}

export class RegisterRequest {
    constructor(
        public firstName: String,
        public lastName: String,
        public birthday: Date,
        public jobrole: string,
        public username: string,
        public password: string,
        public mode: string
    ) { }
}
