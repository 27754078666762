import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {FALLBACK, GravatarConfig, GravatarModule, RATING} from "ngx-gravatar";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {DropdownBlaseComponent} from './components/dropdown-blase/dropdown-blase.component';
import {FooterComponent} from './components/footer/footer.component';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {GptComponent} from './components/gpt/gpt.component';
import {RegisterComponent} from './components/register/register.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {httpInterceptorProviders} from './interceptors';
import {QuestionnaireComponent} from './pages/questionnaire/questionnaire.component';
import {ProfileComponent} from './components/profile/profile.component';
import {ProfileEditComponent} from "./components/profile/profile-edit/profile-edit.component";
import {ConfirmDeleteDialogComponent} from './components/delete/confirm-delete-dialog.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component'
import {PaginationComponent} from './components/pagination/pagination.component';

import {AllMembersComponent} from './pages/all-members/all-members.component';
import {DepartmentsComponent} from './pages/departments/departments.component';
import {MyDepartmentComponent} from './pages/my-department/my-department.component';
import {InviteComponent} from './components/invite/invite.component';
import {InviteService} from "./services/invite.service";
import {ConfirmRemoveDialogComponent} from './components/confirm-remove-dialog/confirm-remove-dialog.component';

import {ProjectComponent} from './components/project/project.component';
import {ProjectEditComponent} from './components/project/project-edit/project-edit.component';
import {ProjectCreateComponent} from './components/project/project-create/project-create.component';
import {SidebarShrinkComponent} from './components/sidebar-shrink/sidebar-shrink.component';

import {NotFoundComponent} from './components/error/not-found/not-found.component';
import {NotAuthorizedComponent} from './components/error/not-authorized/not-authorized.component';
import {ServerErrorComponent} from './components/error/server-error/server-error.component';
import {CompareComponent} from './components/project/compare/compare.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {ImpressumComponent} from "./components/impressum/impressum.component";

const gravatarConfig: GravatarConfig = {
  fallback: FALLBACK.robohash,
  rating: RATING.x,
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  round: true,
  hasBorder: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    GptComponent,
    RegisterComponent,
    QuestionnaireComponent,
    ProfileComponent,
    ProfileEditComponent,
    ConfirmDeleteDialogComponent,
    ResetPasswordComponent,
    PaginationComponent,
    AllMembersComponent,
    DepartmentsComponent,
    InviteComponent,
    MyDepartmentComponent,
    ProjectComponent,
    ProjectEditComponent,
    ProjectCreateComponent,
    ConfirmRemoveDialogComponent,
    DropdownBlaseComponent,
    SidebarShrinkComponent,
    NotFoundComponent,
    NotAuthorizedComponent,
    ServerErrorComponent,
    CompareComponent,
    ImpressumComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    GravatarModule.forRoot(gravatarConfig),
    ToastrModule.forRoot({
      tapToDismiss: true,
    }),
    BrowserAnimationsModule,

  ],
  providers: [httpInterceptorProviders, InviteService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
