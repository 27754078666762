<form #form="ngForm" [class.was-validated]="form.submitted" (ngSubmit)="save(form)">
  <div class="d-flex flex-row w-auto">
    <input id="projectName" name="projectName" class="rounded-input" style="font-size: 40px;" placeholder="Project name"
           [(ngModel)]="name">
    <span style="flex: 1"></span>
    <button type="submit" class="btn btn-primary" style="min-width: 10%" [disabled]="!form.valid">
      Create
    </button>
  </div>
</form>

