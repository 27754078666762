<div *ngIf="!(error) || (authService.isLoggedIn())" class="mt-4 container">
  <div class="row title" *ngIf="authService.isLoggedIn()">
    <h1 class="text-center" *ngIf="!(authService.isAdmin())">Welcome
      back, {{this.user.firstName}} {{this.user.lastName}}!</h1>
    <h1 class="text-center" *ngIf="authService.isAdmin()">Welcome back, {{this.user.firstName}}!</h1>
    <hr class="my-4">
  </div>
  <div class="row">
    <a *ngIf="!authService.isLoggedIn()" class="d-block text-center button form-control btn btn-primary  btn-lg"
       routerLink="/login" role="button">Login</a>
    <div *ngIf="authService.isLoggedIn()">
      <div class="text-center" *ngIf="this.answered">Questionnaire has been answered</div>
    </div>
    <div *ngIf="authService.isLoggedIn() && !this.answered">
      <p>Please fill out the questionnaire completely!</p>
      <p class="lead ">
        <a class="btn btn-primary btn-lg" routerLink="/questionnaire" role="button">Questionnaire</a>
      </p>
    </div>
    <div *ngIf="authService.isLoggedIn() && answerService.checkAnswered()">
      <p>You can view the evaluation of the questionnaire in your <a routerLink="/profile">Profile</a>.</p>
    </div>
  </div>
  <img class="navbar-logo" src="./assets/Logo.png" alt="Logo">
</div>
