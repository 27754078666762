import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../global/globals';


@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  private authBaseUri: string = this.globals.backendUri + '/resetPassword';


  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  resetPassword(email: String): Observable<void> {
    return this.httpClient.put<void>(this.authBaseUri, {email});
  }

  updatePassword(password: string, resetToken: string) {
    const data = {
      newPassword: password,
      resetToken: resetToken
    };
    return this.httpClient.post<void>(this.authBaseUri+"/updatePassword", data);
  }
}
