import {Component} from '@angular/core';
import {SimpleUser, User} from "../../dtos/user";
import {CreateRequest, Department, DepartmentCreate} from "../../dtos/department";
import {NgForm} from "@angular/forms";
import {debounceTime, Observable, Subject} from "rxjs";
import {AuthService} from "../../services/auth.service";
import {UserService} from "../../services/user.service";
import {DepartmentService} from "../../services/department.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {UserSearch} from "../../dtos/userSearch";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-my-department',
  templateUrl: './my-department.component.html',
  styleUrls: ['./my-department.component.scss'],
  providers: [UserService, DepartmentService]
})
export class MyDepartmentComponent {

  notPendingUsers: SimpleUser[];
  userForRemoval: SimpleUser | undefined;
  pendingInvites: SimpleUser[] | undefined;

  invitationDate: String | undefined;

  department: Department | undefined;
  departmentName: string | undefined;

  submitted = false;
  errorMessage = '';
  successMessage = '';

  validDepartmentName = true;
  searchChangedObservable = new Subject<void>();
  invitationInProgress = false;
  revokeInvitationInProgress = false;

  pageOfItems: Array<SimpleUser>;
  searchInput: string = '';
  counter: number;
  pageSize: number = 10;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    public departmentService: DepartmentService,
    private router: Router,
    private notification: ToastrService
  ) {
  }

  manager: User = {
    id: null,
    firstName: "",
    lastName: "",
    birthday: null,
    jobrole: "",
    email: "",
    password: "",
    role: "MANAGER",
    department: null,
  }

  user: User = {
    id: null,
    firstName: "",
    lastName: "",
    birthday: null,
    jobrole: "",
    email: "",
    password: "",
    role: "USER",
    department: null,
  }
  selectedNavItem: string = 'details';


  create(form: NgForm) {
    if (form.valid) {
      this.submitted = true;
      const createRequest: DepartmentCreate = new CreateRequest(
        form.controls.departmentName.value,
        this.authService.getLoggedInEmail(),
        null
      );
      this.departmentService.addDepartment(createRequest).subscribe({
        next: data => {
          this.department = data;
          this.manager.department = data;
          this.successMessage = `<strong>Successfully created Department "${this.department.name}"!</strong>`;
          this.notification.success(this.successMessage, "", {enableHtml: true})
          this.ngOnInit();
        },
        error: error => {
          if (error instanceof HttpErrorResponse && error.status === 409) {
            const errorDetails = JSON.parse(error.error);
            this.errorMessage = errorDetails.detail;
          }
          if (error instanceof HttpErrorResponse && error.status === 400) {
            try {
              const errorDetails = JSON.parse(error.error);
              this.errorMessage = errorDetails.detail;
            } catch {
              const startIndex = error.error.indexOf("[");
              const endIndex = error.error.lastIndexOf("]");
              if (startIndex !== -1 && endIndex !== -1) {
                const validationErrorsString = error.error.substring(startIndex + 1, endIndex);
                this.errorMessage = `<strong>Problems creating Department!</strong> ${validationErrorsString}`;
              }
            }
          }
          this.notification.error(this.errorMessage, "", {enableHtml: true});
        }
      });
    }
  }


  ngOnInit(): void {
    if (!(this.authService.isLoggedIn()) || !(this.authService.isManager())) {
      this.router.navigate(['/401']).then(r => console.log("Error status 401"));
    }
    this.departmentService.getDepartmentByManagerEmail(this.authService.getLoggedInEmail()).subscribe({
      next: data => {
        this.manager.department = data;
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status !== 404) {
          this.defaultServiceErrorHandling(err);
        }
      }
    });
    this.getInvitedUsers();

    this.userService.getAllNotPendingUsers().subscribe((data) => {
      this.notPendingUsers = data;
    });
    this.departmentService.getDepartmentByManagerEmail(this.authService.getLoggedInEmail()).subscribe({
      next: data => {
        this.department = data;
      }
    });
    this.searchChangedObservable
      .pipe(debounceTime(300))
      .subscribe({next: () => this.reloadDepartment()});
  }

  reloadDepartment() {
    this.departmentService.getDepartmentByManagerEmail(this.authService.getLoggedInEmail()).subscribe({
      next: (department: Department) => {
        this.department = department;
      },
      error: error => {
        this.defaultServiceErrorHandling(error);
      }
    });
  }

  getInvitationDate(userInvite: SimpleUser): void {
    this.userService.getInvitationDate(userInvite.email).subscribe({
      next: (data: Date) => {
        // Beispiel-Datumstring
        const datumString = data.toString();
        const date = new Date(datumString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Beachte: Monate starten bei 0, deshalb +1
        const day = date.getDate();
        let dateString = year + "-" + month + "-" + day;
        this.invitationDate = dateString
      },
      error: error => {
        this.defaultServiceErrorHandling(error);
      }
    });
  }

  defaultServiceErrorHandling(err: any) {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 500) {
        this.router.navigate(['/500']).then(r => console.log("Error status 500"));
      } else if (err.status === 404) {
        this.errorMessage = err.error;
        this.router.navigate(['/404']).then(r => console.log("Error status 404"));
      } else if (err.status === 400) {
        const startIndex = err.error.indexOf("[");
        const endIndex = err.error.lastIndexOf("]");
        if (startIndex !== -1 && endIndex !== -1) {
          const validationErrorsString = err.error.substring(startIndex + 1, endIndex);
          this.errorMessage = `<strong>Problems updating Department!</strong> ${validationErrorsString}`;
        }
      }
    }
    if (typeof err.error === 'object') {
      this.errorMessage = err.error.error;
    } else {
      this.errorMessage = err.error;
    }
    this.notification.error(this.errorMessage, "", {enableHtml: true});
  }

  save(form: NgForm) {
    if (form.valid) {
      let observable: Observable<Department>;
      observable = this.departmentService.updateDepartment(this.department);
      observable.subscribe({
        next: data => {
          this.department = data;
          this.successMessage = `<strong>Successfully updated Department "${this.department.name}"!</strong>`;
          this.notification.success(this.successMessage, "", {enableHtml: true});
          this.ngOnInit();
          this.reloadDepartment();
        },
        error: err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 500) {
              this.router.navigate(['/500']);
            } else if (err.status === 404) {
              this.errorMessage = err.error;
            } else if (err.status === 400) {
              const startIndex = err.error.indexOf("[");
              const endIndex = err.error.lastIndexOf("]");
              if (startIndex !== -1 && endIndex !== -1) {
                const validationErrorsString = err.error.substring(startIndex + 1, endIndex);
                this.errorMessage = `<strong>Problems updating Department!</strong> ${validationErrorsString}`;
              }
            }
          }
          this.notification.error(this.errorMessage, "", {enableHtml: true});
        }
      });
    }
    this.reloadDepartment();
  }

  getInvitedUsers() {
    this.userService.getInvitedUsersToDepartment(this.authService.getLoggedInEmail()).subscribe({
      next: data => {
        this.pendingInvites = data;
        for (let user of this.pendingInvites) {
          this.userService.getInvitationDate(user.email).subscribe({
            next: (data: Date) => {
              const datumString = data.toString();
              const date = new Date(datumString);
              const year = date.getFullYear();
              const month = date.getMonth() + 1; // Beachte: Monate starten bei 0, deshalb +1
              const day = date.getDate();
              this.invitationDate = year + "-" + month + "-" + day
            },
            error: error => {
              this.defaultServiceErrorHandling(error);
            }
          });
        }
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status !== 404) {
          this.defaultServiceErrorHandling(err);
        }
      }
    });
  }

  invite(username: string) {
    if (this.invitationInProgress) {
      return;
    }
    this.invitationInProgress = true;
    this.userService.inviteToDepartment(username).subscribe({
      next: data => {
        this.successMessage = 'Successfully invited user: ' + username;
        this.notification.success(this.successMessage, "", {enableHtml: true});
        this.userService.getInvitedUsersToDepartment(this.authService.getLoggedInEmail()).subscribe((data) => {
          this.pendingInvites = data;
          this.getInvitedUsers();
        });
        this.userService.getAllNotPendingUsers().subscribe((data) => {
          this.notPendingUsers = data;
        });
      },
      error: error => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.errorMessage = error.error;
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {
          const startIndex = error.error.indexOf("[");
          const endIndex = error.error.lastIndexOf("]");
          if (startIndex !== -1 && endIndex !== -1) {
            this.errorMessage = error.error.substring(startIndex + 1, endIndex);
          }
        }
        this.notification.error(this.errorMessage, "", {enableHtml: true});
      },
      complete: () => {
        this.invitationInProgress = false;
      }
    });
  }

  uninvite(username: string) {
    if (this.revokeInvitationInProgress) {
      return;
    }
    this.revokeInvitationInProgress = true;
    this.userService.deleteInviteToken(username).subscribe({
      next: () => {
        this.successMessage = 'Successfully deleted Invitation: ' + username + '.';
        this.successMessage = 'Successfully deleted Invitation: ' + username;
        this.notification.success(this.successMessage, "", {enableHtml: true});
        this.userService.getInvitedUsersToDepartment(this.authService.getLoggedInEmail()).subscribe((data) => {
          this.pendingInvites = data;
          if (this.pendingInvites.length == 0) {
            this.selectedNavItem = 'addNewMember';
          }
        });
        this.userService.getAllNotPendingUsers().subscribe((data) => {
          this.notPendingUsers = data;
        });
      },
      error: err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 500) {
            this.router.navigate(['/500']).then(r => console.log('Error status 500'));
          } else if (err.status === 404) {
            this.errorMessage = err.error;
            this.router.navigate(['/404']).then(r => console.log('Error status 404'));
          } else if (err.status === 400) {
            const startIndex = err.error.indexOf("[");
            const endIndex = err.error.lastIndexOf("]");
            if (startIndex !== -1 && endIndex !== -1) {
              this.errorMessage = err.error.substring(startIndex + 1, endIndex);
            }
          } else {
            this.notification.error(this.errorMessage, "", {enableHtml: true});
          }
        }
      },
      complete: () => {
        this.revokeInvitationInProgress = false;
      }
    });
  }

  remove(removalRequest: string) {
    this.userService.removeUserFromDepartment(removalRequest).subscribe({
      next: () => {
        this.successMessage = "Successfully removed user " + removalRequest + " from the department.";
        this.notification.success(this.successMessage, "", {enableHtml: true});
        this.ngOnInit();
      },
      error: error => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.errorMessage = error.error;
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {
          const startIndex = error.error.indexOf("[");
          const endIndex = error.error.lastIndexOf("]");
          if (startIndex !== -1 && endIndex !== -1) {
            const validationErrorsString = error.error.substring(startIndex + 1, endIndex);
            this.errorMessage = validationErrorsString;
          }
        }
        this.notification.error(this.errorMessage, "", {enableHtml: true});
        if (error instanceof HttpErrorResponse) {
          if (error.status === 404) {
            this.router.navigate(['/404']).then(r => console.log("Error status 404"));
          } else if (error.status === 400) {
            const startIndex = error.error.indexOf("[");
            const endIndex = error.error.lastIndexOf("]");
            if (startIndex !== -1 && endIndex !== -1) {
              this.errorMessage = error.error.substring(startIndex + 1, endIndex);
            }
          }
          this.notification.error(this.errorMessage, "", {enableHtml: true});
        }
      }
    });
  }

  updateSearchResult() {
    var input = this.readInput();
    var v;
    if (input.firstInput == "" && input.lastInput == undefined) {
      v = this.userService.getAllNotPendingUsers();
    } else {
      v = this.userService.getNotPendingUsersByName(this.readInput());
    }
    v.subscribe({
      next: data => {
        this.notPendingUsers = data;
      }
    });
  }

  searchInvitedUsers() {
    var input = this.readInput();
    var v;
    if (input.firstInput == "" && input.lastInput == undefined) {
      v = this.userService.getInvitedUsersToDepartment(this.authService.getLoggedInEmail());
    } else {
      v = this.userService.getPendingUsersByName(this.readInput());
    }
    v.subscribe({
      next: data => {
        this.pendingInvites = data;
      }
    });
  }

  readInput(): UserSearch {
    let parts = this.searchInput.split(" ");
    return {
      firstInput: parts[0], lastInput: parts[1], maxResults: null
    }
  }

  onChangePage(pageOfItems: Array<SimpleUser>) {
    this.pageOfItems = pageOfItems;
  }

  setCounter(counter: number) {
    this.counter = counter + 1;
  }

}


