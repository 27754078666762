<div *ngIf="authService.isLoggedIn() && authService.isAdmin()" class="mt-4 container">


  <div class="row pb-3">
    <div class="title">
      <h1>User Administration</h1>
    </div>
    <p class="lead">Here you can manage all users. You can add, edit and delete users.</p>
  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="QA_section">
        <button type="submit" hidden="hidden">submit</button>
        <div class="flex-grow-1">
          <input type="text" id="searchMember" name="searchMember"
                 class="form-control shadow-light rounded-pill bg-white border-white px-4" placeholder="Search ..."
                 [(ngModel)]="searchInput" (ngModelChange)="updateSearchResult()">
        </div>
        <div class="p-3">
        </div>
        <div class="card shadow-light flex-grow-1 pt-4 p-4 rounded-5 bg-white border-white">
          <div class="p-1"></div>
          <div style="overflow-x:auto;">
            <table class="table table-hover table-responsive-lg">
              <thead class="card-top form-floating bg-thead border-white">
              <tr role="row" class="form-floating bg-thead border-white mb-3">
                <th class="form-floating bg-thead left-round-border border-white text-center" scope="col" rowspan="1"
                    colspan="1" aria-label="Icon"></th>
                <th class="form-floating bg-thead" scope="col" rowspan="1" colspan="1"
                    aria-label="First Name: activate to sort column ascending">Name / Email
                </th>
                <th class="form-floating bg-thead" scope="col" rowspan="1" colspan="1"
                    aria-label="Email Address: activate to sort column ascending">Birth Date
                </th>
                <th class="form-floating bg-thead" scope="col" rowspan="1" colspan="1"
                    aria-label="Email Address: activate to sort column ascending">Job Role
                </th>
                <th class="form-floating bg-thead text-center" scope="col" rowspan="1" colspan="1"
                    aria-label="Role: activate to sort column ascending">Role
                </th>
                <th class="form-floating bg-thead right-round-border border-white text-center"
                    scope="col" rowspan="1" colspan="1"
                    aria-label="Actions: activate to sort column ascending">Actions
                </th>
              </tr>
              </thead>
              <tbody class="p-2">
              <div class="p-2"></div>
              <tbody *ngIf="(users); else noManagers">
              <tr *ngFor="let user of pageOfItems; let i = index">
                <td class="col-1 text-center"><img ngxGravatar [email]="user.email"/></td>
                <td class="col-4">
                  <div>{{user.firstName}} <b>{{user.lastName}}</b></div>
                  <div class="card-subtitle lead text-muted">{{user.email}}</div>
                </td>
                <td class="col-2">{{user.birthday}}</td>
                <td class="col-1">{{user.jobrole}}</td>
                <td class="col-2 text-center">
                  <p *ngIf="user.role === 'ADMIN'" class="round-border role-bubble mx-5 m-0 bg-primary text-white">{{user.role}}</p>
                  <p *ngIf="user.role === 'MANAGER'" class="round-border role-bubble mx-5 m-0 bg-manager">{{user.role}}</p>
                  <p *ngIf="user.role === 'USER'" class="round-border role-bubble mx-5 m-0 bg-secondary-subtle">{{user.role}}</p>
                </td>
                <td class="col-2 text-center">
                  <div class="btn-group">
                    <a class="btn btn-sm rounded responsive-info-button pb-0"
                       [routerLink]="['../profile', user.email]"
                       aria-label="Show user details">
                      <i class="bi bi-info-circle icon-big"></i>
                    </a>
                    <a class="btn btn-sm responsive-warning-button pb-0"
                       *ngIf="user.role !== 'ADMIN'"
                       [routerLink]="['../edit', user.email]"
                       aria-label="Edit user">
                      <i class="bi bi-pen icon-big"></i>
                    </a>
                    <button *ngIf="user.role !== 'ADMIN'"
                            (click)="userForDeletion = user"
                            data-bs-toggle="modal"
                            data-bs-target="#delete-confirm-modal"
                            class="btn btn-sm responsive-danger-button pb-0"
                            aria-label="Delete user">
                      <i class="bi bi-trash icon-big"></i>
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
              <ng-template #noManagers>
                <tr>
                  <td colspan="4" style="text-align: center">No users with 'manager' role found</td>
                </tr>
              </ng-template>
            </table>
          </div>
          <div class="container mt-5" id="prev-next-buttons">
            <jw-pagination [items]="users" (counter)="setCounter($event)"
                           [needsSubmit]=false
                           (changePage)="onChangePage($event)"
                           [pageSize]="pageSize"></jw-pagination>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<app-confirm-delete-dialog id="delete-confirm-modal"
                           [deleteWhat]="userForDeletion?.email ?? ''"
                           (confirm)="delete(userForDeletion?.email)">
</app-confirm-delete-dialog>

