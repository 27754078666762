<app-header></app-header>
<body id="layoutSidenav">
<aside *ngIf="authService.isLoggedIn()" class="sb-shrink bg-sb text-black shadow-light">
  <app-sidebar-shrink></app-sidebar-shrink>
</aside>
<div class="layoutSidenav_content">
  <main>
    <div class="container-fluid pt-4 pb-4 p-lg-4">
      <router-outlet></router-outlet>
    </div>
  </main>
  <app-footer></app-footer>
</div>
</body>
