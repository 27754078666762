import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {RegisterService} from '../../services/register.service';
import {JobRoles, RegisterRequest} from '../../dtos/register-request';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from "ngx-toastr";


@Component({
  selector: 'app-login',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: UntypedFormGroup;
  // After first submission attempt, form validation will start
  submitted = false;
  // Error flag
  errorMessage = '';
  role = '';
  minDate: string;
  maxDate: string;
  jobRoles = Object.values(JobRoles);

  constructor(private formBuilder: UntypedFormBuilder,
              private registerService: RegisterService,
              private authService: AuthService,
              private router: Router, private notification: ToastrService) {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/login']).then(r => console.log('Already logged in'));
    }
    this.setDates();
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      birthday: [null, [Validators.required]],
      jobRole: [null, [Validators.required]],
      email: ['', [Validators.required, Validators.maxLength(100), Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
      password2: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
      role: ['', [Validators.required, Validators.maxLength(100)]]
    }, {validators: passwordMatchValidator});
  }


  registerUser() {
    this.submitted = true;
    if (this.registerForm.valid) {
      const registerRequest: RegisterRequest = new RegisterRequest(
        this.registerForm.controls.firstName.value,
        this.registerForm.controls.lastName.value,
        this.registerForm.controls.birthday.value,
        this.registerForm.controls.jobRole.value as string,
        this.registerForm.controls.email.value,
        this.registerForm.controls.password.value,
        this.registerForm.controls.role.value
      );
      this.register(registerRequest);
    }
  }

  register(registerRequest: RegisterRequest) {
    this.registerService.registerUser(registerRequest).subscribe({
      next: () => {
        this.router.navigate(['/login']); // Redirect to login after successful registration
      },
      error: error => {
        if (error instanceof HttpErrorResponse && error.status === 500) {
          this.errorMessage = `<strong>Internal Server Error!</strong> ${error.error}`;
        }
        if (error instanceof HttpErrorResponse && error.status === 409) {
          const errorDetails = JSON.parse(error.error);
          this.errorMessage = errorDetails.detail;
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {
          try {
            const errorDetails = JSON.parse(error.error);
            this.errorMessage = errorDetails.detail;
          } catch {
            const startIndex = error.error.indexOf("[");
            const endIndex = error.error.lastIndexOf("]");
            if (startIndex !== -1 && endIndex !== -1) {
              const validationErrorsString = error.error.substring(startIndex + 1, endIndex);
              this.errorMessage = validationErrorsString;
            }
          }
        }
        this.notification.error(this.errorMessage, "Registration problems!");
      }
    });
  }

  setDates() {
    let maxDate = new Date();
    this.maxDate = maxDate.toISOString().split('T')[0]
    let minDate = new Date(1900, 0, 1);
    this.minDate = minDate.toISOString().split('T')[0];
  }

  selectRole(role: string) {
    this.registerForm.controls.role.setValue(role);
  }


  ngOnInit() {
  }

}

export function passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.get('password')?.value;
  const password2 = control.get('password2')?.value;

  if (password !== password2) {
    return {passwordMismatch: true};
  }
  return null;
}

