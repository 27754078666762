<div class="mt-4 container">

  <div *ngIf="manager.department && authService.isManager(); else noDepartment">
    <div class="row pb-3">
      <div class="title">
        <h1>My Department: {{manager.department.name}}</h1>
      </div>
    </div>

    <div class="card px-3 pt-2 shadow-light rounded-5 border-white mb-4 mt-3">

      <!-- ------------- Navigation ------------- -->

      <div class="secondary-navigation p-2 mb-2">
        <nav class="navigation">
          <ul role="menubar" class="nav nav-tabs rounded-top-4">
            <li data-key="details" class="nav-item rounded-top-4 " role="none" data-forceintomoremenu="false">
              <a role="menuitem" class="nav-link rounded-top-4" (click)="selectedNavItem = 'details'"
                 [class.active]="selectedNavItem === 'details'"
                 aria-current="true">
                Details
              </a>
            </li>
            <li data-key="addNewMember" class="nav-item rounded-top-4" role="none" data-forceintomoremenu="false">

              <a role="menuitem" class="nav-link rounded-top-4" (click)="selectedNavItem = 'addNewMember'"
                 [class.active]="selectedNavItem === 'addNewMember'">
                Add New Members
              </a>
            </li>
            <li *ngIf="(pendingInvites.length > 0)" data-key="invitations" class="nav-item rounded-top-4" role="none" data-forceintomoremenu="false">
              <a role="menuitem" class="nav-link rounded-top-4" (click)="selectedNavItem = 'invitations'"
                 [class.active]="selectedNavItem === 'invitations'">
                Pending Invites
              </a>
            </li>
          </ul>
        </nav>
      </div>


    <!-- ------------- Details ------------- -->

    <!-- ----- Department Name ----- -->

    <div *ngIf="selectedNavItem === 'details'">

      <div class="card-header py-4 bg-white border-white">
        <form #form="ngForm" (ngSubmit)="save(form)">
          <div class="row">
            <div class="col-md-10">
              <input name="name" id="name" type="text"
                     class="form-control rounded"
                     [(ngModel)]="department.name" required/>
            </div>
            <div class="col-md-2">
              <button type="submit" class="button form-control btn btn-primary"
                      [disabled]="!form.valid">
                Update Name
              </button>
            </div>
          </div>
        </form>
      </div>

      <!-- ----- Department Members ----- -->

      <div class="card-body">
        <div class="card-subtitle px-1"><h3>Members</h3></div>
        <div *ngIf="manager.department.members.length > 0; else noMembers" class="table">
          <div style="overflow-x:auto;">
            <table class="table table-hover" role="grid">
              <thead class="card-top form-floating shadow-light bg-thead border-white">
              <tr role="row" class="form-floating bg-thead border-white mb-3">
                <th class="form-floating bg-thead left-round-border border-white text-center"
                    scope="col" rowspan="1"
                    colspan="1" aria-label="Icon"></th>
                <th class="form-floating bg-thead" scope="col" rowspan="1" colspan="1"
                    aria-label="First Name: activate to sort column ascending">Name / Email
                </th>
                <th class="form-floating bg-thead text-center" scope="col" rowspan="1" colspan="1"
                    aria-label="Email Address: activate to sort column ascending">Birth Date
                </th>
                <th class="form-floating bg-thead text-center" scope="col" rowspan="1" colspan="1"
                    aria-label="Email Address: activate to sort column ascending">Job Role
                </th>
                <th class="form-floating bg-thead text-center" scope="col" rowspan="1" colspan="1"
                    aria-label="Actions: activate to sort column ascending">Info
                </th>
                <th class="form-floating bg-thead right-round-border border-white text-center"
                    scope="col" rowspan="1" colspan="1"
                    aria-label="Actions: activate to sort column ascending">Remove
                </th>
              </tr>
              </thead>
              <div class="p-2"></div>
              <tbody>
              <tr *ngFor="let user of pageOfItems; let i = index" class="round-border">
                <td class="col-1 text-center"><img ngxGravatar [email]="user.email"/></td>
                <td class="col-4">
                  <div>{{user.firstName}} <b>{{user.lastName}}</b></div>
                  <div class="card-subtitle lead text-muted">{{user.email}}</div>
                </td>
                <td class="col-2 text-center">{{user.birthday}}</td>
                <td class="col-2 text-center">{{user.jobrole}}</td>
                <td class="text-center">
                  <a type="button" class="btn btn-sm rounded-3 responsive-info-button"
                     [routerLink]="['../profile', user.email]"
                     aria-label="Show user details">
                    <i class="bi bi-info-circle align-self-center icon-big"></i>
                  </a>
                </td>
                <td class="text-center">
                  <button (click)="userForRemoval = user"
                          id="removeUserButton"
                          data-bs-toggle="modal"
                          data-bs-target="#remove-confirm-modal"
                          class="btn btn-sm rounded-3 responsive-danger-button"
                          aria-label="Remove user from department">
                    <i class="bi bi-x-circle align-self-center icon-big"></i>
                  </button>
                  <app-confirm-remove-dialog id="remove-confirm-modal"
                                             [removeWhat]="userForRemoval?.email ?? ''"
                                             (confirm)="remove(userForRemoval?.email)">
                  </app-confirm-remove-dialog>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
          <div class="container mt-5">
            <jw-pagination [items]="manager.department.members" (counter)="setCounter($event)"
                           [needsSubmit]=false
                           (changePage)="onChangePage($event)"
                           [pageSize]="pageSize"></jw-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- ------------- Add new Members ------------- -->

    <div *ngIf="selectedNavItem === 'addNewMember'">

      <div class="card-body mt-1">
        <p class="lead pb-2 mb-3 px-1 ">Invite registered users from the following table to your department.<br>
          You can then find them by Pending Invites until the invitation expires or the user accepts your
          invitation.</p>
        <div class="flex-grow-1 pb-2">
          <input type="text" id="searchMember" name="searchMember"
                 class="form-control rounded-pill bg-white border" placeholder="Search ..."
                 [(ngModel)]="searchInput" (ngModelChange)="updateSearchResult()">
        </div>
        <div style="overflow-x:auto;">
          <table class="table table-hover" role="grid">
            <thead class="card-top form-floating bg-thead">
            <tr role="row" class="form-floating bg-thead border-white mb-3">
              <th class="form-floating bg-thead left-round-border border-white text-center"
                  scope="col" rowspan="1"
                  colspan="1" aria-label="Icon"></th>
              <th class="form-floating bg-thead" scope="col" rowspan="1" colspan="1"
                  aria-label="First Name: activate to sort column ascending">Name / Email
              </th>
              <th class="form-floating bg-thead text-center" scope="col" rowspan="1" colspan="1"
                  aria-label="Email Address: activate to sort column ascending">Birth Date
              </th>
              <th class="form-floating bg-thead text-center" scope="col" rowspan="1" colspan="1"
                  aria-label="Email Address: activate to sort column ascending">Job Role
              </th>
              <th class="form-floating bg-thead right-round-border border-white text-center"
                  scope="col" rowspan="1" colspan="1"
                  aria-label="Actions: activate to sort column ascending">
              </th>
            </tr>
            </thead>
            <div class="p-2"></div>
            <tbody *ngIf="(notPendingUsers); else noUsers">
            <tr *ngFor="let user of pageOfItems; let i = index">
              <ng-container *ngIf="user.role === 'USER' && user.departmentName === null">
                <td class="col-1 text-center"><img ngxGravatar [email]="user.email"/></td>
                <td class="col-3">
                  <div>{{user.firstName}} <b>{{user.lastName}}</b></div>
                  <div class="card-subtitle lead text-muted">{{user.email}}</div>
                </td>
                <td class="col-2 text-center">{{user.birthday}}</td>
                <td class="col-2 text-center">{{user.jobrole}}</td>
                <td class="col-4 text-center col-form-label text-muted col-lg-2 ms-auto">
                  <button id="inviteUserrButton"
                          type="button" class="btn btn-primary"
                          (click)="invite(user.email)"
                          [disabled]="invitationInProgress">
                    <i class="bi bi-send"></i> Send Invite
                  </button>
                </td>
              </ng-container>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="container mt-5" id="prev-next-buttons">
          <jw-pagination [items]="notPendingUsers" (counter)="setCounter($event)"
                         [needsSubmit]=false
                         (changePage)="onChangePage($event)"
                         [pageSize]="pageSize"></jw-pagination>
        </div>
        <ng-template #noUsers>
          <tr>
            <td colspan="4" style="text-align: center">No users found</td>
          </tr>
        </ng-template>
      </div>
    </div>

    <!-- ------------- Pending Invitations ------------- -->

    <div *ngIf="selectedNavItem === 'invitations'">
      <div class="card-body mt-1">
        <p class="lead pb-2 mb-3 px-1 ">Manage pending invitations - resend or revoke as needed.
          Invitations expire after 3 weeks.</p>
        <div class="flex-grow-1 pb-2">
          <input type="text" id="searchInvitedMember" name="searchInvitedMember"
                 class="form-control rounded-pill bg-white border" placeholder="Search ..."
                 [(ngModel)]="searchInput" (ngModelChange)="searchInvitedUsers()">
        </div>
        <div style="overflow-x:auto;">
          <table class="table table-hover" role="grid">
            <thead class="card-top p-2 pb-2 pt-3 border-white mb-3">
            <tr role="row" class="form-floating bg-thead border-white mb-3">
              <th class="form-floating bg-thead left-round-border border-0 border-white"></th>
              <th class="form-floating bg-thead">Name / Email</th>
              <th class="form-floating bg-thead text-center">Jobrole</th>
              <th class="form-floating bg-thead text-center">Invited</th>
              <th class="form-floating bg-thead border-white min-width"></th>
              <th class="form-floating bg-thead right-round-border border-white min-width"></th>
            </tr>
            </thead>
            <div class="p-2"></div>
            <tbody>
            <tr *ngFor="let user of pendingInvites">
              <ng-container *ngIf="user.departmentName === null">
                <td class="col-md-1 text-center"><img ngxGravatar [email]="user.email"/></td>
                <td class="col-3">
                  <div>{{user.firstName}} <b>{{user.lastName}}</b></div>
                  <div class="card-subtitle lead text-muted">{{user.email}}</div>
                </td>
                <td class="col-1 text-center">{{user.jobrole}}</td>
                <td class="col-1 text-center">{{this.invitationDate}}</td>
                <td class="col-3 text-end col-form-label text-muted col-lg-2 ms-auto">
                  <button id="resendInviteButton"
                          type="button" class="btn btn-primary"
                          (click)="invite(user.email)">
                    <i class="bi bi-send"></i> Resend Invite
                  </button>
                </td>
                <td class="col-3 text-center col-form-label text-muted col-lg-2 ms-auto">
                  <button id="deleteUserInvitationButton"
                          type="button" class="btn btn-outline-primary"
                          (click)="uninvite(user.email)"
                          [disabled]="revokeInvitationInProgress">Revoke Invite
                  </button>
                </td>
              </ng-container>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  </div>


  <!-- ------------- The Department has no Members ------------- -->

  <ng-template #noMembers>
    <div class="lead py-2 px-1">Your department has no members! Please add members by inviting them to your department!
    </div>
  </ng-template>

  <!-- ------------- The Manager doesn't have a Department yet ------------- -->

  <ng-template #noDepartment>

    <div class="row pb-3">
      <div class="title">
        <h1>Create Department</h1>
      </div>
      <p class="lead">You have no department assigned yet. Please create one by giving your department a name.</p>
    </div>

    <div class="card px-3 shadow-light rounded-5 border-white mb-4 mt-3">
      <div class="card-header py-4 my-2 bg-white border-white">
          <form #form="ngForm" [class.was-validated]="form.submitted" (ngSubmit)="create(form)">
            <div class="row">
              <div class="col-md-10">
                <input id="departmentName" name="departmentName" type="text"
                       placeholder="Department Name"
                       class="form-control col-form-label input-group rounded mb-3"
                       [(ngModel)]="departmentName" required/>
              </div>
              <div *ngIf="submitted" class="error mt-2">
                <small class="form-text text-warning offset-md-4 offset-lg-4"
                       *ngIf="!validDepartmentName">The name of your department is required!
                </small>
              </div>

              <div class="col-md-2">
                <button type="submit" class="button form-control btn btn-primary"
                        [disabled]="!form.valid"
                        [routerLink]="['/my-department']">Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
  </ng-template>

</div>
