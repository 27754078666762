import {Component, Injectable} from '@angular/core';
import {SidebarService} from '../app/services/sidebar.service';
import {AuthService} from "./services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SE PR Group Phase';


  constructor(public authService: AuthService, private sidebarService: SidebarService) {}
  getClasses() {
    const classes = {
      'pinned-sidebar': this.sidebarService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.sidebarService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }
}
