<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-5">
      <div class="card shadow-light border-0  rounded-5 mt-5">
        <div class="card-header rounded-top-5">
          <h3 class="text-center font-weight-light my-4">Reset Password</h3></div>
        <div class="card-body">
          <form class="form" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
            <div *ngIf="!tokenIsPresent">
              <p class="text-lead text-center">We will send you a reset link if your account exists.</p>
              <div class="form-group form-floating mb-3">
                <input type="email" name="username" formControlName="username" class="form-control"
                       id="username"
                       aria-describedby="usernameHelp" placeholder="name@example.com">
                <label for="username">Email address</label>
                <div *ngIf="submitted && resetPasswordForm.controls.username.errors" class="error">
                  <small class="form-text text-warning"
                         *ngIf="resetPasswordForm.controls.username.errors.required">Your username is
                    required!
                  </small>
                </div>
              </div>
              <div class="mt-4 mb-0">
                <div class="d-grid">
                  <button type="reset" (click)="resetPassword()" class="btn btn-primary btn-block">Reset Password
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="tokenIsPresent">
              <!-- Display password fields and button for resetting the password -->
              <p class="text-lead text-center">Please select your new Password</p>
              <label>New Password:</label>
              <input type="password" name="password" formControlName="newPassword" class="form-control"
                     id="newPassword"
                     aria-describedby="usernameHelp" placeholder="Password">
              <label>Confirm Password:</label>
              <input type="password" name="confirmPassword" formControlName="confirmPassword" class="form-control"
                     id="confirmPassword"
                     aria-describedby="confirmPassword" placeholder="Password">
              <div class="mt-4 mb-0">
                <div class="d-grid">
                  <button type="button" (click)="setPassword()" class="btn btn-primary btn-block">Apply</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer rounded-bottom-5 text-center py-4">
          <div class="small"><a routerLink="/login">Go back to login</a></div>
        </div>
      </div>
    </div>
  </div>
</div>

