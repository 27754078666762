import {Injectable} from "@angular/core";
import {Globals} from '../global/globals';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Question} from "../dtos/question";
import {Trait} from "../dtos/trait";

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  private questionBaseUri: string = this.globals.backendUri + '/questions';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  /**
   * Get the questionnaire for the given level
   * @param level the level
   */
  getQuestionnaire(level: number): Observable<Question[]> {
    return this.httpClient.get<Question[]>(this.questionBaseUri + '/' + level);
  }


  /**
   * Get all questions
   */
  getAllQuestions(): Observable<Question[]> {
    return this.httpClient.get<Question[]>(this.questionBaseUri);
  }


  /**
   * Get the assessment for person with the given email.
   * @param email the email of the person
   */
  getAssessment(email: String): Observable<Trait[]> {
    return this.httpClient.get<Trait[]>(this.questionBaseUri + '/assessments/' + email);
  }

}
