import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {QuestionnaireComponent} from './pages/questionnaire/questionnaire.component';
import {RegisterComponent} from './components/register/register.component';
import {ProfileComponent} from "./components/profile/profile.component";
import {ProfileEditComponent} from "./components/profile/profile-edit/profile-edit.component";
import {ResetPasswordComponent} from "./components/reset-password/reset-password.component";
import {AllMembersComponent} from "./pages/all-members/all-members.component";
import {DepartmentsComponent} from "./pages/departments/departments.component";
import {ProjectComponent} from "./components/project/project.component";
import {ProjectCreateComponent} from "./components/project/project-create/project-create.component";
import {ProjectEditComponent} from "./components/project/project-edit/project-edit.component";
import {MyDepartmentComponent} from "./pages/my-department/my-department.component";
import {NotFoundComponent} from "./components/error/not-found/not-found.component";
import {NotAuthorizedComponent} from "./components/error/not-authorized/not-authorized.component";
import {ServerErrorComponent} from "./components/error/server-error/server-error.component";
import {CompareComponent} from "./components/project/compare/compare.component";
import {InviteComponent} from "./components/invite/invite.component";
import {ImpressumComponent} from "./components/impressum/impressum.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'questionnaire', component: QuestionnaireComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'profile/:email', component: ProfileComponent},
  {path: 'edit/:email', component: ProfileEditComponent},
  {path: 'resetPassword', component: ResetPasswordComponent},
  {path: 'resetPassword/:resetToken', component: ResetPasswordComponent},
  {path: 'user-admin', component: AllMembersComponent},
  {path: 'departments', component: DepartmentsComponent},
  {path: 'projects', component: ProjectComponent},
  {path: 'projects/create', component: ProjectCreateComponent},
  {path: 'projects/edit/:id', component: ProjectEditComponent},
  {path: 'projects/compare/:id1/:id2', component: CompareComponent},
  {path: 'my-department', component: MyDepartmentComponent},
  {path: '401', component: NotAuthorizedComponent},
  {path: '500', component: ServerErrorComponent},
  {path: 'departmentInvite/:email/:token/:user', component: InviteComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: '**', component: NotFoundComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
