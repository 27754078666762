<div *ngIf="pager.pages && pager.pages.length" class="container mb-3">
  <div class="row pagination m-0 p-0">
    <div class="footer pagination-buttons m-0">

      <!-- Previous Button -->

      <div *ngIf="pager.currentPage === 1" class="button-container px-2 mx-2">
        <a class="btn page-link disabled"
           aria-disabled="true" (click)="setPage(pager.currentPage - 1)"><i
          class="bi bi-arrow-left"></i>
        </a>
      </div>
      <div *ngIf="pager.currentPage !== 1" class="button-container px-2 mx-2">
        <a class="btn page-link" tabindex="-1" aria-disabled="false"
           (click)="setPage(pager.currentPage - 1)">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>

      <!-- First Button -->

      <div *ngIf="pager.currentPage >= 4" class="button-container">
        <button class="btn btn-outline-primary" type="submit" (click)="setPage(1)">
          <i class="bi bi-arrow-left-short px-0"></i> 1
        </button>
      </div>

      <!-- visible page buttons -->

      <div *ngFor="let page of getVisiblePages()" class="button-container mx-2">
        <a class="btn page-link" [ngClass]="{active: pager.currentPage === page}" (click)="setPage(page)">
          {{page}}
        </a>
      </div>


      <!-- Last Button -->

      <div *ngIf="pager.currentPage <= pager.totalPages - 3" class="button-container">
        <button class="btn btn-outline-primary" type="submit"
                (click)="setPage(pager.totalPages)">
          {{pager.totalPages}} <i class="bi bi-arrow-right-short"></i>
        </button>
      </div>

      <!-- Next Button -->

      <div *ngIf="pager.currentPage === pager.totalPages" class="button-container px-2 mx-2">
        <a class="btn page-link disabled"
           aria-disabled="true" (click)="setPage(pager.currentPage + 1)">
          <i class="bi bi-arrow-right"></i>
        </a>
      </div>

      <div *ngIf="pager.currentPage !== pager.totalPages" class="button-container px-2 mx-2">
        <a class="btn page-link" tabindex="-1" aria-disabled="false"
           (click)="setPage(pager.currentPage + 1)"> <i
          class="bi bi-arrow-right"></i></a>
      </div>


      <!-- Submit Button for questionnaire-->

      <div *ngIf="needsSubmit && pager.currentPage === pager.totalPages" class="button-container">
        <button class="btn btn-primary bg-th" type="submit">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

