import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Globals} from "../global/globals";
import {Observable} from "rxjs";
import {SimpleUser, User} from "../dtos/user";
import {UserSearch, UserSuggestionsSearch} from "../dtos/userSearch";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userBaseUri: string = this.globals.backendUri + '/user';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }


  /**
   * Loads specific user information from the backend
   *
   * @param email of user to load
   */
  getUserByEmail(email: string): Observable<User> {
    return this.httpClient.get<User>(this.userBaseUri + '/byEmail/' + email);
  }

  /**
   * Updates User information.
   *
   * @param user user information to update
   */
  updateUser(user: SimpleUser) {
    let request = this.userBaseUri + '/' + user.email;
    return this.httpClient.put<SimpleUser>(this.userBaseUri + '/' + user.email, user);
  }

  deleteUser(deleteRequest: string): Observable<string> {
    return this.httpClient.delete(this.userBaseUri + '/' + deleteRequest, {responseType: 'text'});
  }

  getUsersByName(userSearch: UserSearch): Observable<SimpleUser[]> {
    let params = new HttpParams();
    if (userSearch.firstInput) {
      params = params.append("firstInput", userSearch.firstInput);
    }
    if (userSearch.lastInput) {
      params = params.append("lastInput", userSearch.lastInput);
    }
    if (userSearch.maxResults) {
      params = params.append("maxResults", userSearch.maxResults);
    }
    return this.httpClient.get<SimpleUser[]>(this.userBaseUri + "/byName", {params});
  }

  getNotPendingUsersByName(userSearch: UserSearch): Observable<SimpleUser[]> {
    let params = new HttpParams();
    if (userSearch.firstInput) {
      params = params.append("firstInput", userSearch.firstInput);
    }
    if (userSearch.lastInput) {
      params = params.append("lastInput", userSearch.lastInput);
    }
    if (userSearch.maxResults) {
      params = params.append("maxResults", userSearch.maxResults);
    }
    return this.httpClient.get<SimpleUser[]>(this.userBaseUri + "/notPendingByName", {params});
  }

  getPendingUsersByName(userSearch: UserSearch): Observable<SimpleUser[]> {
    let params = new HttpParams();
    if (userSearch.firstInput) {
      params = params.append("firstInput", userSearch.firstInput);
    }
    if (userSearch.lastInput) {
      params = params.append("lastInput", userSearch.lastInput);
    }
    if (userSearch.maxResults) {
      params = params.append("maxResults", userSearch.maxResults);
    }
    return this.httpClient.get<SimpleUser[]>(this.userBaseUri + "/pendingByName", {params});
  }

  getSuggestionsByName(userSuggestionsSearch: UserSuggestionsSearch): Observable<User[]> {
    let params = new HttpParams();
    if (userSuggestionsSearch.firstInput) {
      params = params.append("firstInput", userSuggestionsSearch.firstInput);
    }
    if (userSuggestionsSearch.lastInput) {
      params = params.append("lastInput", userSuggestionsSearch.lastInput);
    }
    if (userSuggestionsSearch.maxResults) {
      params = params.append("maxResults", userSuggestionsSearch.maxResults);
    }
    if (userSuggestionsSearch.projectId) {
      params = params.append("projectId", userSuggestionsSearch.projectId);
    }
    if (userSuggestionsSearch.managerEmail) {
      params = params.append("managerEmail", userSuggestionsSearch.managerEmail);
    }
    return this.httpClient.get<User[]>(this.userBaseUri + "/suggestions", {params});
  }

  getAllUsers(): Observable<SimpleUser[]> {
    return this.httpClient.get<SimpleUser[]>(this.userBaseUri + "/all");
  }

  inviteToDepartment(username: string): Observable<Date> {
    return this.httpClient.put<Date>(this.userBaseUri + '/inviteToDepartment', username);
  }

  deleteInviteToken(useremail: string): Observable<string> {
    return this.httpClient.delete(this.userBaseUri + '/deleteInviteToken/' + useremail, {responseType: 'text'});
  }

  removeUserFromDepartment(email: string): Observable<void> {
    return this.httpClient.put<void>(this.userBaseUri + '/removeFromDepartment' + '/' + email, email);
  }

  getInvitedUsersToDepartment(email: string): Observable<SimpleUser[]> {
    return this.httpClient.get<SimpleUser[]>(this.userBaseUri + '/invitedUsersOfManager/' + email);
  }

  getInvitationDate(email: string): Observable<Date> {
    return this.httpClient.get<Date>(this.userBaseUri + "/getInvitationDate/" + email);
  }

  getAllNotPendingUsers(): Observable<SimpleUser[]> {
    return this.httpClient.get<SimpleUser[]>(this.userBaseUri + "/allNotPending");
  }

  getManagersWithoutDepartment(): Observable<SimpleUser[]> {
    return this.httpClient.get<SimpleUser[]>(this.userBaseUri + "/managersWithoutDepartment");
  }
}
